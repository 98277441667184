import { useEffect, useState } from 'react';
import BackgroundImg1 from '../../../../assets/images/report/1.png';
import BackgroundImg2 from '../../../../assets/images/report/2.png';
import BackgroundImg3 from '../../../../assets/images/report/3.png';
import BackgroundImg4 from '../../../../assets/images/report/4.png';
import BackgroundImg5 from '../../../../assets/images/report/5.png';
import DestinyImg1 from '../../../../assets/images/destiny/destiny-1.png';
import { useInterval } from '../../../hooks/useInterval';
import './DestinyNumber.scss';

const DestinyNumberReport = ({ destinyNumber, isFinished }) => {
  const [isGraphicEntered, setIsGraphicEntered] = useState(false);
  const [switchBackgroundImage, setSwitchBackgroundImage] = useState(false);

  const { finishInterval } = useInterval({
    callback: () => setSwitchBackgroundImage((old) => !old),
    ms: 30000,
  });

  useEffect(() => {
    setTimeout(() => {
      setIsGraphicEntered(true);
    }, 1000);

    return () => {
      finishInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.body.classList = switchBackgroundImage ? 'switch-background' : '';
  }, [switchBackgroundImage]);

  return (
    <div
      className={`numbers-report ${isGraphicEntered ? 'entered' : ''} ${
        isFinished ? 'finished' : ''
      }`}
    >
      <div className={`destiny ${switchBackgroundImage ? 'display' : ''}`}>
        <img src={DestinyImg1} alt="destiny-bg-1" />
      </div>
      <div
        className={`report-number ${switchBackgroundImage ? '' : 'display'}`}
      >
        <img src={BackgroundImg1} alt="report-bg-1" />
        <img src={BackgroundImg2} alt="report-bg-2" />
        <img src={BackgroundImg3} alt="report-bg-3" />
        <img src={BackgroundImg4} alt="report-bg-4" />
        <img src={BackgroundImg5} alt="report-bg-5" />

        <div className={`destiny-number ${isGraphicEntered ? 'display' : ''}`}>
          {destinyNumber}
        </div>
      </div>
    </div>
  );
};

export default DestinyNumberReport;
