import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import VoiceCaptionBox from '../audio-caption-box/AudioCaptionBox';
import { useDestinyNumberAudioData } from './useDestinyNumberAudioData';

const DestinyNumberAudio = ({ destinyNumber, readingStep, onFinish }) => {
  const [scriptAudio, setScriptAudio] = useState(null);
  const [audioPaused, setAudioPaused] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [canPlayScriptAudio, setCanPlayScriptAudio] = useState(false);

  const {
    caption,
    isFinished,
    isStarted,
    scriptAudioSrc,
    startTimer,
    stopTimer,
  } = useDestinyNumberAudioData({
    destinyNumber,
    startPlay: readingStep === 'destiny-number',
  });

  useEffect(() => {
    if (scriptAudioSrc) {
      loadAudios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptAudioSrc]);

  const loadAudios = async () => {
    const scriptAudioObj = new Howl({
      src: [scriptAudioSrc],
      html5: true,
      format: 'mp3',
      onload: () => setCanPlayScriptAudio(true),
    });
    setScriptAudio(scriptAudioObj);
  };

  useEffect(() => {
    if (readingStep === 'destiny-number' && scriptAudio && canPlayScriptAudio) {
      startPlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptAudio, readingStep, canPlayScriptAudio]);

  useEffect(() => {
    if (isFinished) {
      stopPlayer();
      scriptAudio.unload();
      onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished, scriptAudio]);

  const startPlayer = async () => {
    startTimer();

    if (audioPaused) {
      scriptAudio.play();
      setAudioPaused(false);
    } else {
      setTimeout(() => scriptAudio.play(), 1000);
    }
  };

  const stopPlayer = () => {
    if (scriptAudio) {
      scriptAudio.pause();
      setAudioPaused(true);
    }
    stopTimer();
  };

  const handleControlVolumn = (isMuted) => {
    scriptAudio.mute(isMuted);
    setAudioMuted(isMuted);
  };

  return (
    <VoiceCaptionBox
      caption={caption}
      isFinished={isFinished}
      isMuted={audioMuted}
      isPaused={audioPaused}
      isStarted={isStarted}
      onControlClicked={audioPaused ? startPlayer : stopPlayer}
      onVolumeClicked={() => handleControlVolumn(!audioMuted)}
    />
  );
};

export default DestinyNumberAudio;
