import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { covertTextToSpeech } from '../../../../../api/api';
import { S3UploadedAudios } from '../../../../../common/constants';
import VoiceCaptionBox from '../audio-caption-box/AudioCaptionBox';
import { useIntroDestinyNumberAudioData } from './useIntroDestinyNumberAudioData';

const IntroDestinyNumberAudio = ({ name, readingStep }) => {
  const [nameAudio, setNameAudio] = useState(null);
  const [scriptAudio, setScriptAudio] = useState(null);
  const [canPlayNameAudio, setCanPlayNameAudio] = useState(false);
  const [canPlayScriptAudio, setCanPlayScriptAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const scriptAudioObj = new Howl({
      src: [S3UploadedAudios.START_DESTINY_NUMBER],
      html5: true,
      format: 'mp3',
      onload: () => setCanPlayScriptAudio(true),
    });
    setScriptAudio(scriptAudioObj);
  }, []);

  useEffect(() => {
    setTimeout(() => loadAudios(), 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const loadAudios = async () => {
    if (name) {
      const nameSpeech = await covertTextToSpeech(
        `Hello ${name} and welcome back,`
      );
      const nameAudioObj = new Howl({
        src: [nameSpeech],
        html5: true,
        format: 'mp3',
        onload: () => setCanPlayNameAudio(true),
        onplay: () => setIsPlaying(true),
      });
      setNameAudio(nameAudioObj);
    }
  };

  useEffect(() => {
    if (
      readingStep === 'destiny-number-calculation' &&
      nameAudio &&
      scriptAudio &&
      canPlayNameAudio &&
      canPlayScriptAudio &&
      !isPlaying
    ) {
      setTimeout(() => nameAudio.play(), 1000);
      setTimeout(() => scriptAudio.play(), 3000);
    }
  }, [
    canPlayNameAudio,
    canPlayScriptAudio,
    isPlaying,
    nameAudio,
    scriptAudio,
    readingStep,
  ]);

  const { caption, isFinished, isStarted } = useIntroDestinyNumberAudioData({
    name,
    startPlay:
      readingStep === 'destiny-number-calculation' &&
      canPlayNameAudio &&
      canPlayScriptAudio,
  });

  useEffect(() => {
    if (isFinished) {
      nameAudio.unload();
      scriptAudio.unload();
    }
  }, [isFinished, nameAudio, scriptAudio]);

  return (
    <VoiceCaptionBox
      caption={caption}
      isFinished={isFinished}
      isStarted={isStarted}
      hiddenControls
    />
  );
};

export default IntroDestinyNumberAudio;
