import { useEffect, useMemo, useState } from 'react';
import { AllDestinyNumberAudios } from '../../../../../common/constants';
import { DestinyNumbersScript } from '../../../../../common/destiny-numbers-script';

export const useDestinyNumberAudioData = ({ destinyNumber }) => {
  const [countSeconds, setCountSeconds] = useState(-1);
  const [currentCaption, setCurrentCaption] = useState('');
  const [intervalId, setIntervalId] = useState(undefined);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (countSeconds > 0 && destinyNumber) {
      const currentScript = DestinyNumbersScript[destinyNumber];
      if (currentScript) {
        const nextBlock = currentScript.find(
          (block) => block.time === countSeconds
        );
        if (nextBlock) {
          setCurrentCaption(nextBlock.text);
          if (!nextBlock.text) {
            setIsFinished(true);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countSeconds, destinyNumber]);

  const startTimer = () => {
    const id = setInterval(() => setCountSeconds((old) => old + 1), 1000);
    setIntervalId(id);
  };

  const stopTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  };

  const scriptAudioSrc = useMemo(() => {
    return AllDestinyNumberAudios[destinyNumber];
  }, [destinyNumber]);

  return {
    caption: currentCaption,
    isFinished,
    isStarted: countSeconds >= 0,
    scriptAudioSrc,
    startTimer,
    stopTimer,
  };
};
