export const DestinyNumberScript = {
  1: [
    "The power of destiny number 1 is within you, which means you're stubborn and driven. You'll always end up leading any group you're in, whether you try to or not. Some people want attention but stay in the shadows. But you shine bright without even trying, and people can't help but be drawn to you.",
    "I see that you're brimming with inventive thoughts. And unlike others, you don't hide them away due to the fear of mockery. It's not that you're entirely fearless, but rather, you're more concerned about the consequences of keeping your insights to yourself. With a firm belief in your potential to make a difference, you confidently share your ideas, knowing they can turn things around.",
    'Your self-confidence can sometimes manifest as arrogance, causing you to dismiss the ideas of others, believing yours to be superior. You worry that if you entertain differing opinions, they might fail and impede your progress. Consequently, you resist opposing views.',
    "However, this behavior contradicts the very essence of effective leadership. I understand you have a passion for leading, but if you truly want others to follow you, it's crucial to appreciate their contributions and give credit where it's due.",
    "As an overachiever, no goal is too high for you to accomplish. You're always setting new records, ensuring your name remains at the top of any list. In school, you consistently rank among the best, maintaining flawless scores as part of your impressive reputation.",
    'People wonder how you manage to string along your accomplishments. However, you laugh at them because they have no idea how much work you put into yourself.',
    "Although you don't shy away from leadership, you only lead because you want to be in the limelight. You want the fame, the money, and the respect that comes with leading. So deep down, you're one big mush of individualism. Focused, you don't see anyone but yourself, and it's hard to fall prey to peer pressure.",
    "If you want to reach your potential and deal with your destiny number's downsides, stop being so isolated. You like being independent and not caring what others think, but that attitude won't get you far. Connect with others on a deeper level. Be more emotionally sensitive. When you do that, your relationships won't suffer from your determination to succeed, and you'll find it easier to pay attention to your loved ones. In return, they'll cherish and love you even more for your efforts.",
  ],
  2: [
    'Your Destiny number is 2. I could feel a gentle aura surrounding you even before calculating your Destiny number. This should give you an idea of how palpable your sensitivity is. As an emotional antenna, you effortlessly pick up on the signal’s others send and transmit your own. With abundant peace within your soul, you naturally bring tranquillity wherever you go.',
    "It's high time you stopped underestimating your worth. You're the one who keeps the group united and offers a safe space during tough times. Instead of imposing your opinions on others, you inspire them to use their own thoughts and ideas to move the group forward. Guided by your kind nature, you lend a helping hand to others, sharing what you can, even if it's not much.",
    'Despite your achievements, you continue to be humble and kind, reflecting your belief that people are more than means to accomplish goals. Your sincere respect for others, coupled with your constant efforts to improve their well-being, highlights your grounded and authentic approach to life.',
    "Relationships are very dear to you. You'd trade all the riches in the world to save the people you love. Seeing others suffer is unbearable, and you often take the blame for their mistakes or cover for less diligent co-workers. However, putting others first can be a double-edged sword for your destiny. If you let your altruistic side take over completely, people may take advantage of you, using you for their own gain and leaving you feeling drained and unappreciated.",
    'On the flip side, your caring nature might lead you to sacrifice your own dreams to help your loved ones succeed. While they flourish in their careers, you could find yourself stuck, not progressing in your own life. As your youth fades, you might regret not putting yourself first.',
    "But you don't have to be stuck in either of those extremes. Treading the middle ground may be very hard for you because only very few people with your destiny number can do that. However, you can partner with others. As a partner rather than a subordinate, you'll recognize how much value you offer and appreciate yourself for it. Your shell will become tougher. Instead of seeking external validation and becoming upset when you're not appreciated for your hard work, you'll control your wave of emotions so they don't overpower you. Then you'll have all the happiness, peace, and wealth you desire.",
  ],
  3: [
    'Your Destiny number is 3. Three is the queen bee of all destiny numbers. Your personality is bubbly, and you truly enjoy being in large crowds. When like-minded individuals surround you, it feels as if the world is yours for the taking, and nothing can stop you from achieving your goals.',
    "You are like a bird that refuses to be caged, needing to spread its wings and fly. You can't go a day without embarking on something new. Since you love partying and dancing, meeting new people comes naturally to you. Your easy-going and carefree nature makes it effortless to win others over and have them on your good side.",
    "In a relationship, your openness inspires your significant other to become a better partner. Although you possess fierce loyalty, you sometimes find it difficult to differentiate between your wants and needs, often prioritizing your wants over your actual needs. This can make your partner unhappy and may even result in frequent arguments. However, your strong communication skills save the relationship each time it's on the edge of falling apart.",
    "When someone offends you, you face the issue head-on rather than keeping it bottled up inside. This proactive attitude also comes into play when dealing with seemingly overwhelming challenges. Instead of waiting for the problem to escalate, you express your struggles, and since you've been so kind to everyone, they are eager to come to your aid.",
    "Consequently, you've grown accustomed to having others do things for you without much effort. So when no one is available to shoulder your burdens, you become overwhelmed and discouraged.",
    'For you, life is more of a sprint than a marathon. Your hobbies and interests reflect this mindset. Instead of focusing on planning for the future or making long-term investments, you prefer to spend your time and money on short-term pleasures.',
    "I can see that you'll likely become a successful professional in a creative field. However, you may often feel like you can do everything all at once, but life doesn't always work that way. Your struggle to prioritize your needs can leave you with many unfinished projects and may make you seem careless.",
    "The tendency to leave projects incomplete will eventually make you dissatisfied with yourself. To avoid the trauma of feeling unfulfilled, focus on satisfying your needs before indulging in your wants. When you prioritize and manage your time effectively, you'll complete your tasks and achieve the success you're destined for.",
  ],
  4: [
    'Your destiny number is 4, which explains why you tend to see the world in black and white terms. You possess an almost innocent mindset, one that is not as familiar with the complexities and pains others might experience. As a result, when someone hurts you, you often look inward, trying to understand what you could have done to deserve their actions and constantly placing blame upon yourself.',
    "The emoji that best represents you is probably a ruler or compass, as it shows just how straightforward and calculated you can be. When you make a decision, you don't waver or deviate from the path. You'd rather stick with the done-to-death methods of doing things.",
    "Your social life is non-existent, but it's not because you're not fun. People think you're too uptight to relax and enjoy yourself. As a boss, you're quick to call out your team if they try different ways to get results, caring more about how they do things than the actual outcome. You tend to choose low-risk, by-the-book careers like law, civil service, or teaching, where you must follow established doctrines and principles.",
    'Your down-to-earth nature makes you a great manager. When everyone else is panicking, you stay chill and know how to put resources to the best use.',
    "You're one of the few people who don't let money control them. For you, everything needs to be planned and budgeted, from vacations and outings to groceries and even your morning coffee. This thrifty attitude helps you save more than your friends, but it also holds you back because you're too scared to invest. So your wealth doesn't grow, and in the end, you're not much better off than those who spend on fancy cars and pricey clothes, the ones you look down on.",
    "Sometimes, you can be a real party pooper. It's cool to have boundaries, but don't forget to loosen up occasionally. Look for the gray area between black and white. Live by the rules, but remember they have exceptions, and it's okay to bend them a bit if it helps you out. You don't need to be formal and proper all the time.",
    "Even though you put on this super-upright act and scold people for making normal mistakes, deep down, there's a wild side hiding beneath that serious mask. Let it out and have some fun. You're already great, but then, you'll be so much better.",
  ],
  5: [
    "You've got the number 5, which is super creative in numerology. You're meant to grow and create new stuff, not stay stuck in one place. You can't stand leaving things as they are or sticking to old traditions. Every night, you go to bed dreaming about rewriting history and changing the world.",
    "Some folks might think you're odd, and others might even chat behind your back, labelling you as quirky or too daring. I bet there's been someone bold enough to tell you to tone down your unconventional ways. It's natural for people to feel uneasy with the unfamiliar. However, having someone like you around to push them out of their comfort zones and explore better, more efficient ways of doing things is a game-changer.",
    "The only thing constant in your destiny is change. Many people come and go. Some come to help you, while others tempt you to abandon your life path. You fall in love and then fall out of it quickly, leaving many broken hearts behind. You've got lots of enemies and even more bitter exes. But it's not your fault. You can't stand being stuck in a relationship that doesn't make you happy.",
    "Unfortunately, happiness isn't as easy to figure out for you as it is for others. It's like this mysterious thing you're always chasing. And even though you keep ditching what you've got in search of that happiness, you somehow always miss the mark.",
    "You don't hold your creations close to your heart because you feel they're not worth much. So on the outside, you appear to be someone who never runs out of ideas and doesn't need emotional support.",
    "When no one takes the time to remind you of how much you matter, you still find the strength to keep going. That's how incredibly self-motivated you are. But sometimes, even the strongest among us need a shoulder to lean on, a kind word, or a gentle reminder that they're not alone.",
    "The one thing holding you back is how often you try to escape reality. When you dive into creative activities, it's because they help you forget your problems for a while. But when you come back to the real world, you find that those troubles are still waiting for you. So, it's time to stop running away.",
    "Grab a hold of your creativity and use it to tackle real-life situations. If you do that, you'll see that your reality can become as beautiful as your wildest dreams.  So, take a deep breath, face your challenges head-on, and your destiny will turn out amazing.",
  ],
  6: [
    "Your Destiny number is 6, which reveals that your most exceptional gift is love. However, your love isn't the kind that remains hidden. Instead, you radiate it outwards, enabling it to manifest and prevent calamities. At times, love can also become your most significant challenge, especially when you love too deeply.",
    "You blend easily into large gatherings, hanging out in the middle, tending to those in need. Your life goals are to stand up for those who can't defend themselves, support the less fortunate, and uphold the law. Unlike most people who only care about themselves and don't worry about others' problems, you see everyone's issues as your own.",
    "Your parenting instincts come alive around kids or folks who seem lost. Even though you're not really into becoming a parent, you act in a way that kids love, often getting them gifts and toys, and you don't mind helping them with their homework.",
    "Your love is patient, kind, and generous. You're always ready to help out. Throughout life, you keep on giving. Soon, you'll see the rewards of your kindness. When you use your wealth to make a difference, it comes back to you in many ways.",
    "Anyone would be lucky to have you as their partner. Your affection is genuine, pure, and you're super forgiving. Your heart is filled with love, leaving no space for holding grudges. Even when others would be super upset, you welcome back those who let you down or left you hanging. You're so caring that some people might see you as a doormat they can toss aside after using.",
    'Fortunately, you had some experiences during childhood that taught you to exercise your generosity with discretion. As you grow older, you master how to discern when someone really needs your help or is just playing you for a fool.',
    'Think about taking a job where you can show your love for others. You could work at a non-profit, volunteer at a food bank, teach, become a consultant, or even join law enforcement. In any of these roles, your passion for people and your intense focus will help you.',
    "You might not end up super rich, but that's not how you measure success. You think the true measure of success is how much one's life impacts others. Don't be shy about sharing your love. Everyone needs it. So go out there and make a difference in people's lives.",
  ],
  7: [
    "Your Destiny number is 7. Out of the twelve destiny numbers, 7 occupies the middle position. So, you're stuck in between, not really sure where you fit in, always searching for where you truly belong.",
    "Knowledge means everything to you. When someone is looking for you, they know they'll probably find you at the library, lost in a book, figuring out answers to some of the craziest questions. Your brain is always on fire, adding up facts and making sense of things. Even when you're asleep, your mind's still buzzing.",
    "But you can't crack every nut. Some problems are just too tough. When you encounter these puzzling issues, you step back from the crowd and find your own space. That's when you bring out all your tools and start connecting the dots, drawing the map. You never give up on any mystery, even if it seems impossible to solve.",
    "You're a total introvert with not much social energy. Just hanging out with a few folks can tire you out, so it's annoying when someone invades your personal space. Reading a lot helps you understand how people think. So, you're not afraid to keep those you don't want around at bay, knowing they can't hurt you. This boldness makes you seem cold to some and cool to others. Even the ones who can't get past your defenses still admire your intelligence from a distance.",
    "When you meet someone new, you quickly judge them based on how logical they seem during your first chat. This helps you decide if they're worth keeping in your life or not. Anyone who doesn't enjoy probing current phenomena as much as you do will weigh you down and suppress your ambition.",
    "Your intelligence announces you wherever you go, so you never run out of opportunities for career and academic advancement. Your destiny leads you on an easy path where you don't have to struggle to get anything, not because you're lazy, but because you spent your early days learning while others just let life pass by.",
    "But sometimes, knowing so much might make you feel like everyone else isn't worth your time. When that happens, remember that people wrote the books you study. If you push everyone away with your high-minded attitude, you'll end up alone.",
    "Being alone isn't a problem, but having a few buddies around will make your destiny more fun. Join clubs, attend events, or start conversations with people you meet. You'll soon find that making friends can be pretty easy and rewarding.",
  ],
  8: [
    "Your destiny number is 8. You're destined to be very powerful and materialistic. While others wait for adulthood to catch up with them before taking practical steps forward with their lives, you map out your plans at an early age. With the available resources, you implement those plans and build your empire brick by brick.",
    'No one is a better businessperson than you are. The speed at which you take the initiative and turn every situation into an opportunity to make money is astonishing. Fears and worries of failure often overcome the desire to be financially strong. But you toss those dark emotions aside and gaze at the light at the end of the tunnel.',
    "Discipline is your most obvious strength. When you need to get work done, you don't waste any extra seconds lingering elsewhere. Surprisingly, time is your most precious asset. That's why you are extremely good at managing it. Your head is like a library. In your head, thoughts about each aspect of your life are arranged in order of importance. The most important aspects come first and determine your courses of action. Very often, your action is to work, save money, and invest in your future.",
    "Someone with a mindset like yours is made for positions of authority. When you occupy any leadership or managerial office, you swing into action immediately. You'll use your power to right the wrongs and do what will lead to the happiness of the greatest number of people.",
    "You'll fare better with a partner who understands the gravity of your mission on Earth. It's only when they understand that they'll be able to fully support you without being jealous or hurt when you place official duties over them.",
    "Life won't go easy on you. Your biggest enemy will be yourself. It may be too difficult for you to participate in activities just for the sake of learning or having fun. Your mind is set on winning. As a result, you can be ruthless towards your opponents when you feel they're getting in your way. You may do anything to take them down and even cause permanent damage to their reputation or families. But if you do that, you won't derive the satisfaction you thought you would derive from your achievements. Don't waste time trying to bring others down. Just work on making yourself better. There's plenty of space for everyone up there.",
  ],
  9: [
    "Your destiny number is 9, and it shows that you want to see justice in the world. All parts of your personality focus on making sure everyone gets treated fairly. You're different from others who try to do good things because you're not driven by love or vulnerability. Instead, you're driven by your experiences and a sense of urgency.",
    "People sometimes wonder how old you are since you seem emotionally mature. This is because you've experienced many good and bad things early on, and you already know what's important to you. You've had some tough times, but you don't want others to go through the same thing if you can help it.",
    "On the outside, you want people to believe that you're perpetually calm. So you prefer to dress in cooler colors that emphasize your role as an agent of goodness. However, your calm facade sometimes gets in the way of truly expressing your feelings. You're afraid that you've been so exalted to the position of a god that if you display human emotions, you'll be ridiculed or judged.",
    "Your cold, aloof demeanor prevents you from openly pursuing the one you love. It puts off potential partners who think you're incapable of being romantic. It also makes people think it's okay to stress you beyond humanly acceptable levels.",
    "While you may not collapse beneath the weight of that burden, you may harbor resentment towards the people you're trying to help. That annoyance might build up until it explodes when you least expect it. The problem with destiny number 9 is that you sometimes let out your frustration at the worst times, and it surprises everyone.",
    "In your zeal to help everyone and everything, you can be a little too focused on what you're doing rather than on how it's being received. That's why you may occasionally find yourself acting like a one-person show and then wondering why everyone is so mad at you for not listening to them.",
    'Plenty of people could use the advice you have to give, but they need to be able to trust that your advice will be useful. For them to trust you, they need to know that your interests are aligned with theirs.',
    "If you're feeling pressured by someone who wants you to do something that directly conflicts with your interests, speak up. That's when you'll add as much value as you're destined to add to humanity.",
  ],
  11: [
    "Your destiny number is 11. This number makes you as unstable as water. Like water, you easily soak up the energy in the room and are easily affected by the bad energies of others. You're in tune with your surroundings, and your emotions often change based on the people around you. You're happy when everyone's happy. But when there are mixed emotions, you get confused and frustrated. Expressing frustration isn't easy, so you escape into a fantasy world where you don't have to feel it anymore.",
    "You're your safe zone and your dangerous alley. The contrast between the different sides of you that manifest in different situations scares you. Other times, you feel as if you don't really know yourself and so can't even identify what you like and dislike. This makes it easy for others to influence you. You often follow whoever's leading, whether they're going in the right direction.",
    "Figuring out right from wrong might be too strange for you. You're like a sponge, soaking up everything people say without questioning it. Rather than weigh different options to identify which you prefer, you leave all the options on an equal scale. This makes you overwhelmed because you don't know which option to choose. When you're in such a dilemma, you rely on others to decide for you and blame them if their decisions land you in trouble.",
    'Your style and opinions change with your mood, which happens quite often. You tend to base your views on what people say rather than your beliefs.',
    "Connecting with you takes time and effort. Once someone has broken your psychological walls, you quickly rebuild them without trying. And since you exude multiple energies, it's hard for people to pinpoint which one they should tackle when trying to get to know you. The result is that you stay friendless.",
    "You might blame your lack of friends on being socially awkward or shy and think that your past experiences shaped who you are. It's normal for people with the destiny number 11 to feel this way. You wish for friends, but you're lost in your dream world.",
    "It's important to understand that having friends may not be part of your destiny. Your strength lies in your independence and ability to entertain yourself. If you find peace within yourself, your future will begin to fall into place. Define the emotions you want to feel and the goals you want to get. Then chase them.",
  ],
  22: [
    "Your destiny number is 22. You don't just talk or dream about things. You make them happen. When you lead a team, you guys usually get the job done. Your ideas might not be the best, but they always become a reality. People can count on you to finish what you start. You'll make a great leader, a trustworthy boss, and an efficient employee.",
    "2 is a very sensitive number, and having double 2s in your destiny number projects the sensitivity of 2 outwards. Instead of you feeling what others are feeling, others feel what you feel. You're usually on an all-time high, desperate to meet deadlines and crush objectives. When you disclose your mission to people, the passion with which you sell your idea to them inspires them to help you out.",
    "In leadership roles, you're a total rockstar because you never leave things half-done. When there's a problem in your jurisdiction, you don't freak out or bail. Even if everyone else leaves, you'll use your smarts and might even work yourself to the bone to find a practical solution.",
    "Your objective approach to everything helps you apologize for wrongs done and make amends with others. Naturally, people can't walk all over you because the sheer force of your destiny scares them away from messing with you.",
    "Going by your strongly founded realism, there shouldn't be anything height you can't reach in life. Unfortunately, you're limited by the quantity and quality of resources available. You're also limited by your extreme practicality, which prevents you from accepting that there could be other possibilities besides what you aim for.",
    "You have tried to do a particular thing several times and failed. But you still kept trying. I want you to know that you may be patient, but time is not. Time is running out. So if you continue pressing forward at just one goal and ignoring others, you'll end up under-fulfilled and pessimistic.",
    "It would help if you dropped the veil, you've cast over your eyes. Your options are as many as you make them. The fact that you cannot finish one project does not mean that all others you attempt will stay unfinished. Refuse to let doubt set in when you're unable to hit the mark. Shield yourself from being paralyzed by fear. Hope for the best, and surround yourself with people or things that help you stay optimistic.",
  ],
  33: [
    "Your destiny number is 33, the number of goodness. Life is nothing short of a struggle, but you're destined to make living easier for everyone. You were sent to Earth to prevent it from being destroyed by bad people. Your number is the cardinal sign of everything good. You're here to replace sicknesses sweeping with health. It's your destiny not to just be rich but to make many rich.",
    "Because you're equipped with the strongest business acumen among all destiny numbers, you'll enjoy creating job opportunities for others and thus turn their lives around for the better. When you speak, your words edify the listeners. You're the agent of transformation in your community. I know you're going through a tough phase right now. Remember that the darkest part of the night is just before dawn.",
    "From birth, you've been arraigned on the good side in the battle between good and evil. Various elements of this world are striving to stain your good conscience. Someone may have broken your trust and made you believe that the world is not worth redeeming. You're trying to swim to the surface, but there's a thing that wraps itself tighter and tighter around your neck. That thing is so tight that you feel as if you can't breathe. That thing may not necessarily be evil, but it's stopping you from moving forward. The moment you identify what that thing is, you will use your good powers to conquer it.",
    "Your biggest weakness is yourself. You battle with internal fear. Fear prevents you from manifesting your inherent goodness. You worry that you're too pure, so everyone will dislike you if you allow your goodness to influence your actions. Then you shirk from expressing your goodness in its fullness. In the end, you thwart your destiny with your own hands.",
    "Seeing the number 33 popping up on your numerology chart is a proverbial wake-up call. It's the universe nudging you to finally squash that fear that's been lurking around. When you've conquered your fear, you'll care less about what people say about you. You'll also become more grounded in the real world and form solid relationships with others.",
    "The beauty of this connection isn’t just in the solidity it brings to your relationships. It also lets you peek into the world from the perspectives of others. You'll begin to fathom the reasons behind the actions of those around you. This understanding will make your interactions richer and help you achieve your ultimate purpose.",
  ],
};
