import { useEffect, useState } from 'react';
import DestinyNumberImage from '../../../../assets/images/destiny/destiny-1.png';
import Helper from '../../../common/helper';
import { YouBestSelectOptions } from '../../../common/constants';
import './QuizFullName.scss';

const QuizFullName = ({ name, onComplete }) => {
  const [fullNameText, setFullNameText] = useState(`${name} `);
  const [emailText, setEmailText] = useState('');
  const [describeYouBest, setDescribeYouBest] = useState('');
  const [isScriptStarted, setIsScriptStarted] = useState(false); // life path number end script
  const [isFormEntered, setIsFormEntered] = useState(false);
  const [validateError, setValidateError] = useState({
    fullName: false,
    email: false,
    youBest: false,
  });

  useEffect(() => {
    setTimeout(() => setIsScriptStarted(true), 1000);
    setTimeout(() => setIsScriptStarted(false), 43000);
    setTimeout(() => setIsFormEntered(true), 44000);
  }, []);

  const handleContinue = () => {
    const validateForm = {
      fullName: !fullNameText || !fullNameText.split(' ')?.[1],
      email: !emailText || !Helper.validateEmail(emailText),
      youBest: !describeYouBest,
    };
    setValidateError(validateForm);

    if (
      !validateForm.fullName &&
      !validateForm.email &&
      !validateForm.youBest
    ) {
      setIsFormEntered(false);

      setTimeout(
        () =>
          onComplete({
            fullName: fullNameText,
            email: emailText,
            youBest: describeYouBest,
          }),
        1000
      );
    }
  };

  return (
    <div className="quiz-full-name">
      <div className={`life-path-end ${isScriptStarted ? 'display' : ''}`}>
        <div className="image-container">
          <img src={DestinyNumberImage} alt="Destiny number" />
        </div>
      </div>

      <div className={`quiz-full-name-form ${isFormEntered ? 'entered' : ''}`}>
        <p className="h2">
          To get your free personalized Destiny reading, complete the
          information below...
        </p>

        <div className="box__wrapper">
          <div className="box__inner">
            <div className="form-content">
              <div className="input-item">
                <label>
                  Enter Full Name <br />
                  <span>(including any middle names)</span>
                </label>
                <input
                  className={`${validateError.fullName ? 'error' : ''}`}
                  placeholder="Full name"
                  value={fullNameText}
                  onChange={(e) => {
                    setValidateError({ ...validateError, fullName: false });
                    setFullNameText(e.target.value.replace('  ', ' '));
                  }}
                />
                {fullNameText && validateError.fullName && (
                  <p className="error-text">Please enter at least two words</p>
                )}
              </div>
              <div className="input-item">
                <label className="empty"></label>
                <input
                  className={`${validateError.email ? 'error' : ''}`}
                  placeholder="Email"
                  value={emailText}
                  onChange={(e) => {
                    setValidateError({ ...validateError, email: false });
                    setEmailText(e.target.value);
                  }}
                />
              </div>
              <div className="input-item select-item">
                <label className="empty"></label>
                <select
                  className={`${describeYouBest ? '' : 'placeholder'} ${
                    validateError.youBest ? 'error' : ''
                  }`}
                  value={describeYouBest}
                  onChange={(e) => {
                    setValidateError({ ...validateError, youBest: false });
                    setDescribeYouBest(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Choose What describes you best
                  </option>
                  {YouBestSelectOptions.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="btn-gradient btn-continue"
                onClick={handleContinue}
              >
                Continue with my reading
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizFullName;
