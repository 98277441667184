import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  return (
    <div className="page-privacy-terms">
      <p className="h1">Privacy Policy</p>

      <div className="box__wrapper">
        <div className="box__inner">
          <div className="block">
            <p className="h3">Privacy Policy</p>
            <p>Your privacy is critically important to us.</p>
            <p>
              It is our policy to respect your privacy regarding any information
              we may collect while operating our website. This Privacy Policy
              applies to this website (hereinafter, ”us” or “we”).
            </p>
            <p>
              We respect your privacy and are committed to protecting personally
              identifiable information you may provide us through the Website.
              We have adopted this privacy policy (“Privacy Policy”) to explain
              what information may be collected on our Website, how we use this
              information, and under what circumstances we may disclose the
              information to third parties.
            </p>
            <p>
              This Privacy Policy applies only to information we collect through
              the Website and does not apply to our collection of information
              from other sources.
            </p>
            <p>
              This Privacy Policy, together with the Terms and conditions posted
              on our Website, set forth the general rules and policies governing
              your use of our Website.
            </p>
            <p>
              Depending on your activities when visiting our Website, you may be
              required to agree to additional terms and conditions.
            </p>
          </div>
          <div className="block">
            <p className="h3">Website Visitors</p>
            <p>
              Like most website operators, we collect non-personally-identifying
              information of the sort that web browsers and servers typically
              make available, such as the browser type, language preference,
              referring site, and the date and time of each visitor request. Our
              purpose in collecting non-personally identifying information is to
              better understand how our visitors use our website.
            </p>
            <p>
              From time to time, we may release non-personally-identifying
              information in the aggregate, e.g., by publishing a report on
              trends in the usage of our website.
            </p>
            <p>
              We also collect potentially personally-identifying information
              like Internet Protocol (IP) addresses for logged in users and for
              users leaving comments on blog posts.
            </p>
            <p>
              We only disclose logged in user and commenter IP addresses under
              the same circumstances that we use and discloses
              personally-identifying information as described below.
            </p>
          </div>
          <div className="block">
            <p className="h3">
              Gathering of Personally-Identifying Information
            </p>
            <p>
              Certain visitors to this website choose to interact in ways that
              require us to gather personally-identifying information. The
              amount and type of information that we gather depends on the
              nature of the interaction. For example, we ask visitors who sign
              up for a newsletter to provide their email address.
            </p>
          </div>
          <div className="block">
            <p className="h3">Your Access To and Control Over Information</p>
            <p className="mb-10">
              You may opt out of any future contacts from us at any time. You
              can do the following at any time by contacting us via our contact
              page:
            </p>
            <ul className="no-padding custom-list-style">
              <li>See the data that we have about you, if any.</li>
              <li>Change/correct any data that we have about you.</li>
              <li>Have us delete any data that we have about you.</li>
              <li>
                Express any concerns you might have about our use of your data.
              </li>
            </ul>
          </div>
          <div className="block">
            <p className="h3">Security</p>
            <p>
              The security of your Personal Information is important to us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While we strive to
              use commercially acceptable means to protect your Personal
              Information, we cannot guarantee its absolute security.
            </p>
          </div>
          <div className="block">
            <p className="h3">Advertisements</p>
            <p>
              Ads appearing on our website may be delivered to users by
              advertising partners, who may set cookies. These cookies allow the
              ad server to recognize your computer each time they send you an
              online advertisement to compile information about you or others
              who use your computer. This information allows ad networks to,
              among other things, deliver targeted advertisements that they
              believe will be of most interest to you. This Privacy Policy
              covers the use of cookies by our website and does not cover the
              use of cookies by any advertisers.
            </p>
          </div>
          <div className="block">
            <p className="h3">Links To External Sites</p>
            <p>
              Our Service may contain links to external sites that are not
              operated by us. If you click on a third party link, you will be
              directed to that third partyвЂ™s site. We strongly advise you to
              review the Privacy Policy and terms and conditions of every site
              you visit.
            </p>
            <p>
              We have no control over, and assume no responsibility for the
              content, privacy policies or practices of any third party sites,
              products or services.
            </p>
          </div>
          <div className="block">
            <p className="h3">Remarketing</p>
            <p>
              We may use remarketing services to advertise on third party
              websites (including Google, Facebook and Others) to previous
              visitors to our site. It could mean that we advertise to previous
              visitors who may not have completed a task on our site, for
              example using the contact form to make an enquiry. This could be
              in the form of an advertisement on search results pages, or a site
              in the Display Network.
            </p>
            <p>
              Third-party vendors, including Google and Facebook, use cookies to
              serve ads based on someoneвЂ™s past visits. Of course, any data
              collected will be used in accordance with our own privacy policy
              and the ad networkвЂ™s privacy policy.
            </p>
            <p>
              You can opt out of remarketing or interest-based advertising
              entirely by cookie settings or permanently using a browser plugin.
            </p>
            <p>
              Protection of Certain Personally-Identifying Information We
              disclose potentially personally-identifying and
              personally-identifying information only to those of our employees,
              contractors and affiliated organizations that (i) need to know
              that information in order to process it on our behalf or to
              provide services available on our website, and (ii) that have
              agreed not to disclose it to others.
            </p>
            <p>
              Some of those employees, contractors and affiliated organizations
              may be located outside of your home country; by using our website,
              you consent to the transfer of such information to them.
            </p>
            <p>
              We will not rent or sell potentially personally-identifying and
              personally-identifying information to anyone.
            </p>
            <p>
              Other than to our employees, contractors and affiliated
              organizations, as described above, we disclose potentially
              personally-identifying and personally-identifying information only
              in response to a subpoena, court order or other governmental
              request, or when we believe in good faith that disclosure is
              reasonably necessary to protect the property or rights of our
              website, third parties or the public at large.
            </p>
            <p>
              If you are a registered user of our website and/or have supplied
              your email address, we may occasionally send you an email to tell
              you about new features, solicit your feedback, or just keep you up
              to date with whatвЂ™s going on with our website and our products.
              We primarily use our blog to communicate this type of information,
              so we expect to keep this type of email to a minimum. If you send
              us a request (for example via a support email or via one of our
              feedback mechanisms), we reserve the right to publish it in order
              to help us clarify or respond to your request or to help us
              support other users.
            </p>
            <p>
              We take all measures reasonably necessary to protect against the
              unauthorized access, use, alteration or destruction of potentially
              personally-identifying and personally-identifying information.
            </p>
          </div>
          <div className="block">
            <p className="h3">Aggregated Statistics</p>
            <p>
              We may collect statistics about the behaviour of visitors to our
              website.We may display this information publicly or provide it to
              others. However, we do not disclose your personally-identifying
              information.
            </p>
          </div>
          <div className="block">
            <p className="h3">Affiliate Disclosure</p>
            <p>
              This site may use affiliate links and earn a commission from
              certain links. This does not affect your purchases or the price
              you may pay.
            </p>
          </div>
          <div className="block">
            <p className="h3">Cookies</p>
            <p>
              To enrich and perfect your online experience, we use
              вЂњCookiesвЂќ, similar technologies and services provided by
              others to display personalized content, appropriate advertising
              and store your preferences on your computer.
            </p>
            <p>
              A cookie is a string of information that a website stores on a
              visitorвЂ™s computer, and that the visitorвЂ™s browser provides to
              the website each time the visitor returns. We use cookies to help
              is identify and track visitors, their usage of this website and
              their website access preferences. Visitors who do not wish to have
              cookies placed on their computers should set their browsers to
              refuse cookies before using this website, with the drawback that
              certain features may not function properly without the aid of
              cookies.
            </p>
            <p>
              By continuing to navigate our website without changing your cookie
              settings, you hereby acknowledge and agree to our use of cookies.
            </p>
          </div>
          <div className="block">
            <p className="h3">E-commerce</p>
            <p>
              Those who engage in transactions with us вЂ“ by purchasing our
              services or products, are asked to provide additional information,
              including as necessary the personal and financial information
              required to process those transactions.
            </p>
            <p>
              In each case, we collect such information only insofar as is
              necessary or appropriate to fulfil the purpose of the visitorвЂ™s
              interaction with this site.
            </p>
            <p>
              We do not disclose personally-identifying information other than
              as described below. And visitors can always refuse to supply
              personally-identifying information, with the caveat that it may
              prevent them from engaging in certain website-related activities.
            </p>
          </div>
          <div className="block">
            <p className="h3">Assignment</p>
            <p>
              We may change our ownership or corporate organization while
              providing the Sites and services. We may also sell certain assets
              associated with the Sites. As a result, please be aware that in
              such event we may transfer some or all of your information to a
              company acquiring all or part of our assets or to another company
              with which we have merged.
            </p>
            <p>
              Under such circumstances we would, to the extent possible, require
              the acquiring party to follow the practices described in this
              Privacy Policy, as it may be amended from time to time.
              Nevertheless, we cannot promise that an acquiring company or the
              merged company will have the same privacy practices or treat your
              information the same as described in this Privacy Policy.
            </p>
          </div>
          <div className="block">
            <p className="h3">Privacy Policy Changes</p>
            <p>
              Although most changes are likely to be minor, we may change our
              Privacy Policy from time to time, and at our sole discretion. We
              encourage visitors to frequently check this page for any changes
              to our Privacy Policy. Your continued use of this site after any
              change in this Privacy Policy will constitute your acceptance of
              such change.
            </p>
          </div>
          <div className="block">
            <p className="h3">Contact Information</p>
            <p>
              If you have any queries regarding this Privacy Policy, please
              contact us by clicking the contact link on the bottom this page.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
