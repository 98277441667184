import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
import { ReactComponent as MobileLogo } from '../../../assets/images/logo-mobile.svg';
import { ReactComponent as IconLogo } from '../../../assets/images/icon_logo.svg';
import { signOut } from '../../redux/slices/AuthSlice';
import './Header.scss';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.auth);
  const readingStep = useSelector((state) => state.reading.step);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const token = localStorage.getItem('mystic-numbers-token');
  let readingData = localStorage.getItem('mystic-numbers-reading');
  if (readingData) {
    readingData = JSON.parse(readingData);
  }

  const showOrderLink =
    location.pathname === '/' &&
    readingStep === 'quiz-birthday' &&
    (token ? !userData?.hasPaid && userData?.lastReadingNumbers : readingData);

  const clickBankURL = useMemo(() => {
    if (
      (readingData?.fullName || userData?.lastReadingNumbers?.fullName) &&
      (readingData?.email || userData?.email)
    ) {
      return `https://mysticnumb.pay.clickbank.net/?cbitems=1&cbskin=43542&cbfid=55149&name=${
        userData?.lastReadingNumbers?.fullName || readingData?.fullName
      }&email=${userData?.email || readingData?.email}`;
    }
    return 'https://mysticnumb.pay.clickbank.net/?cbitems=1&cbskin=43542&cbfid=55149';
  }, [readingData, userData]);

  const isSmallMode = useMemo(() => {
    return ['destiny-number-calculation', 'outro-order'].includes(readingStep);
  }, [readingStep]);

  const handleSignout = useCallback(() => {
    setIsMenuOpened(false);
    dispatch(signOut());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    location.pathname.startsWith('/affiliates') ||
    location.pathname.startsWith('/new-message-1')
  )
    return <></>;

  return (
    <header
      className={`${isSmallMode ? 'small' : ''} ${
        location.pathname === '/' ? 'reading' : ''
      }`}
    >
      <a href="/">
        <div className="large-logo">
          <Logo />
        </div>
        <div className="small-logo">
          <IconLogo />
        </div>
        <div className="mobile-logo">
          <MobileLogo />
        </div>
      </a>

      {(showOrderLink || userData) && (
        <div className="right-block">
          <div className={`order-link ${showOrderLink ? 'show' : ''}`}>
            <a href={clickBankURL}>
              {`${userData?.firstName || readingData?.name}, Order Now`}
            </a>
          </div>
          {userData && (
            <div className="user-menu">
              <div
                className="user-menu__button"
                onClick={() => setIsMenuOpened(true)}
              >
                {userData.fullName ? userData.fullName[0] : userData.email[0]}
              </div>
              <div
                className={`user-menu__dropdown${
                  isMenuOpened ? ' opened' : ''
                }`}
              >
                <div
                  className="dropdown-overlay"
                  onClick={() => setIsMenuOpened(false)}
                ></div>
                <div className="dropdown-content">
                  <div className="dropdown-menu-top">
                    <p className="full-name">{userData.fullName}</p>
                    <p className="email">{userData.email}</p>
                  </div>
                  {userData.role !== 'admin' && (
                    <div
                      className="dropdown-menu-item"
                      onClick={() => {
                        setIsMenuOpened(false);
                        navigate('/members-area');
                      }}
                    >
                      Members area
                    </div>
                  )}
                  <div className="dropdown-menu-item" onClick={handleSignout}>
                    Sign out
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
