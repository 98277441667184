import { useEffect, useState } from 'react';
import { AllMonthNames } from '../../../../../common/constants';
import './MonthStep.scss';

const MonthStep = ({ className, data, onSelect }) => {
  const [delayOffset, setDelayOffset] = useState(0.2);
  const [monthBoxes, setMonthBoxes] = useState([]);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setDelayOffset(0.1);
    }
  }, []);

  useEffect(() => {
    if (data.decade && data.year) {
      setMonthBoxes(AllMonthNames);
    }
  }, [data]);

  const getAnimationStyle = (index) => {
    return {
      animation: `scale-up-tl 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) ${
        delayOffset * (index + 1)
      }s both`,
    };
  };

  return (
    <div className={`step-content ${className}`}>
      <p className="h2 text-center">Which Month were you born?</p>

      <div className="month-grid">
        {monthBoxes.map((month, i) => (
          <div
            key={month}
            className="number-box"
            style={getAnimationStyle(i)}
            onClick={() => onSelect(month)}
          >
            <div className="number-box__inner">{month}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthStep;
