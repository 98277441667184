export const LoveNumberScript = {
  1: [
    "Your love number is 1. Regardless of your gender, you won't wait for your love interest to approach you before agreeing to date them. You hunt them down with the confidence of a lion and the speed of a cheetah. Though you may not have an ocean of wealth or the looks of a Hollywood star, they always succumb to you, hopelessly falling head over heels.  You're aware of your romantic charm and use it to your advantage.",
    "The reason why you're so unapologetically bold concerning these matters is that you're not afraid of what may happen if the relationship goes south. Ever since that first awful heartbreak, you have come to terms with the fact that breakups are just a part of life. So avoiding the incredible experience of love because you are afraid of a broken heart seems like such a waste. You do not make promises about the future and even encourage your lovers not to feel stuck with you or assume they will share their life with you forever.",
    'This attitude might lead people to believe you are not the marrying kind. But let me tell you, that could not be further from the truth. You are not against marriage at all! You would adore meeting that special someone, settling down together, starting a family, and raising children to carry on your legacy.',
    "But until that person enters your life, you will not stop searching. And if that means experiencing a broken heart repeatedly, you'll be willing to pay the price.",
    'You often experience mood swings that significantly impact your interaction with your significant other. Some days, you are the happiest person around, showering your partner with love and attention. On other days, though, they almost become invisible to you. This emotional rollercoaster stems from an underlying need for control over your relationship.',
    'You find it difficult to let your partner take charge occasionally. This often leads you to exhaust yourself while providing love and care to your partner, but when it is their turn to show that love and care in return, you put a stop to it. Unknowingly, you create a distance between the two of you.',
    "When you need someone to lean on, they cannot be there for you because you have decided to keep your emotions to yourself. You've let them weigh you down rather than allowing your partner to support you through your struggles.",
  ],
  2: [
    "Your love number is 2. Love was not made for you, but you were made for love. You manifest many qualities that are essential for the survival of any relationship, especially empathy, compassion, and teamwork. Before deciding to be committed to someone, you would've counted the costs and prepared yourself for the various obstacles that may arise on the way.",
    "If your partner needs you around, you make yourself available. You feel your loved one's emotions as if they're your own, and this makes you not want to do anything that will hurt them. You want to protect them from the harm in this world with all your strength. When you're infatuated with a person, they become your utmost priority.",
    "You express love in gentle ways. You may buy your significant other dainty gifts, like their favorite flowers, a quaint piece of jewelry, or something sentimental to them. You sing for them while holding them in your arms and dance with them under the moonlight. Although you're not afraid of your loved one, you're terrified of anything unsavory happening to them. Unfortunately, your nature makes your love interests take you for granted. You act as the hero that caters to all their needs, but they may not appreciate the value you add to their lives.",
    "Most people will consider you their ideal partner. However, being romantically connected with you isn't easy. In your world, your partner is the sun, and you are in a continuous orbit around them. It seems tough for you to grasp the fact that your loved one has a life outside the two of you. They have interests, needs, and priorities that do not always revolve around you, and they might need their space.",
    "You care deeply about your significant other's opinion. Their words of encouragement are like gold in your hands, making you crave it even more. In the quest for their approval, you might even find yourself metaphorically kneeling, eagerly waiting for them to say you have done well.",
    'Your need for validation makes it hard for you to have a strong backbone, and others might even call you needy. This quality can attract mixed reactions when it comes to romantic interests. Some might find it unappealing, while others see it as a charming and endearing trait.',
    'Those who find you endearing are the ones that matter. They understand and accept the intricacies of your personality. Cherish these connections and continue developing more confidence to stand on two feet.',
  ],
  3: [
    `Your love number is 3. That means your love story is full of passion, excitement, and pure joy. You have this burning desire to bring creativity into your relationships, which keeps you on your toes, searching for new ways to add a little zing to the mix. Maybe it is finding different words to express your love, other than the cliché "I love you." Perhaps, you could surprise your partner with a heartfelt message instead of the usual "good morning" text. You might even be the type to update your lover's playlist with fresh tunes constantly.`,
    "Your quest for newness can backfire on your relationship. For instance, you may force your partner to try a new meal they're reluctant to try or spend tons of money from the joint account to renovate the home. You may even attempt to change things that are fine just the way they are.",
    "You top the chart for those who may stray from a committed relationship. The intriguing part is that the person you're cheating with may not be superior to your current partner. They could be less attractive, less funny, less everything. But they offer a different taste, a different flavor from the daily bread your partner dishes out. It's almost like trying a new dessert while still claiming your favorite is chocolate cake. While all this may be thrilling, it's a constant battle on your partner's side. They're always at the edge of their seat, restlessly planning strategies to secure your attention.",
    "One thing about you that people find difficult to understand is that although you can devote your heart to someone, they won't own it for long. You're always on the lookout for the next big catch. Once you find someone who seems more interesting than your current partner, you leave them without regrets.",
    "However, no matter how often you change lovers, you're hardly satisfied with your relationships. You keep chasing the perfect package of a partner. Since no one is perfect, you'll have to convince yourself to settle down later in life.",
    "You don't want to hurt anyone's feelings, so you usually make it clear from the start that you're not the committed type. Yet, despite your brutal honesty, people still fall in love with you because you're so charming. And although you may not stay with someone for long, they'll always appreciate the short period for which they had you all to themselves.",
  ],
  4: [
    "Your love number is 4. While you may not be the most fun person to date, you're the most reliable. Your reliability makes you shy away from romantic affairs at a young age. You only delve into a romantic relationship when you have plans for your partner that you're ready to implement.",
    "When growing up, you made it your mission to become the finest version of a partner by nurturing your mind, body, and soul. To become your best, you may have chosen to develop soft skills, excel in your studies, compete in various contests, or engage in activities that enrich your life. During this period, you'll fantasize about having the perfect first date, the perfect first kiss, and even the perfect first intimate encounter.",
    'This unwavering dedication to your goals can create stumbling blocks when it is time to dive into the realm of romance. Having spent years repressing your feelings for potential love interests, allowing yourself to be vulnerable and authentic with the person you desire might prove to be a challenging task. It might feel awkward or slightly unsettling at first.',
    'Nevertheless, when you decide to partake in the game of love, you do so wholeheartedly. You bring all your passion and intensity to the table. When you play, you play to win.',
    'Your ideal love story is the kind commonly found in slow-burn romance novels. You dream of meeting that special someone in a regular setting, be it at work or a place you frequently visit, and slowly veering toward a romantic relationship with them.',
    "When sincerely in love, you want to delve deep into honest conversations about your partner's hopes and fears. You adore connecting with them over the simplicity of daily activities and aim to understand each intricate detail about who they are. You take your time to paint the canvas of your relationship with the care and precision of an artist at work.",
    "Your heart yearns to have your future partner, assuming you're still waiting for them, share the same living space with you. The thought of settling down together, declaring to the world that they are yours and you are theirs, brings a warm feeling. Marriage might not be something you would immediately dive into. But loyalty remains a priority for you. This is why you like defining your relationships in clear terms.",
    'However, waiting perfect age or moment to experience the magic of love can make you miss valuable lessons that could have been learned along the path of love.',
  ],
  5: [
    'When it comes to love, your number is 5, which means that your expressions of love are far from ordinary. You utterly disregard the typical thinking that limits expressions of love to just fine dining and movie nights. You strive for something more exciting, more unpredictable in love.',
    'Deep, emotional conversations with your significant other are not necessarily your thing. But you make them recognize the glamor in nightlife, find the joy under the neon lights of nightclubs, or feel the surge of adrenaline during karaoke nights.',
    "You are the gravitational force that pulls out the vibrant, passionate side of your partner. Any proposal for a new activity from your partner tickles your fancy and brings the two of you closer. Your adventurous spirit does not only apply to outdoor activities. It extends to the bedroom as well. There, you are all about exploration and discovery in your quest to meet your lover's cravings in extraordinary ways.",
    'The consensus among those you have been romantically involved with is that you are, by far, the most entertaining person they have ever loved. Your lively, almost youthful vigor ensures that the fire in your relationship or marriage never fizzles out.',
    "There's one minor drawback to your love number. It has you ducking from the responsibilities of being in a romantic relationship. Having kids may not be on your radar because you feel your time and resources would be better spent exploring new experiences with your partner. You want to be free from the binding chains of parenthood. Naturally, this would be a significant matter of discussion with your would-be spouse before getting married.",
    "If you detect a drop in the excitement level in your relationship, you are quick to pull the plug. Let's suppose a causal relationship survives against the odds. You may be quite content with maintaining a friends-with-benefits type of situation. This sometimes results in the perception that you are nonchalant about the other person's feelings or, worse, that you do not care enough to be official with them. In reality, if they comprehend the way you think, you two could maintain a very cordial relationship.",
    "You see no value in cutting off ex-partners because you feel that you may need them later in life. However, this approach has its pitfalls. Ex-partners may misconstrue your decision to keep the lines of communication open as an invitation to rekindle old love. But once your romantic engagement with someone ends, it remains so. There's no turning back for you, at least not in the typical sense.",
  ],
  6: [
    "You're truly romantic, with a love number of 6. You enjoy having candlelit dinners with your partner, seducing them with a bathtub filled with rose petals, taking them on romantic vacations, and buying them sentimental gifts.",
    'You believe strongly in happily ever afters and actively seek someone to spend the rest of your life with. You only chase beautiful and handsome partners who have fashion senses that can shut down an entire building. Some may think your approach to love is shallow. However, you just like having attractive people on your arm.',
    "Fortunately, you don't care about appearances alone. You aim to have a soulful connection with the ones you adore, so you put them first before everyone else, including yourself. You don't mind going hungry so they can eat. You'll happily give them your only coat so they don't feel cold. You see them as more than just a person, but the ultimate reason for your existence. That's why breakups hit you hard. You struggle to accept that a relationship or marriage is over and often blame yourself for its end.",
    "You want to be as close as possible to your lover. When you love someone, you don't hide it. You enjoy displaying affection towards them in public. If you're not holding their hands, you're hugging them or doing something even more intimate. Your intense attachment to them makes you obsessed with their affairs. You'll stalk them on social media, track their location, and question them about all their friends. These behaviors are unhealthy and can ruin your love story if they're not kept in check.",
    'Your vulnerability attracts those who may take advantage of it, leading to much accumulated hurt from previous experiences. As a result, trusting new partners becomes challenging for you. In certain situations, even when there is no reason for suspicion, you may find yourself digging for faults and complaining, which could eventually push your partner to their breaking point.',
    "You don't do one-night stands and dislike being taken for a fling. You prefer investing your feelings and time solely in the ones who show commitment towards a long-lasting relationship. Hence, it becomes crucial for you to screen out people who might barge into your life and leave after a short while. Be wary of those opportunists who could mislead you into believing they want a long-term relationship while their intent is just to exploit you temporarily.",
  ],
  7: [
    "Your love number is 7. That makes you one of the hardest people to chase. You build high psychological walls guarding your heart. Anyone interested in you must prove themselves worthy of your love before you lower the walls for them to come into your heart. You're shyest when around the person you love the most.",
    "Expressing love is a big struggle for you, as you may want to show someone how much you love them but just can't. When you are alone, your mind fills with colorful dreams of what you might do with the object of your affection and how you might share your feelings. But once they show up, whatever boldness you garnered fades away. It would help if you had someone who's expressive and will encourage you to come out of your shell.",
    "When you get around to expressing your feelings, you'll find it easy to win anyone over. You often choose intellectual ways of showing love. Instead of taking your significant other to a pool party, you'll ask them to the local library. The ideal relationship for you is one in which you can ponder the mysteries of existence and delve into the depths of philosophical discourse.",
    "You're attracted to people on an intellectual level and can't stand someone who doesn't like talking much or only wants to do stuff like drink, smoke, or have sex. While this preference automatically places you among the less desirable in the love market, it also helps you connect deeper with your lover. Everything you do with them becomes very enjoyable because you both are mentally in tune with each other.",
    'To those not close to you, you seem to lack the capacity for love. This perception stems from the cold, emotionless facade you project. However, when you manage to form a successful relationship, your partner often finds themselves taken aback by the surprising depth of warmth and passion that you demonstrate.',
    "You believe that every day should be an occasion to celebrate love. So you don't wait until Valentine's Day or your partner's birthday before doing something nice for them.",
    'However, you hold back until the relationship has been on for some time, and you know what your partner likes before spending your money on them. The period between your getting to know them and your spending on them is the trial phase, during which you discover if they love you for who you are or just want your money.',
  ],
  8: [
    "Your love number happens to be 8, which means falling in love isn't something that comes naturally to you. However, when that special someone does catch your eye, it's an all-consuming, passionate experience. Your love is as deep as the ocean and as intense as a roaring fire.",
    "Physical chemistry means the world to you. You want a partner that is attractive, financially successful, and driven by their goals. You know what you want, and you're not afraid to go after it with every fiber of your being.",
    "You often skip the talking stage because you feel like you've known your loved one all your life. It's easy for you to let people in, as you're not fond of holding on to grudges from failed past relationships.",
    "However, it's also easy for you to let people out. Once a relationship begins to lose its spark, you break up with your current partner over text or in the coldest manner possible. But when others serve you your cup of tea, you feel insulted and swear to take revenge.",
    "Patience isn't one of your virtues. But you can plot how to bring an ex-lover down for years, tracking their every move. When you finally get them to suffer like they made you suffer, you smile with satisfaction and close that chapter of your life.",
    'In a relationship, your dominant nature tends to take over. You believe that if someone truly loves you, they should cater to your desires and prioritize your interests. You like to have control over the direction of the relationship, and your partner almost makes no contributions to the decisions that concern you both.',
    "Some people find your take-charge attitude attractive, but they're often those with more reserved love numbers. If you enter a relationship with someone who shares your dominant personality traits and strong nature, things might not work out in the long run. The constant clashing of interests and the struggle for control can cause too much friction.",
    'With time, you will find your approach to romance shifting. Instead of aggressively chasing after your love interests, you might find yourself drifting towards detachment. This air of aloofness might leave them puzzling over your romantic intent, questioning whether you are interested or not.',
    'This detached demeanor provides you with the perfect cover to observe them. As they get caught in the web of uncertainty, you attentively study every part of their personality but keep a safe distance. Eventually, they may get tired of your unresponsiveness and come running to you themselves.',
  ],
  9: [
    "Your love number is a solid 9, which indicates you've been on the hunt for your Prince Charming or your damsel in distress ever since you grasped the notion of romantic love. What you yearn for is an idyllic relationship with no trace of trouble. Somehow, you have chosen to stay oblivious to the reality that love is not always a smooth and rosy journey.",
    `You define your love language as the whole package of a so-called "perfect relationship." This includes sexual chemistry, receiving numerous gifts, heartwarming gestures, and having ample time together. You interpret every move your partner makes and often attribute a deeper meaning to it, which, unfortunately, is not always accurate. Over time, your attitude makes them feel uncomfortable and tense in your company.`,
    'Your partner often finds themselves at the receiving end of your persistent complaints. This is not a reflection of your lack of appreciation for them but rather your belief in the possibility of improvement. Once they finally succumb to your wishes and begin adhering to every request you make, you find that the relationship becomes boring.',
    "You're very emotional towards your loved ones and tend to depend heavily on them for support. When you're sad, you want to run into their arms. When you're happy, you want them to drop everything they're doing and celebrate with you. In the same vein, you expect them to reciprocate your sentimentality. You want to be the one with whom they share their deepest secrets. You want to love them more than anyone ever has and heal whatever emotional wounds may have been inflicted on them in the past.",
    "Public displays of affection irritate you. You dislike it when your significant other or someone you're attracted to is too affectionate in public. However, your loyalty to your loved one is unquestionable. You're ready to be there for them through thick and thin. Since you feel you'll never cheat, you don't entertain the possibility of your partner seeking romantic solace elsewhere. This makes you continue to live in denial even when it's clear that the one you love is no longer committed to the relationship or marriage.",
    "To an extent, you're overflowing with compassion. While you aren't the most supportive person in love, you'll do whatever it takes to ensure your partner does not suffer. This usually happens after you've been hit with the hard truth that the perfect relationship does not exist.",
  ],
};
