import { useEffect, useState } from 'react';
import './YearStep.scss';

const YearStep = ({ className, data, onSelect }) => {
  const [delayOffset, setDelayOffset] = useState(0.2);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setDelayOffset(0.1);
    }
  }, []);

  const getAnimationStyle = (index) => {
    return {
      animation: `scale-up-tl 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) ${
        delayOffset * (index + 1)
      }s both`,
    };
  };

  return (
    <div className={`step-content ${className}`}>
      <p className="h2 text-center">Which Year were you born?</p>

      <div className="year-grid">
        {Array(10)
          .fill(data.decade)
          .map((_v, i) => (
            <div
              key={data.decade + i}
              className="number-box"
              style={getAnimationStyle(i)}
              onClick={() => onSelect(data.decade + i)}
            >
              <div className="number-box__inner">{data.decade + i}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default YearStep;
