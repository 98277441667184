export const SoulUrgeNumberScript = {
  1: [
    "The vowels in your name add up to a soul urge number of 1. This tells me that your greatest desire is to lead. Whenever you have to follow someone else's leadership, you become disgruntled and unhappy because you think that no one can handle the reins of leadership as well as you do. Your desire for leadership can be as unquenchable as the desert's longing for rain, and this thirst may sometimes make it challenging to bow in respect to those seated on authority's high chairs.",
    "When you're admitted into a new school or a new office, the first thing you do is seek out opportunities to lead the crowd. That's an excellent thing about you, because it helps you identify chances for progress and seize them before anyone else. If you follow your soul's urge, you'll take the initiative wherever you find yourself and write your name in the sands of time.",
    "In addition to leadership, you yearn for independence. You want to be your boss, not be subject to someone commanding you up and down. While your hunger for freedom, if used wisely, can push you towards entrepreneurial ventures, it can also negatively affect your growth. You think of yourself as such an island that you blatantly dislike learning from others or accepting that you don't know everything. This thought pattern clouds your brain and makes it harder to soak up new information daily because you believe you know best.",
    "You can't thrive in a relationship where your partner wants to lead as much as you do. Instead of loving your partner and showing them that love, you'll be too busy mapping out ways to prove to them that you're superior. You may become highly competitive. Everything you do for your loved one is done out of arrogance, not affection. Even when someone compliments your significant other, you'll find a way to link the compliment to yourself and make your partner look small.",
    "Your primary advantage over people with other soul urge numbers is that you identify who you are very early in life. You have a strong sense of identity, so you don't feel like you don't belong or rely on others to validate yourself. This makes you do your things your own way. You're very proud of your originality and dislike moving with the bandwagon. Your soul urge number is potent, but if you let it dominate other numbers in your numerology chart, it may ruin your life.",
  ],
  2: [
    "Your heart desires to support others, so your soul urge number is 2. You're the protector. You are the one who stands by others to guard their hearts and dreams from the wounds of life. You know that you have many brilliant capabilities. But instead of using your talents to lead, you want to use them to advance the administration of those in charge. As a result, you're among the most loyal allies any leader can wish for. Even when the going gets tough, you can't bear to desert those you support. ",
    'The blessings of your soul urge number lead you to love wholeheartedly, without restraint or conditions. However, this love can cloud your vision. When you like someone, you latch onto that spark and brighten it until all you can see is the light of their goodness. In this pursuit of connection, your heart often makes excuses for the darker aspects of their behavior. Your love becomes a soft blanket, ready to shroud even the sharpest edges. This leaves you vulnerable to the wounds of an unhealthy relationship.',
    "Take a moment to question whether your love might be losing balance. Do you find yourself defending those who bring you pain or becoming upset when friends encourage you to walk away? It's important to navigate these waters with both wisdom because your soul urge number might chain you to relationships that do you more harm than good.",
    "You tend to follow your heart, not your head. So rather than make decisions based on rational grounds, your emotions often influence you. It's easy to lie to you because you're easy to sway. ",
    "Others may mistake you for a weakling, but you're not. The reason why you act irrationally at times is that your feelings are very much alive, and your conscience is very delicate. However, you live in a cold world that will do anything to take everything from you. Use your heart when you should. But also learn when to trust your head to lead you in the right direction. ",
    "Your greatest weakness is that you don't believe in yourself. You often doubt the sensibility of your ideas, thinking that you don't add much value to the world. You can uplift others and remind them of their outstanding capabilities. But you have no problems writing off yourself. This self-doubt makes you yearn even more to obtain approval from others. In the end, you focus on pleasing everyone but yourself.",
  ],
  3: [
    'Your soul urge number is 3, the digit of creativity. Unlike other soul urge numbers, the number 3 does not merely signify what your heart yearns for but what your heart has been empowered to do. At the bottom of your mind lies immense talent waiting to create something new.',
    "If you've caught yourself staring at artworks for longer than usual or wishing you could reproduce something as good as your favorite artist's work, that's your soul's urge calling out to you. Your hands itch to swipe a paintbrush across a canvas, assemble machine parts, or produce the latest technology that no one has ever imagined could be built.",
    "Your creative instincts, coupled with a go-getter attitude, practically guarantee success in whatever you pursue. Don't think for a second you're one to sit back. Your true calling is to take the world by storm.",
    "You were born to connect with people, so you effortlessly have heartwarming interactions with everyone. Not only do you showcase your creative works with ease, but you also get paid for all that fantastic talent you're harboring. So when they stand in awe at the masterpieces you unveil, accept the truth that no one does it quite like you.",
    "Your perfect partner is the life of the party. Their spark of excitement should match your own. You crave someone who shares your sense of humor, diving headfirst into joy with you. You both dream of exploring the world together, admiring each other's every quirk and uniqueness.",
    "But don't forget that love is a partnership, not a game. You may not aim to control, but you want a proportional share of power. And you can't let your heart's desire cloud your judgment. Relationships can't be a non-stop party. That's simply unrealistic. So when the occasional routine sets in, resist the urge to cast the line for something new. Remember, love is a journey, not just a destination.",
    'Despite your passion for creation, you procrastinate a lot. You have all the power you need to work, but you lack the will to work, so you keep procrastinating your chores. This puts your dreams on hold. Your tasks pile up, and everything you could have achieved remains undone.',
    "But don't be afraid. Knowing is half the battle. Now that you know how much your soul urge number influences you, that battle is half-won. You're not just powerful. You're a force of nature. Once you rekindle the will to work, there's nothing you can't accomplish.",
  ],
  4: [
    "You're lucky to have the soul urge number 4. This number is the secret behind having it all under control. You hate failures and disappointments and people who don't live up to their promises. ",
    'For you, the most essential aspect of life is being successful. You crave success as much as a hungry man craves food. Every step you take is propelled by your overwhelming desire to succeed. When people tell you to chill, you become agitated and work even harder. ',
    "Your deep drive to get things done manifests even at work. Bosses and clients love your efficiency and professionalism, and you're almost irreplaceable. ",
    "At your core is the yearning for a stable life. You want everything to fall into place according to plan. If you haven't already, you'll plan when you want to get to a certain height in your career, when you want to get married, whom you want to marry, and even when you want to have kids. You're the kind of person that starts saving for retirement the moment you receive your first paycheck. Your approach to life is slightly rigid, and you cannot be spontaneous. ",
    "Your soul urge number can be deceptive because it makes you think that simply planning everything out is enough to guarantee everything will be done. So you may refuse to take advantage of opportunities when they present themselves because they weren't part of your plan. ",
    "In a relationship, you're usually the boring partner. You're boring because you're predictable. Once someone gets close enough to you, they can easily guess your next moves. Ironically, you want to be in a relationship with someone who's a polar opposite, who can help you relax and motivate you to have fun occasionally. ",
    "Your triumph won't be a result of your soul urge number alone. This is because the soul urge number 4 tends to be the weakest link in the success chain. While having plans is good, you need a certain level of spontaneity to compete with others to achieve the goals you've set for yourself. No one will let you realize your plans without attempting to sabotage them. And that's the problem. You barely have a plan B. ",
    'However, your tendency to be secretive and cunning often saves you from being defeated in the race for victory. You never fully reveal your plans to anyone, so they can never ambush you. ',
  ],
  5: [
    'Your soul urge number resonates so deeply with who you are. Yours is number 5, the seeker of liberty. This number whispers to your soul, and it responds with a craving for freedom. Once any project, job, or environment appears too restrictive, you search for a way out. ',
    "You're not too fond of labels in relationships because you dislike the idea of feeling like you're someone else's property. You believe everyone should have the breathing space to do what they want. While you don't necessarily want to rebel, you have no problem with screwing the rules and doing your own thing if the rules seem too harsh. ",
    "You interpret attention as clinginess and so may not appreciate a partner who's extremely sensitive and caring. Your heart wants a person who loves their freedom as much as you love yours, who'll travel the world with you, and who will always be ready to take the plunge with you. They would always be by your side, yet never in your way.",
    "You love a challenge. For you, it's a breath of fresh air. Whether it's reaching a new level in a game you've been playing, hunting a new lover, or fighting for a novel cause, you're down for it. You also enjoy the excitement of not knowing what will happen next. As far as you're concerned, life is meant to be enjoyed to the fullest. ",
    'You dislike stability, so you always look for something to disrupt established patterns. When your life appears too peaceful, you become unhappy and ache for conflict, anything to stir up drama. You may quarrel with your lover, pick at non-existent faults, or even cheat. This excessive desire for change makes you roll your eyes at healthy relationships. ',
    "I need you to understand that a healthy relationship can still bring laughter, adventure, and surprise into your life. It's essential to respect and honor your romantic partner without compromising your mutual happiness.",
    "Staying in one place for too long feels weird to you. Once you've learned the ropes of your current workplace, you begin sending out applications for new positions. You're less concerned about whether those jobs will be mentally and financially healthy for you. As long as you can switch to them, you're good. Although you may not be the best entrepreneur, you'll make an excellent consultant. Rubbing minds with enterprising individuals can help you enjoy the freedom of monotony you desire.",
  ],
  6: [
    "Having the soul urge number 6 makes you want to care about others. The fact that you want to doesn't necessarily mean that you will care for them. However, your heart responds when people call out for help. Even if you're not in the position to render assistance, you find yourself wishing you could save them from their troubles.",
    "On the outside, you may have a pineapple exterior. But inwards, you're soft and pliable. You harbor a latent compassion towards living things, including plants and animals. This is why you're so conscious of the health of your environment.",
    "However, your soul urge number is among the less dominant ones, which means that you may not manifest all or most of its characteristics. While you may want to love others unconditionally, you may be held back by factors like self-preservation. I sense you've once witnessed someone suffer while remotely wishing you could help but decided it was better to let them endure their pain alone.",
    "When romantically involved with someone, you want to give them all of you, your spirit, soul, and body. You itch to do their bidding and satisfy them with all your might. If there's a disagreement between you and your partner, you're more likely to compromise to please them. Not everyone can love people enough to compromise for them, so your wanting to compromise deserves an award. On the flip side, there's a high probability that you'll enslave yourself to others in the name of loving them. You may lose sight of your needs since you're busy catering to theirs. Bit by bit, you transform into a tired version of your once cheerful self and become someone even you cannot recognize.",
    "You prefer buying others clothes, shoes, and food. Yet when it comes to yourself, you don't spend much money. This is because you derive satisfaction from keeping others satisfied. Maybe you're even a little self-centered, seeking appreciation from your beneficiaries. Even if you don't have the money to borrow or give someone, you may want to help the person so badly that you can borrow the funds to assist them. If you follow the dictates of your soul urge number, your list of friends will be pretty long. However, it's on you to discover who truly likes you for who you are, not what you have to offer. When true friends surround you, you won't have to go out of your way to feel seen, understood, and valued.",
  ],
  7: [
    `Your soul's urge is represented by the number 7, which signals that you're always hungry for information. You're not satisfied with just surface knowledge of things, but you seek to gain deeper insights into the nature and causes of events. While others are happy knowing the "what," you want to discover the "why."`,
    "However, you tackle the challenge of choosing whether to follow a quest for knowledge or to pursue worldly riches. If you amass wealth instead of growing your reservoir of enlightenment, you'll feel unfulfilled and frustrated. No matter how well-settled other aspects of your life are, you're uncomfortable if you think you haven't learned all you need.",
    "In high school, you're the kid who doesn't mind skipping prom as long as you get into the college of your dreams. While your classmates enjoyed the excitement of being young, you were tucked away in your dorm room, flipping through textbook after textbook. One degree isn't enough for you. You're ready to climb the educational ladder, spending your life chasing academic accomplishments.",
    "When you think about it, keeping knowledge to yourself doesn't seem right. You believe that sharing what you know is the way to go, and you eagerly step up whenever the chance to enlighten others comes around. Sometimes, that excitement takes over, leading to moments where you might come across as talking down to others. But we all make mistakes, and it's just because you're so passionate about spreading the wisdom you've discovered.",
    "People appreciate your generous spirit when it comes to sharing your understanding. They come to you when they need help wrapping their heads around those perplexing topics, and you're more than happy to help.",
    "Living with this soul urge number might make you feel caught in a constant battle between your soul's quest for learning and your body's desire for worldly pleasures. This struggle makes you feel disconnected from life, like an observer rather than a participant. Your reliance on logic even influences your relationships. It's hard for you to give in to love because it doesn't have a rational explanation.",
    "When you come to grips with the essence of love, you'll open the door and invite it into your life. It'll make you spread understanding with empathy rather than ego. And once you learn how to love, it'll thaw your inherent coldness and smooth over any cons that come bundled with your soul urge number.",
  ],
  8: [
    "Your soul urge number turned out to be 8. The desire for money influences most of your good or bad actions. Before taking any step, you're inclined to think about how many dollars richer that step will make you.",
    "No matter how much money you have, you're never satisfied and always thinking of new strategies to add one more cent to your stack of cash. You want to be an excellent investor who interprets market signals correctly and uses them to make brilliant financial decisions.",
    "You're the one who calls the shots in relationships. When you have someone, you want all for yourself, you immediately start showering them with moolah. To you, money is not just bits of green paper but a mighty tool.",
    "You trust the power of your dollars and cents so much that you'd rather make it rain on a problem than knuckle down and face it upfront. In your world, money does the talking, nudges troubles out of the way and helps you glide over them instead of wading through.",
    "There's a war going on inside you. On the one hand, you love lavishly displaying your wealth, and on the other, you long to invest and pile on more dough. It's the classic flash vs. stash debate. But the urge to grow your riches more often tips the scale because you can't resist the call of cold hard cash.",
    "You despise the days of your humble beginnings and being reminded of the kind of person you were before the money started entering your hands. You'll do anything to get to the top 1% of people in society and everything to keep yourself there.",
    "Sometimes, the desperation associated with your soul urge makes you so defensive of your achievements that you'll take down anything and anyone standing in your way. Because of the occasional bad things, you have to do to retain the grasp of power, you have to select your pool of friends wisely. You let only the most trustworthy people into your inner circle and keep many secrets about even the simplest things because you believe that you should never be caught off-guard.",
    "But you can't always get what you want. Sometimes, life throws you curveballs, and you might end up just going with the flow, financially speaking. Make the best out of what you've got, whether being an average Joe or dealing with a few investments gone sour.",
  ],
  9: [
    'Your soul urge number is 9, and that makes you part of an incredible group of people who know that life is about more than just themselves. Your heart is on a mission to seek the best ways to help others thrive.',
    "For you, life is not all about personal gain. You don't care if you're left hungry, cold, or even missing out on that magical thing called love. As long as the people around you can savor life's beautiful experiences that make it all worthwhile, then you know you've done your job.",
    "You're the agent of sacrifice. Everything you do, you do it so that someone out there can have a higher standard of living. I see you happily satisfied with living a minimally good life in a humble apartment, filling your head with worries about humanity.",
    "As a public servant, you'll be immune to the desire to participate in the streak of corruption that has consumed the government. And while you're busy being the saint at work, your coworkers and superiors will be jealous that you're disciplined enough to uphold the principles of service to your country. So they'll frustrate your promotion.",
    "When you're tired of professional stagnation, you'll venture into private practice. Even if you don't want to, your heart won't let you stay and do nothing while the masses reap the consequences of your inactions.",
    "You're saddest when you pass by an orphanage or an animal shelter. Conversely, you're happiest when you part with a substantial chunk of your income towards those in need.",
    "Your selfless nature isn't just impacting the world. It's also turning heads. An endless supply of potential partners lines up for you, all thanks to how much you care for others. But the moment you decide to enter into a committed relationship, things can get a bit stormy.",
    "Your partner might want to pull your attention in their direction, but they won't succeed because your heart is devoted to humanitarian causes. You're just not wired for all-consuming romance. Your focus stays fixed on saving the world. So, you wish your partner would understand that it's not that you don't care for them, but cuddling all night every night isn’t your style when there's a world out there in need of heroes like you.",
    "Someday, you'll find that perfect match who shares your passion for change and gets it. Until then, manifest your soul's urge with pride.",
  ],
  11: [
    'Your soul urge number is 11. This number makes you crave spiritual peace. Unlike the soul urges of other numbers, yours is a bit hard to define and harder to express, but it plays a dominant role in the working of society.',
    "Now, let's delve a little deeper. You see human existence as a two-story house with a physical floor where all the senses live and a loftier spiritual floor where the soul thrives. To others, the physical realm is all there is. Their days devolve into a dizzying race for more material possessions. You, on the other hand, are wired to plunge deeper and recognize how much the spiritual influences the physical.",
    "You don't just see. You discern that forces are at play in the spiritual space opposing human progression and growth. Your energies are subtle but powerful. Deep in your heart, you want to guide others to find that inner equilibrium, to tune their hearts into the sweet symphony of nature's life-giving energy.",
    "Your spiritual consciousness is both a blessing and a curse. Your extreme consciousness of the workings of the spiritual makes you appear strange to others. However, it also gives you the gift of foresight. While you're no fortune teller, most things you say come to pass.",
    "Riding the spiritual wave non-stop means you sometimes struggle with blending in amongst the crowd. You focus so much on the non-material realm that you leave the physical aspect of your life barren and undeveloped. Of course, others can only see the physical side of you. When all they see is an individual with no future ambition and no material possessions, they'll label you a failure.",
    'Ordinarily, you would be inclined to listen to them and become a better version of your physical self. However, your soul urge number is so strong that it could trap you on the journey of constantly seeking spiritual harmony.',
    "You're an intuitive partner. You're always on point and tap into your lover's deepest emotions as they ebb and flow. You feel their highs and lows as if they were your own. Unfortunately, you're too wrapped up in your spiritual bubble to respond to their needs.",
    "Your lovey-dovey might not be as thrilled about your laid-back stance toward material goals. Their desire for you to play in the world a bit more might make them feel a tad dissatisfied with you. The day you realize this, you'll invest in your physical advancement and become a better lover.",
  ],
  22: [
    "Your soul's urge is influenced by 22, which implies that you want to be remembered for something. In your case, that something may be good or bad. It may even be despicable. But you can't stand spending all your days on Earth without leaving your mark behind. You love the idea of being immortalized through a statute, of your name being carved on a plaque or a monument.",
    "You want to be referenced by future generations as the pioneer of a particular event, activity, or establishment. This is why you're such a huge fan of breaking records. In your opinion, it makes perfect sense to save up money through years of labor to pour all of that money into the invention of a machine or the discovery of an element. You don't mind living off the thrill of being the latest subject of discussion in town.",
    "You're not after wealth or success or power or fame. You're after the preservation and reverence of your memory after your death. This is why you're so passionate about doing what has never been done before.",
    "Your heart is bold and confident, and you're not afraid of encountering multiple heartbreaks in your search for love. This empowers you with the resilience that most people lack. When in love, you love fiercely, with everything within you. You make sure your partner knows how much they mean to you. The only caveat that comes with your love is that your desire to do something tangible for which you'll be remembered forever often supersedes your feelings for your loved one. This may make them doubt if you truly love them or not.",
    "Now that you've figured out what your heart longs for, your next task is discovering what you want to be remembered for. Some people with this soul urge number want to be remembered for their inventions, so they venture into creative fields. Now, others have their hearts set on taking up the mantle for the less fortunate. Their path? Philanthropy. They aspire to be remembered for their compassion and their reach-out-and-help attitude.",
    "But there are those with this soul urge number that embark on a journey to the top echelons of the corporate world. They don't just want to play the game. They want to be the game.",
    "What's striking about your soul urge number is that it's adaptable. It doesn't just sit there. It motivates, inspires, and propels you towards your dreams. It helps you flip the script from imagining your legacy to living it.",
  ],
  33: [
    "When your family gave you your birth name, they had no clue of the fantastic gift they were handing over. It was more than just a string of letters, as it gave you a rare chance to have the soul urge number 33. Your most tremendous yearning is to please those closest to you, especially your family and friends. You don't live for yourself but for the people in your domestic circle. You're more concerned with the problems of your relatives. Although you don't always show it, you don't want anything to hurt them.",
    "The easiest way to pull you down is to attack your loved ones. You'll do all in your power to save them and even offer yourself up so they can be set free. Their joy is your joy, and their peace is your peace. Your satisfaction stems from seeing the people you care about doing well, so you don't mind putting your life on hold so theirs can move forward.",
    "When you're the family's firstborn, your siblings rarely suffer because you have no issues with working hard to take care of them. Even when you're not the firstborn, you feel you owe a deep sense of responsibility to your flesh and blood. You even organize your finances to ensure you send a huge chunk of your income to the upkeep of your parents or guardians.",
    "During childhood and adolescence, you're perceived as selfish and uncaring about the people you claim to love. That's because your soul urge number usually manifests later in life, from your new adult years to your late fifties. It's during this period when you actively earn a living that your soul's urge to take care of your loved ones becomes stronger. Then you have to make the tough choice of caring for yourself or caring for them. Often, your soul urge wins, and you happily place yourself on the back burner.",
    "No dazzling career prospects could lure you, not if they mean boarding a plane and leaving your loved ones behind. Don't mistake it for a lack of ambition. You've got dreams as high as stars. It's just that even the fanciest job loses its charm if it means not getting back home to your gang every night. As a result, you pass through life hoping your loved ones are as loyal to you as you are to them.",
  ],
};
