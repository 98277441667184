import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PDFCoverImage from '../../../../assets/images/numerology-pdf/1.png';
import { updateLastReadingNumbers } from '../../../redux/slices/ReadingSlice';
import OrderForm from '../../order/Order';
import './OutroOrder.scss';

const OutroOrder = ({ personalData }) => {
  const dispatch = useDispatch();
  const [contentViewType, setContentViewType] = useState(''); // pdf-cover, order-form

  useEffect(() => {
    setTimeout(() => setContentViewType('pdf-cover'), 15000);
    setTimeout(() => setContentViewType('order-form'), 81000);
  }, []);

  useEffect(() => {
    if (contentViewType === 'order-form') {
      dispatch(updateLastReadingNumbers(personalData));
    }
  }, [contentViewType, dispatch, personalData]);

  return (
    <div
      className={`outro-order ${contentViewType ? 'display' : ''} ${
        contentViewType === 'pdf-cover'
          ? 'content-pdf-cover'
          : 'content-order-form'
      }`}
    >
      <div
        className={`pdf-cover-block ${
          contentViewType === 'order-form' ? 'hidden' : ''
        }`}
      >
        <img src={PDFCoverImage} alt="pdf cover" />
        <div className="pdf-cover-inner">
          <p className="title name-text">{personalData.name}</p>
        </div>
      </div>

      <div
        className={`order-form-block ${
          contentViewType === 'order-form' ? 'display' : ''
        }`}
      >
        <OrderForm personalData={personalData} />
      </div>
    </div>
  );
};

export default OutroOrder;
