export const NameNumberScript = {
  1: [
    "Your name number is 1. You have a strong spirit and a stronger soul. People can't easily toss you around, deceive you, or manipulate you. You only follow a route when you're convinced it's the best for you, and only you can determine what's best for you. You'll find it easier to dodge unnecessary pitfalls if you listen to and appreciate the suggestions of others. Whoever you listen to should be more competent, wiser, and experienced than you. There's no harm in taking advice from your peers, but they're usually reluctant to table before you any strategy they know is contrary to your plans. If you can, find a mentor ready to tolerate your arrogance and slightly rude manner of speech.",
    "I feel you'll be in a better space to utilize your innovative gifts if you have a closer relationship with your family. You may have fallen out with your parents or siblings recently or some time ago because you were too stubborn to see their side of the coin. While submitting entirely to their demands may veer you off your ordained path, interacting with them regularly will help you feel warm, remembered, and not alone.",
    "Your personal finances don't suffer depletion. You know how to balance your income with your expenditure. But you're a leader, and more often than not, you may feel pressured to make certain expenses that will hurt your organization's wallet. To prevent such instances from taking you by surprise, you should operate with a budget with room to accumulate unexpected expenditures.",
    "The letters of your name have made it evident that your success is sure. After all, you're selfishly focused on your life's goals, so focused that you want everyone else to remain in the dust. You're also very jealous, so you're usually unwilling to help others rise. However, you can host masterclasses or free classes to enlighten everyone else who aspires to be where you are on what it took you to get there.",
    "You have a high sense of your opinion, believing you're never wrong. Although it's possible for you always to be correct, you need to learn how to accommodate conflicting opinions. The accommodation of these opinions broadens your perspective about the situation and accelerates the pace at which you run.",
    'I understand that you enjoy criticizing the achievements of others. Try constructively doing that. Offering destructive criticism makes you appear bitter and pushes your followers farther from you. It can even make them revolt against you.',
  ],
  2: [
    "Your name number is 2. Even when the world goes dark, your emotional radar is always on. You've got this one-of-a-kind gift for picking up on everyone's feelings. Put that empathy of yours to work, will you? Use it to throw a lifeline to those feeling down in the dumps. Speak from the heart, and people will listen to you.",
    "In a way, you can bring hope to the victims of life, not just by giving them the things they need for the body but by feeding their souls. You may hate yourself for feeling too much, but it's not your fault. Instead of blaming yourself, learn to disassociate your emotions from logic. You can feel emotional but think logically. This way, you arrive at impressive decisions and maybe implement them even faster.",
    "You're an ambassador of peace. But deep down, you wish you were stronger, more dominant, more forceful. But you don't know how much strength lies in your softness. You can assert your opinions without being loud. The only thing you need to do to get people to listen to you is to come out of your shell.",
    "However, you may not make it far in life with your diplomatic attitude. Life won't give you what you think you deserve, but only what you demand. Demanding something indicates asking for it with a hint of force. So it would help if you had a little force in your life. If you're unable to trade off some of your gentility for forcefulness, find someone forceful by nature. They'll drag you along when you want to sit down and rest and inspire you to fight for your right when you're about to give up.",
    `You may have had a rough or easy childhood. However, what happens during childhood doesn't matter once adulthood approaches. Open your heart and let go of whatever experiences made you retreat into yourself as a child. The world doesn't see the "child" version of you. The world doesn't care about the "child" version of you. The world only cares about how you're doing now, present, and in the future. So show everyone they were wrong to think you'd never amount to anything because of your timidity.`,
    "Also, use your imaginative talent for something worthwhile. Constantly conjuring up fictional worlds in your head won't help you go anywhere. Try writing poetry, prose, plays, or other avenues of artistic expression. And if you don't know how to do these yet, learn.",
  ],
  3: [
    "Your name number is 3, and it defines your aspirations. You want to dip your toes into everything this vast world can offer. A love for the arts, with its complexity and creativity, fills your heart. You're equally drawn toward social sciences, holding a fascination for the intriguing interplay between human psychology and money. The realm of sciences tugs at your curiosity as well, with its stream of technological innovations.",
    "Yet, as hard as it might be to accept, you can't do everything, least of all simultaneously. Ask yourself which field sets your passion aflame. That's the field you should delve into. When you narrow your options, you can give your best to the field that resonates with you.",
    "You're constantly fluctuating between different things, never fully settling for one and never fully abandoning the other. Such a pattern can obscure your potential in many ways. It might make carving a name for yourself in any industry difficult. The constant switching can dilute your authority, making it harder for your views and suggestions to command the respect they deserve.",
    "While change is the very essence of life, people often have a preference for steady, reliable professionals who've marked their presence over time with consistent contributions. Before starting any job or business, ensure it matches your goals for the next five to ten years. Then, you'll be more likely to stick with it for longer.",
    "You're one ball of charm, and you can easily entice anyone to converse with you. However, it's necessary to know your limits and respect people's personal space. Don't mix up your professional life with your personal life. Save the excessively jovial side of you for your friends and family. To everyone else, be friendly, but not too friendly. This will increase your chances of being recommended by your superiors for professional opportunities.",
    "And cultivate the habit of being disciplined. Take your financial goals seriously. Don't splurge on irrelevant things. If keeping a money diary isn't helping much, try having an accountability partner who'll monitor how much you spend.",
    "You're a social person, eager to share your daily experiences with others. You find comfort and security in the company of others, and that's okay. However, relying too much on others for your well-being can make you somewhat dependent. Real happiness begins from within, not from outside sources. When you focus on being there for yourself, your life will be more balanced and fulfilling.",
  ],
  4: [
    'As a person with a name number of 4, stability is essential to you. Surprises can be troubling, and emergencies can cause you to lose your composure. You resist change, trying to hold on to what is familiar and secure.',
    "However, change is a natural part of life and crucial for the world's development. By resisting it, you might be closing off opportunities for growth and progress. If you're not careful, the world can move forward, leaving you struggling to stay relevant.",
    "Embracing change might not be easy for you. It's crucial, however, to realize that change represents shifts in positions and circumstances. By focusing on the positive outcomes that can arise from these changes, you'll be more equipped to adapt and move along with the flow of life.",
    "You're uncomfortable around anyone who doesn't share your rigid habits. You're reluctant to accommodate the differences of others. This usually becomes a huge problem when you switch jobs or get started at a new school. While your family may have tolerated you, you won't enjoy the same forbearance outside because everyone isn't your family. So rather than throw tantrums or hate on people for their being different, try and get to know them. It might occur to you that maybe they're not that bad.",
    "You arrange upcoming events systematically, so your presence in any organization keeps things from falling apart. And, you're content to remain where you are, neither progressing nor retrogressing. If you continue like that, you may be rewarded for your staunch loyalty to the organization, but that's all you'll get. Don't just use your mind to make plans. Use it to conceive big ideas. Aim higher.",
    "I perceive that you lean heavily on the pessimistic side. Rarely do you seem to consider an activity successful unless it strictly follows proven pathways. When you look at situations, you spot the disadvantages easily, but somewhat surprisingly, you don't take active steps to prevent these drawbacks from taking shape.",
    "Shaking off this mindset might be a struggle for you at the moment. But don't let pessimism stand as a barrier in your life's journey. Experience the joy of living. Try going on dates if that's what you fancy, or go hiking or embark on a trip somewhere. If it doesn't hit the mark, at least you've savored the process of giving it a shot.",
    'Life is a beautiful blend of tiny victories and hard lessons. Go out there in all your human imperfections. Try, err, learn, and grow from it.',
  ],
  5: [
    "Your name number is 5. That's the number of freedom. When you're finally free from whatever used to restrain you, you tend to let loose completely. You splash cash on nights out, fill up on fast food, and take people along for the ride, happy and carefree as you are. You might even find it easy to tell a few white lies if it helps protect your newly found free-spirit lifestyle.",
    "The thirst for freedom is a powerful force. If you don't control it, it will control you and frustrate your destiny. The first step in managing your freedom is accepting that you're never truly free. As a human, you trade one form of control for another. As a child, your parents guide your actions. In your adulthood, it's often your bosses or clients dictating what you do next. Even if you're not working, your body has needs and desires that you must address. So instead of wildly chasing unattainable liberation, aim to live a decent life. You'll uncover a unique, natural freedom that aligns with your identity.",
    "You're an engine of change. Infuse this transformative energy into your own life in a positive way. And when you make a decision, act on it swiftly. In the game of life, opportunities knock but don't always wait.",
    "You've been moving at a fast pace in your love life. You may have had some romantic adventures in which your heart was broken, or you broke someone else's heart. Ducking in and out of relationships as often as you do can start to take a toll on you emotionally.",
    "I understand you like the rush of not knowing where a new fling is going. However, clarity in your intentions can save a lot of heartache down the line. If you feel that you're not ready to anchor yourself in a committed relationship, it's best not to label what you have with your partner. This approach helps lessen misunderstanding and emotional distress.",
    "Concentrate on the benefits that await you after putting in the hard work. Then, you'll overcome the temptation of continually starting projects without seeing them through. Keep your future in mind instead of only living for the present moment. Make decisions that will positively impact your life in the long run. When you adopt this forward-thinking mindset, you'll find yourself becoming less undisciplined. You'll be surprised at how organized and fruitful your life will become.",
  ],
  6: [
    "Your name number is 6. By nature, you perceive yourself as the parent of everyone around you. Whenever possible, you show them as much love as they can take. You can't find it in your heart to lie to people. If you apply this honesty to your business, you'll cultivate a fantastic relationship with your clients, audience, employees, and employers. They'll trust you because they know that you'll never go back on your promises or cheat them.",
    "Before you try to incorporate honesty into your life on a higher level, start practicing it with those close to you, like your family and friends. Avoid giving in to the desire to tell lies. When you choose to lie, it implies that you don't value the person enough to be truthful with them. But deep in your heart, you know that's not who you are.",
    'For someone as caring as you, your challenges often arise within the family circle. Sometimes, your family might appreciate less oversight to breathe and be. Instead of investing all your time and energy into their lives, do something solely for yourself. Enjoying a hobby or pursuing a personal interest can wonderfully recharge your batteries. As contrary as it may seem, this is not selfish. It is also a part of caring for others. By keeping yourself happy and healthy, you ensure you can continue to look after their welfare effectively.',
    "I sense you've been the obedient child, doing what your elders wished you did. While you may not have it in you to rebel against their wishes, you certainly get them to understand your point of view. You already occupy precious space in their hearts, and it won't be hard for you to convince them to allow you to follow your path.",
    'You accept responsibility for your actions. Mistakes are a part of life, and instead of hiding from them, you own up to them gracefully. This captures the hearts of those around you and makes them appreciate you more.',
    'Consider canalizing this virtuous trait of yours towards something even more extraordinary. Management courses could help you further groom this talent. They would undoubtedly pave the way for professional excellence.',
    'The range of professions open to you is wide: Financial advisory, human resource management, investment banking, accounting, or even political service. These are all domains where your natural sense of responsibility would be deeply valued.',
  ],
  7: [
    "Your name number is 7, the number of knowledge. You're wired to read your way to the top. If you are yet to start, consider curating a library. This could be a collection in a quiet corner of your home or a virtual library on your device. Fill it with books that ignite your curiosity and interest, take you to places you've never been, and help you understand things from a different perspective.",
    'Commit to reading at least one book a day. Vary the type of books you read. This habit will empower your mind and toughen your mental muscles.',
    'Keeping all the knowledge to yourself may not be the best way to maximize your efforts. Knowledge is not a finite asset that diminishes with sharing. So, share your insights with others. Use social media to spread the word about the new things you discover daily. Organize masterclasses or host webinars or seminars to increase your outreach.',
    "You marvel at the complex aspects of the game of politics, but why not try and play a little politics yourself? If you're in high school or college, consider running for student council positions. However, should you be in a position beyond those educational institutions, why not venture into politics in the broader world? It could be as small as a community or local government role or as adept as participating in national discourses.",
    'Lean away from political apathy. An armchair critic might have an opinion, but one who gets involved can drive change. Your participation could be the missing element to clarify the obscured aspects plaguing our politics today. Rather than relying on a hit-or-miss approach as some do, your potent blend of instinct and insight can serve as a reliable compass. With it, you can discern the actions necessary for the collective good and understand the best timing to execute them.',
    "Don't sit around waiting for what you want to fall into your lap. Life has shown us time and time again that it rewards those who take the initiative and those who strive for their ambitions. It's great that you possess a reservoir of knowledge, for knowledge has its own way of opening doors. But that alone isn't enough. Your skills, abilities, and experiences are your treasures. Showcase them to the world with pride.",
    'Respect your physical inclinations. If you enjoy having a good time, acknowledge that with openness. Listen to your body, notice what it needs, and enjoy the joy of fulfilling those needs.',
  ],
  8: [
    "Your name number is 8. You aspire to have plenty of riches and power. But have you considered what happens when you're no longer in charge? After piling up your wealth during your youth, you'll find someone to expand your business empire as you age.",
    "You'll be left with just nominal authority and all that you need to survive, while your successor will have everything you've worked for. And if you had hurt others to get to where you are, you may begin to regret hindering their paths to success. This is why you shouldn't put others down to rise. Compete for the crown in a healthy way. Instead of seeing your fellow hustlers as sworn enemies, get to know them. They'll be your backbone when you're old and frail.",
    "As you progress in your journey, let the younger ones know what you did to get to where you are and what you want to do to get to where you want to be. Raise your children in a disciplined manner so they don't become wild and ruin your empire when you're too old to look after it.",
    "Find it in your heart to feel for people. That's hard, considering you may not even feel for yourself. But adding a little empathy or compassion to your emotional makeup will make it easier for you to accomplish your goals, as you'll quickly rally the support of those around you.",
    "Learn to love and accept being loved in return. Don't keep your heart occupied with thoughts of gaining or multiplying material wealth. Over time, your heart may grow so cold that you won't recognize who you are anymore. Devote most of your attention to your goals, but know when you pause in your chase of money and embrace your loved ones. Let them know you're there for them.",
    'You tend to get carried away by your grand plans. While you may know how to invest your time appropriately to achieve desired results, your ostentatiousness can push you to eat the fruits of your labor before they mature. Take your time to better your game and reach the heights of your career before showing off.',
    'And build your mental strength. Your name number has set you out for outstanding achievements, and none will come easy. Arm yourself with the relevant knowledge, skills and strategies before difficulties arise so you can remain steadfast and secure in the face of adversity. Then run your race and claim your victory.',
  ],
  9: [
    "Your name number is 9. You want to rescue the world from destruction while neglecting your immediate family. It's a noble thing to care about humanity as much as you do, but leaving the ones closest to you in the lurch makes you no better than those you despise.",
    "As you accomplish your humanitarian aims, carry your loved ones along. Protect them as much as you protect the helpless in society. Fight for them as fiercely as you fight for the oppressed.  When you're down, your loved ones lend you a helping hand. But if you ignore their requests for assistance while you look like the hero outside, you're building your foundation on sinking sand, and you'll inevitably crash.",
    'Learn how to prioritize. Everyone needs your help, but some need your help more than others. Ranking distress calls in their order of importance helps you fulfill your mission more efficiently.',
    'Since you dedicate so much of your life to helping others, your life may become disorganized. While your peers focus on achieving their personal goals and progressing in their careers, you might allocate the resources intended for your growth to support others.',
    "Selflessness can make you rely on fast foods, as you often don't set aside time for healthy eating. These concerns might not seem important to you, but consider the possibility that your efforts to save the day might not always be fruitful. In that case, what would become of the personal life you've neglected?",
    "Postponing your personal goals and milestones is not a sustainable way of living. Strive for a more orderly lifestyle. It'll benefit yourself and those you help by setting a positive example for them to follow. Find balance in your life, both for yourself and for the lives you touch.",
    "Indulge in your desires with modesty. You're carved out for positions of leadership and authority. Learn how to carry yourself like a royal or a noble person. Refrain from irresponsible behavior both in public and private.",
    "When you begin to manifest your name number in its full capacity, you'll be the center of attraction. You'll gain respect far and wide and be tempted to let your newfound fame get into your head. To resist such temptations, surround yourself with individuals who'll help you stay grounded. Nurture your character and remarkable abilities, and don't pass up chances to learn something new. If the attention is too much for you to handle, take a breather.",
  ],
  11: [
    "Your name number is 11. You appear tough on the outside but are truly quiet and malleable within. People tend to see you as an easy target for manipulation. So focus on learning about people and understanding their behavior patterns. You'll recognize traits common among manipulators, such as gaslighting, envy, and flattery. When you encounter someone displaying these characteristics, trust your instincts and distance yourself from them to protect your well-being.",
    "Be mindful of your finances. Exercise discretion when lending or gifting money. Not everyone who approaches you for financial help should receive it. Ensure you can genuinely afford to give without putting yourself in a difficult position, ensuring your generosity doesn't lead you into hardships.",
    "Consider creating small, bite-sized goals to pursue. Break your goals into manageable, short-term objectives, and you'll find it much easier to tackle them without feeling overwhelmed. Starting with smaller goals makes them more achievable and prevents you from being complacent.",
    "You underestimate your capacities. While a part of you wants to lead, the other feels that you're not cut out for leadership and prefers to tag along. These opposing parts are present to make you an excellent leader. You may not currently see yourself as a leader, and that's alright.",
    "However, don't wait for an overwhelming surge of confidence before you try leading. Taking a small step can lead you to more remarkable accomplishments. For instance, you could organize a reunion for your classmates, volunteer to coordinate a local community event, or even participate in leadership classes.",
    "As you gain more experience and put yourself in different leadership roles, you'll gradually understand your unique leadership style and learn how to use it for the benefit of others effectively.",
    "You may have grown used to the idea that what will be, will be. You feel no matter how much effort you put in, you cannot alter the course of events set out for you. That's not an entirely accurate perspective. Each one of us comes into this world on an equal footing. Your growth and the person you become is not dictated by the situations you encounter but by how you respond to them.",
    "You hold within you the potential to be the source of positivity in any room you walk into. Do not stifle your capacity for joy and happiness. Instead of staring at the wall, challenge yourself to look in the mirror. The minute you grasp how powerful your birthday number is, you'll stop trawling behind.",
  ],
  22: [
    "Your name number is 22. You can create something where nothing existed before. Your struggle to be consistent with what you do pushes you into the trap of predictability. People already know what you have in stock without you telling them. While that makes it more difficult for cloners to copy your vision and mission, it makes you stale. Everything you're doing has been done before. If you don't want people to lose interest in you, infuse your works with a creative spark. Challenge your brain to cross that mental boundary you've set for yourself.",
    "Once you burst free from those shackles of predictability, you'll become more than just a builder. You'll become the architect. I see you being among the greatest inventors of your time. Besides, your commitment to completing anything you start will save your efforts from going to waste.",
    "The first step in building whatever you want is not laying the first brick. It's recognizing that even after laying all the bricks, they may eventually crash. This doesn't mean you'll declare all your projects doomed from the start. However, accept the chances of failure. Accepting these chances fuels your desire to succeed even more and keeps you from losing your mind if the venture does fail.",
    "Working alone may not be the best approach for you, but having a supportive partner can make all the difference in reaching your goals. Your strengths lie in collaborating with others, where you support each other's ambitions and keep each other in check when needed.",
    "A meaningful friendship can be precious, especially when you need a nudge in the right direction during difficult times. Forming these friendships might not be your strongest suit. Sometimes you might be critical or dismissive of others' accomplishments, making you less approachable.",
    "It's never too late to improve yourself. Start by being more mindful of your interactions and gradually reduce the negative habits you may have developed over time. This change might not happen overnight, but as you work on yourself, you'll find that fostering genuine connections will become easier. With the right support system in place, nothing can hold you back.",
    "As you live up to your name number, you may find yourself being noticed by various individuals and organizations, some with good intentions and others with less desirable motives. You're not very intuitive, so figuring out which is good and bad won't be child's play for you. It is in situations like this that you'll need someone to advise you. Find that someone.",
  ],
  33: [
    "Your name number is 33. You have a creative spirit that doesn't like to be starved. Once you neglect your creative side, you grow restless and seek other media of expression. You often decide to express your emotions through a human being whom you serve without any restraints.",
    "You won't even care if the person you devote so much time and attention to loves you half as much as you love them. You'll sell yourself to them for free. If you're tired of this happening or don't want it to happen, hone your creative skills. Run away from procrastination because it's the number one enemy of creatives. Make it a point to appraise yourself with the latest developments in your creative field, and train your mind to reason beyond mundane constraints.",
    "If you haven't discovered what you're particularly good at creating yet, that could be because your creativity is meant to be exercised indirectly. You could be very good at creating color palettes for brands, setting up ad campaigns, or rolling out new strategies for the company you work at to implement and succeed. Whatever you're good at, double down on it and grow it so that when love comes your way, you won't become a devoted servant.",
    "I can tell that you're a stickler for rules and regulations. You do what is right at the right time because you fear the consequences of not doing it. You behave honorably because you're scared of the dishonor that'll stain your name if you're punished for dishonorable behavior by the constituted authority.",
    "While your obedience is heartwarming and promotes order in the society, it can also lead to the downfall of society. And you and all you love will go down with that society. This is because you don't question the intent of the rules. You're so dogmatic, following them to the letter. Of course, your task isn't to become a stubborn rebel but to analyze rules before deciding whether or not you want to follow them. This way, your obedience will be more willing than coerced, and it'll bring you joy.",
    "You have a witty charm that's not dependent on how groomed or shabby you look. Express it through your creative works. Allow it to act as a persuasive tool, attracting potential customers to patronize you. You will find that building a devoted audience will be a smooth and worthwhile process.",
  ],
};
