export const StartIntroScript = [
  {
    text: 'Hello {name} and welcome to Mystic Numbers',
    time: 1,
  },
  {
    text: `My name's John and I will be giving you your FREE personalised numerology reading here today.`,
    time: 4,
  },
  {
    text: 'According to the teachings of Pythagoras each of us',
    time: 10,
  },
  {
    text: 'is surrounded by an infinite amount of numbers.',
    time: 12,
  },
  {
    text: 'Each number has a unique vibration and energy structure ',
    time: 16,
  },
  {
    text: 'and that is why the numbers and the fate of people are closely linked.',
    time: 19,
  },
  {
    text: 'Your Numerology chart is divided into 8 different elements with each element ',
    time: 23,
  },
  {
    text: 'revealing invaluable information about your abilities, talents, goals, relationships, ',
    time: 27,
  },
  {
    text: 'opportunities and lifes destiny.',
    time: 33,
  },
  {
    text: 'These Elements are:',
    time: 36,
  },
  {
    text: 'Life Path',
    time: 38,
  },
  {
    text: 'Soul Urge',
    time: 40,
  },
  {
    text: 'Destiny Number',
    time: 42,
  },
  {
    text: 'Personality Number',
    time: 44,
  },
  {
    text: 'Love number',
    time: 46,
  },
  {
    text: 'Birthday Number',
    time: 48,
  },
  {
    text: 'Name Number',
    time: 50,
  },
  {
    text: 'and Planet Number',
    time: 52,
  },
  {
    text: 'I shall start your reading by determining your Life Path number which is considered one of the most important',
    time: 55,
  },
  {
    text: 'and influential numbers within your entire Numerology Chart',
    time: 61,
  },
  {
    text: 'and represents who you are at your core.',
    time: 64,
  },
  {
    text: 'To determine your Life Path number ',
    time: 67,
  },
  {
    text: 'we simply add up the digits from your date of birth, {birthday}',
    time: 70,
  },
  {
    text: '',
    time: 76,
  },
];
