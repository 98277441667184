export const PersonalityNumberScript = {
  1: [
    "There are two things about you that are impossible to deny; your personality number is 1, and you're more confident than a peacock in a beauty pageant. You're like a lion, fearless against foes and ready to puff that chest out and confront challenges head-on.",
    "With you, everything screams royalty, from your poised stride to your impressive calm. You have your word filter, making sure no vulgar language slips out. You don't just walk into a room. You captivate it. Every eye in the room gravitates towards you, almost hypnotized. Your voice comes with a dash of command, painting the picture of someone who needs to be heard and obeyed.",
    "Sometimes, your close friends and family may be embarrassed by your elegance because you don't hide the fact that you think all humans should behave like kings and queens. This viewpoint can make it difficult to adapt to whatever life throws at you.",
    "However, you're made for the throne. Everyone can agree that you possess the charisma and the etiquette needed to govern. Those are great traits for anyone representing their team. Ruling the roost isn’t just about having suitable characteristics. It's about putting your nose to the grindstone and dedicating yourself to the cause. And you've got the determination to pour your heart and soul into whatever you dream of conquering. Keep your chin up.",
    "You have this whole mysterious, intimidating vibe going on. Anyone who doesn't know you well hesitates whether to chat with you or not, thinking you might give them the cold shoulder. We all have a bit of pride in us, but yours is on another level. Sometimes, it feels like people should be thanking you just for existing.",
    "But if your sights are set on a leadership role, you'll need to dial down that ego a notch since overconfidence can lead to messy outcomes. Many people share your personality number. Unfortunately, only a handful enjoy its perks. Most of them trip up chasing their pride, and I figure that's the last thing you want.",
    "Taking it down a few notches can help you spot the people who are there to lend a hand. You may even fall in love. I know you wish for someone as stubborn as you are. But ensure you're falling in love with someone who has a submissive personality, or else your relationship will be doomed from the start.",
  ],
  2: [
    "Your personality number is 2, and it explains why you're that one person in the room everyone wants to sit next to. It's not because you crack the best jokes but because you add a distinct flavor of warmness to every conversation.",
    `There are times, though, when your extreme politeness could pose as a battlefield for others to figure out the real "you." It becomes a guessing game to truly understand whom you like and whom you are just being courteous to.`,
    'Some people might miss out on grasping your persona. They could misunderstand your niceness as a hint of special favoritism towards them. This could set off reactions you never anticipated when you first opened up a line of communication with them.',
    'It is almost magical how even the most guarded souls soften when they connect with you. Your presence is an inviting bubble of safety that makes others feel at ease. Conversations flow, laughter bursts, and walls come down as people find comfort in sharing their deepest secrets with you.',
    'That sense of security swirling around you makes people believe you are trustworthy. They readily share things with you that they might never divulge otherwise. Meanwhile, you masterfully keep your cards close to your chest.',
    "You're quick to comfort and quicker to empathize. When the conversation turns sharply down the road of heartfelt and deep discussion, you swiftly retreat to the haven of your shell. Preplanned words lose their steam, and at the moment to bare your feelings, you step back, the fear of vulnerability nipping at your heels.",
    'Your struggle to unveil your inner world likely roots in an unfortunate experience. Possibly a childhood incident, a chapter from your romantic past, or perhaps a scenario you observed unfold in society. The thought of being the unsuspecting prey in a game of broken trust keeps you on guard. As a safety measure, you lock the deepest chambers of your heart and deny access to all.',
    "You adore a crystal-clear environment, so the mere thought of being in an unorganized place makes you uncomfortable. You don't shy away from domestic duties, so your living spaces often become sanctuaries of calm and order. Walking through your front door is an instant recipe for sanity, a chance to escape from the world's noise.",
    "Beneath your calm demeanor, though, roars a fierce spirit. This fierce side of you only appears when a situation calls for it, such as when you have been wronged or when there is a worthy cause to fight for. Yet, through all these, your integrity doesn't waver.",
  ],
  3: [
    'Your personality number is 3. Of all the personality numbers one could be assigned, three is a coveted spot. As a triple threat, you have the charm, confidence, and infectious zest for life that befits a party animal.',
    "You are the kind of person who naturally takes on the leader's mantle whenever your gang hits the town. It almost seems like you have a magic wand, scoring your crew not just one, but well, a couple of free rounds of drink. You can engage people in your banter all day without them wishing to escape. That's because you possess an immersive charisma that stuns others whenever they meet you.",
    "You are the object of everyone's envy. Those who have had the pleasure of knowing you find themselves daydreaming about what it would be like to fill your shoes. However, they don't understand that any efforts to imitate you are futile. Your personality is not a coat to be worn on or taken off at will. It's intertwined with your very being, as natural to you as breathing.",
    'Your love for a bold, vibrant wardrobe is unmissable. You discovered your unique fashion sense quite early in life. This early introduction has allowed you to connect deeply with your sense of style. The quickest route to your heart is through gifts of fabulous clothing, dazzling jewelry, and sleek shoes. Each piece that joins your collection increases your appetite for more.',
    'You are overflowing with optimism, an optimism usually exclusive to the innocent hearts of children. This reservoir of positive energy fuels your resilience, driving you to look for true love until you hit the jackpot. Unbroken and undeterred, you hold no account of how many hearts have played frisbee with yours.',
    "Your nose is tuned to the scent of money. Whether it's hidden in stashes or present in an opportunity, you can sniff it out. There are smart ones, but you always skate past them and snatch the cheese before anyone else gets to it.",
    "You're always contemplating how to revitalize your relationships. But this could make your partners complacent, lay back, and put in lesser effort. And you thrive on freshness, on energy. When a relationship with someone bores you, you won't hesitate to cut off that person from your life.",
    "Anything you have your eyes on is good as yours already. When you need help, you tell people what they want to hear so they do what you want them to do. That's the art of persuasion unfolding in its finest form.",
  ],
  4: [
    'Your personality number is 4, and I can picture your closet filled with formal, button-down clothes in subdued and calm shades. This really tells me something about your calm and traditional nature. You prefer all things safe - safe outfits, safe jobs, safe projects, and even safe relationships. The lower the risk associated with something, the more it appeals to you.',
    "You don't enjoy lingering in the dating pool. When you date someone, you have marriage on your mind. What you appreciate are those simple and comforting moments, like coming home to a tidy house with warm food and your loved one there to greet you, rather than a night out with friends.",
    'If a stranger strikes you as wild or unpredictable, you prefer to keep your distance. It can be tricky to figure out whether you are happy or sad since your demeanor hardly changes in either situation. Your closest friends know they should not tease you inappropriately or playfully poke at you. These behaviors irritate you, and nobody wants to be on your bad side.',
    'Sometimes you tend to be quite petty, and forgiveness comes as a challenge to you. You often hold grudges, even over minor mistakes others make. ',
    'When it comes to completing projects and following instructions, you genuinely excel. This makes you a valuable member of any team. But keep in mind; this may be because you have a hard time expressing any opposing ideas out of fear of rocking the boat.',
    'As an employee, you would likely perform well. But starting your own business may not be the best path for you. You could struggle with adapting to market changes, such as shifts in consumer preferences.',
    "You might not be the world's best at expressing affection through words, and that's just fine. You have other ways of showing that love and care for your loved ones, like making sure they are comfortable and well taken care of. For you, actions speak volumes, and that is your way of shouting your love from the rooftops without uttering a single word.",
    'When people first meet you, they might label you as a bit of an uptight, rigid person. But while wild and crazy fun might not be your go-to activity, you appreciate the moments when you let loose and enjoy yourself. When you do hang out, you tend to be the reliable friend who stays sober, ensuring that everyone gets home safe and sound after an eventful evening. ',
    'Next time you find yourself being a bit reserved in sharing your emotions, remember that your actions speak louder than words ever could. Never lose sight of the wonderful, responsible person you are.',
  ],
  5: [
    'With your personality number clocking in at 5, it becomes clear that your actions are rooted in your five senses. To you, life is about seeing, hearing, tasting, touching, and smelling everything that is there. You carry the curiosity of a scientist, not afraid to explore and experiment.',
    "Often this relentless quest to squeeze the essence of existence makes you reckless. But you don't care. Trouble is just another experience to be cataloged in the museum of your senses. Take a cigarette, for instance. You would light one for the first time not because you crave nicotine but almost as if winning a bet against convention. Causing someone to fall head over heels in love with you could be about some game or proof of your charm, not the pursuit of love itself.",
    'Money is merely a loyal servant awaiting your commands. You do not let your bank balance handcuff your life. Concepts like savings and investments feel dry on your tongue. You wonder why people hoard money when they can spend it. After all, cash is just another sensory experience meant to be exchanged, felt, and spent.',
    'I sense a remarkable courage in you that laughs in the face of death. Your single wish is to drink life to the last drop before you bid this world goodbye. You go through each day as it comes, unbothered by thoughts of what tomorrow may bring.',
    'Anyone hating on you is merely wasting their breath. You remain oblivious to such antagonism, leaving them to stew in their toxic juices. You understand that, more often than not, disapproval springs from pure envy. People dislike what they yearn to have but cannot possess.',
    `You're afraid of commitments. You're usually the last person to say "I love you" in a relationship because the phrase makes you feel tied down.`,
    "No matter how rich or poor you may be, you always ensure that your outlook is good enough to make a show-stopping entrance into any room. Although you may not focus so much on developing your intelligence quotient, you're usually very street-smart and can survive on your own.",
    "However, you're fond of making brash decisions concerning sensitive matters. This quality only makes people think you're unfit for leadership.",
    'But not everyone comes packaged in the cut-out mold of a leader. With your spirit of fierce independence, you carry the potential to blaze your trail in the corridors of leadership. All it needs is a gentle reign-in on those more unruly aspects of your character. Build on your strengths, and you can navigate through any storm.',
  ],
  6: [
    "Your personality number is 6, and you're among the most caring people ever. You care for others differently, not to take advantage of them or request favors later, but because you don't feel complete without doing good to at least one person a day.",
    "Instinctively, you place the well-being of those around you above all else, even if it means parting with your money or belongings of significant importance. It is this selfless offering of love and care that makes people yearn for your presence. While everyone seems to gravitate towards you, you don't fancy being amidst large crowds. On the contrary, you find pleasure in the company of those you cherish deeply.",
    "Your loved ones truly stand to gain the most as you shower them with gifts and affection like it is your full-time calling. You have a tender heart, so falling in love comes as naturally to you as drinking water. When in love, you're loyal. Even when your heart is wounded by your significant other, cheating on them never crosses your mind. Your every action is fueled by the thought that we ought to treat others how we would wish to be treated.",
    "Respecting others' wishes matters a lot to you. You frequently find yourself caught in a tug-of-war to figure out which of their desires are most deserving of fulfillment. Even when it might be considered overbearing at times, your thoughtful consideration of the situations affecting others compels you to go the extra mile in trying to please everyone simultaneously.",
    "Juggling all these varying desires can be a challenging task. So, while it's important to honor and respect the wishes of others, don't forget to give yourself some credit when you successfully navigate the turbulent waters of balancing everyone's needs.",
    "You have a hard time choosing favorites, like favorite colors and favorite food. It's not that you cannot choose, but rather that you genuinely love them all. In the same way you advocate for people to coexist peacefully, you also believe that different aspects of life should share the same respect and harmonious balance.",
    'You cherish the solace found in a quiet room rather than the commotion of a crowded hall. Your hobbies reflect this preference, too, as you enjoy indoor activities like immersing yourself in novels, challenging yourself with board games, and getting engrossed in movies. You deeply understand human nature, with all its strengths and weaknesses, which helps you accept and appreciate people just as they are.',
    'However, this acceptance sometimes clashes with your parental instincts. Instead of guiding your children with a steady hand, you lean towards giving them greater independence. And it might just put them at risk of becoming too spoiled. In the same way, you advocate for people to coexist peacefully, you also believe that different aspects of life should share the same respect and harmonious balance.',
  ],
  7: [
    "If you've ever wondered why you voice your opinions so fervently, it is because your personality number is 7. You know the right words to use at the right time, the perfect way to frame them, and the ideal place to voice them. When you piece these three qualities together, you'll understand why you often feel an irresistible urge pushing you to speak and keep speaking until those listening agree with what you say.",
    'At times, your words can seem so offbeat that they stretch the limits of human reasoning. Consequently, you may have earned a reputation for being unconventional. You have little time for traditional religious observance or showing reverence to a divine entity. You might not identify as an atheist, but you strongly discourage following religious rules without thoroughly understanding their origins.',
    'Among your peers, you command the most respect. You impress not only those of your age but also those wiser and more experienced. You possess an unfettered imagination. This imaginative power serves as the fuel that drives you forward, even when the path ahead is steep and rocky.',
    'In any group, you naturally assume the proactive role, unafraid to think ahead and challenge the status quo. But when alone, you become a lone ranger and navigate your flights of fantasy.',
    'Now this does not mean that you possess skills in every domain. Instead, it is your propensity for imagination that allows you to leap across obstacles that would have tripped up others.',
    "For you, love is serious business. You don't like it when the person you love trivializes your feelings. Before you approach your potential partner, you take your time to study them from afar. You don't turn into a creepy stalker, but you don't also rush into relationships. Instead, you gradually build the spark between your significant other until that spark becomes a flame. As a result, your relationships are usually meaningful and satisfying.",
    'You have dedicated so much of your life to pursuing spiritual and mental growth, but that might leave you a bit naive when it comes to practical worldly matters. It would be wise to find someone grounded and realistic to help you stand firm amid these challenges.',
    'You have these amazing, bright ideas. Yet without the right strategies to put them into action, they remain mere thoughts. Recognize these missing elements and seek them out. Give your ideas the execution plan they need to become realities, transforming your extraordinary thoughts into tangible achievements.',
  ],
  8: [
    "Your personality number is 8, and it gives you this incredible feeling that you can achieve just about anything you put your mind to. You're extraordinarily determined. ",
    "The determination you have comes from facing and overcoming numerous challenges. If you are still not feeling that burning desire to achieve your goals, don't worry. It just means those challenges igniting your determination are on their way. And when they arrive, you will rise and conquer them and reach incredible heights.",
    "You are physically, emotionally, and financially strong. You take working out seriously. If you can't afford a gym membership, you find other ways to keep fit. It takes more than a failure to break you down and bring you to your knees in tears. It's as if you were schooled before birth that misfortunes are an integral part of living, so there's no need to collapse under their weight. ",
    "Besides, you're smart with money. You control money, not the other way round. From an early age, you developed a keen interest in learning how to grow one's wealth and were also diligent enough to practice the lessons you learned. Your financial strength makes others presume you had it easy from the start, but they have no idea how rough the road was for you. ",
    'In matters of the heart, your focus is always on what you can do for your partner, not what your partner can do for you. However, showing love is a bit different for you. You show it through giving them material gifts.',
    "You're happy to splurge on the fanciest shoes, the most exquisite bags, or the latest wristwatches for your partner when you return from business trips. But spending quality time with them is something you find challenging. So unless you find a partner with a personality like yours or at least understands this part of you, your relationships may suffer. ",
    "It's striking how fiercely you guard your wealth. Sure, you can be generous when the occasion calls for it, and I don't consider you inherently selfish. However, in the grand scheme of things, you tend to see money as essentially transactional.",
    "You hesitate to splash cash on just about anything or anyone unless there's a promise of significant returns either now or in the foreseeable future. And no matter how wealthy you become, there's always this persistent urge for more. You're on this relentless pursuit to amass as much wealth as possible and often overlook the fact that you'll leave these worldly possessions behind.",
  ],
  9: [
    "Your personality number is 9, so you carry yourself like a king or queen. Your gait is royal and elegant and makes anyone who sees you think that you either have a lot of money or a lot of pride. You're often chosen to represent your organization at external events because you have a firm grasp of how to present yourself to the world. When people get to know you, they're often surprised by how sweet and humble you are.",
    "While you're not into inventing new ways of doing things, you enjoy spinning the old ways and making them appear new. So when you share your works with others, they're inspired and run to you when they need help with similar projects. You don't just talk and argue mindlessly. You're a person of few words. When you speak, your audience knows you must have something important to say and pay attention to your every word.",
    "You're not interested in leadership positions. However, you revel in influencing the people's choice of their leaders. Your personality makes many think highly of you, so they're more likely to vote for your preferred candidate. Even though you aren't officially a member of the government, you're among those the government turns to when it's trapped in a corner.",
    "People often find it hard to guess whom you have romantic feelings for. Regardless of how much your heart pants after someone, you won't reduce yourself to a hungry wolf chasing his dinner. You're too busy with different projects to focus on one person. Consequently, your partner may feel frustrated when you don't spend as much time as they hoped you would spend with them or give as many gifts as they hoped you would.",
    "You're not after all the riches in the universe. You want enough money to take care of yourself and help others survive. Your frugality comes at the cost of seeking cheap ways to have fun. You don't think it's wise to buy clothes on impulse, book vacation flights, or buy tickets to some concert when you can just visit the local museum or go on a road trip.",
    'You skillfully build a name for yourself across the various layers of society. For the less fortunate, you become their champion. To the middle class, while you come across as self-important and difficult, they still find you indispensable. And finally, among the wealthy, you are regarded as the wise one.',
  ],
  11: [
    "Your personality number is 11. That means you have the softest heart anyone has ever seen. It also means others don't have to do much work to get under your skin because you're very emotional. In social settings, you feed on the signals others send your way. When someone waves at you happily or enthusiastically talks to you, you respond similarly. The opposite is the case when someone is rude to you. ",
    "Your dependence on the kinds of energy that people channel your way manifests in romantic relationships. You hardly fall in love with someone first, mainly because you're afraid of them not reciprocating your feelings. You wait for them to fall for you first and confess their feelings to you before you let your heart open up to the possibility of being with them romantically. ",
    "If there were a prize for the most socially awkward person, you'd bag it every single day. I'm sure you've stared at a group of friends having fun, wishing you could join in, but your body felt as if it didn't want to cooperate with your wishes. So you remain the shy, closed-off person most people perceive you as. ",
    "You can walk into a room full of people and sit by yourself, not saying hi to anyone unless they greet you first. In public, you're so gentle that it hurts, and it seems you can't hurt a fly. This gentleness does you both good and bad. On the one hand, it attracts people seeking solace in this chaotic world. On the other hand, it makes you shrink into yourself whenever you're outdoors. ",
    "You're not a huge fan of social media. Interacting with people in person is more your thing. This love for physical interactions, combined with your extreme shyness, casts a blanket over your talents. You're naturally creative, but your creativity won't see the light of day if you don't come out of your shell. Surprisingly, you unveil your naughty side when around those you like. ",
    "You perceive as one individual within a planet that is home to over seven billion unique persons. This outlook helps you understand life is far richer and broader than just your singular existence. You see others and recognize their worth. While you aren't competitive yourself, you encourage those who are to put in their best.",
    'You find yourself drawn to quality wine, delectable food, and the elegance of well-crafted attire. All these affirm that you deserve the finest things in life.',
  ],
  22: [
    "Your personality number is 22, and it keeps you on your feet when everyone else has crashed. Even though a project appears not to be working out, you don't abandon it, always believing in its potential for success. You're not the kind to write off people quickly, as you think everyone should have a chance to prove their worth. ",
    "Your disposition makes people think you'll accept whatever they throw at you. They may respect you, but they don't fear you. As a result, they'll blatantly do things they know you dislike and disregard your wishes. No matter how determined you are to put your foot down, you can't bring yourself to tell them off. This continues until they break your last straw. ",
    "You're close to a doormat when romantically involved with someone. They can cheat on you, insult you, steal from you. Yet, you won't leave them, hoping they'll change someday. It's only after they've recurrently messed you up that you pick up the pieces left of your shattered life and move forward. ",
    "You enjoy being your boss and running your business. Right from a young age, you start buying and selling things. For you, getting educated isn't about being at the top of the class. You focus on your books to soak up the right amount of knowledge to be a successful businessperson. Your contentment rubs off on your business ethics. You don't cheat or mislead customers but strive to offer the best quality of goods or services they deserve. ",
    "You prefer quiet, non-vigorous activities, like walking in the park or meditating. You're very big on me-time, and spending time alone helps you understand yourself better. You're exceedingly patient and calm. It would take someone with an extraordinary degree of rudeness to unsettle your equilibrium. ",
    'You are the type to welcome each day with a to-do list. Checking off each bullet point, one by one, until a clean slate remains makes you happy. Some argue that you push yourself too much, but they do not understand how much you dislike leaving things half-done.',
    'Pleasing you is pretty straightforward. All it takes is a clean house, delicious meals, or collaborating with you in reaching your goals. You think time is too precious to be spent on anyone with a glass-half-empty mentality. So, you gravitate towards those who will fan your flames, not throw water on your dreams.',
  ],
  33: [
    "33 is your personality number. That's a sign that you're sweet, kind, and fun. You care less about dolling up or hoarding extravagant jewelry that may never see the outside of your wardrobe. You'll trade heels and suits for sneakers and pants any day. As for colors, you prefer a mix of bold and cool. ",
    "You're far from being reckless or wild, but when it comes to choosing between attending a class or missing out on a concert by your favorite artist in town, well, let's say the answer is clear as day. In your circle of friends, you are known for your easy-going nature. You find joy in the simple things and always search for the next good vibe.",
    'You have mastered the art of recognizing the signals that wave the red flag of stress, and at the slightest hint, you will let go. Be it an activity or a person causing the strain; you will not think twice about walking away.',
    "The same way you don't care about competition is the same way you don't care about what anyone thinks about you. If you want to wear your hair in a different style, you do it. If you don't want to hang out with your friends, you let them know and walk away. ",
    "It doesn't take much encouragement for you to kick against convention. While this lends you the confidence many lack, it can also make you reject constructive criticism. However, good criticism is fundamental to progress. If you keep shrugging off what others say, you may be stuck in the same spot for long. ",
    "You don't have the strength for toxic relationships. If your partner doesn't appreciate your efforts or attempts to guilt you into feeling bad for taking time to chill without them, the relationship is over. This may be a harsh reality, but it's one that you understand and are willing to accept for your well-being. ",
    "People see you as an individual marked by wisdom that outmatches your age. It is in your nature to take on a guiding role, regardless of the scenario in which you find yourself. Your judgment commands trust, and people are confident that following your lead won't steer them off course.",
    'You thrive in the arts and sciences, while commercial fields may not be your cup of tea. Your remarkable personality is a source of strength, providing the perfect equilibrium in your life.',
  ],
};
