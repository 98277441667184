import IconPlay from '../../../../../../assets/images/icon_play.svg';
import IconPause from '../../../../../../assets/images/icon_pause.svg';
import IconVolumnOff from '../../../../../../assets/images/icon_volume-off.svg';
import IconVolumnUp from '../../../../../../assets/images/icon_volume-up.svg';
import './AudioCaptionBox.scss';

const AudioCaptionBox = ({
  caption,
  isFinished,
  isMuted,
  isPaused,
  isStarted,
  onControlClicked,
  onVolumeClicked,
  hiddenControls = false,
}) => {
  return (
    <div
      className={`audio-caption ${isStarted && !isFinished ? 'visible' : ''}`}
    >
      <div className="audio-caption__player">
        <div
          className={`audio-caption__content ${
            hiddenControls ? 'no-control' : ''
          }`}
        >
          {caption}
        </div>
      </div>

      {!hiddenControls && (
        <div className="audio-caption__player-controls">
          <div className="control-item control-play" onClick={onControlClicked}>
            <img
              src={isPaused ? IconPause : IconPlay}
              alt={isPaused ? 'pause' : 'play'}
            />
          </div>
          <div
            className="control-item control-volumn"
            onClick={onVolumeClicked}
          >
            <img
              src={isMuted ? IconVolumnOff : IconVolumnUp}
              alt={isMuted ? 'volumn-off' : 'volumn-up'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioCaptionBox;
