import React, { useEffect, useState } from 'react';
import NumbersImage from '../../../../assets/images/numbers.png';
import {
  AllMonthNames,
  BirthItems,
  NumerologyAspectsImages,
} from '../../../common/constants';
import Helper from '../../../common/helper';
import './LifePathNumberCalculation.scss';

const LifePathNumberCalculation = ({
  data,
  canPlayAudio,
  onComplete,
  onLifePathNumberCalculated,
}) => {
  const [curBirthItemIndex, setCurBirthItemIndex] = useState(-1);
  const [numSumResults, setNumSumResults] = useState({
    year: 0,
    month: 0,
    day: 0,
    total: 0,
    result: [],
    lifeNumber: 0,
  });
  const [isContainerDisplay, setIsContainerDisplay] = useState(false);
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);
  const [isNumerologyAspects, setIsNumerologyAspects] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsContainerDisplay(true), 100);
  }, []);

  useEffect(() => {
    if (canPlayAudio) {
      setTimeout(() => setIsAnimationStarted(true), 37500);
      setTimeout(() => setIsNumerologyAspects(false), 55000);
      setTimeout(() => setCurBirthItemIndex(0), 59200);
      setTimeout(() => setCurBirthItemIndex(1), 63100);
      setTimeout(() => setCurBirthItemIndex(2), 65400);
      setTimeout(() => setIsCompleted(true), 78000);
      setTimeout(() => onComplete(), 79000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canPlayAudio]);

  useEffect(() => {
    if (data.year && data.month && data.day) {
      const strYear = `${data.year}`;
      const strDay = `${data.day}`;
      const posOfMonth = AllMonthNames.findIndex((name) => name === data.month);
      const isMasterDay = ['11', '22'].includes(strDay);
      const birthDayNums = {
        year:
          parseInt(strYear[0]) +
          parseInt(strYear[1]) +
          parseInt(strYear[2]) +
          parseInt(strYear[3]),
        month: posOfMonth + 1,
        day:
          strDay.length > 1 && !isMasterDay
            ? parseInt(strDay[0]) + parseInt(strDay[1])
            : parseInt(strDay),
      };
      if (birthDayNums.year > 9) {
        birthDayNums.yearNum =
          parseInt(birthDayNums.year / 10) + (birthDayNums.year % 10);
      } else {
        birthDayNums.yearNum = birthDayNums.year;
      }
      const strTotal = `${
        birthDayNums.yearNum +
        Helper.getSingleNumberFromMonth(birthDayNums.month) +
        birthDayNums.day
      }`;
      const result = [];
      let report = Helper.getSingleSumNumber(strTotal);
      result.push(report);
      if (
        !['11', '22', '33'].includes(`${report.singleNumber}`) &&
        report.singleNumber > 9
      ) {
        report = Helper.getSingleSumNumber(`${report.singleNumber}`);
        result.push(report);
      }

      onLifePathNumberCalculated(report.singleNumber);

      setNumSumResults({
        ...birthDayNums,
        total: parseInt(strTotal),
        result,
        lifeNumber: report.singleNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.day, data.month, data.year]);

  const animateNumerologyItem = (index) => {
    if (index === 0) {
      const strYear = `${data.year}`;
      return (
        <div className="animate-numerology-item year-item">
          <div className="letters">
            {Array(strYear.length)
              .fill(1)
              .map((_v, i) => (
                <React.Fragment key={i}>
                  <div className="num">{strYear[i]}</div>
                  {i !== 3 && <div className="plus-between-nums">+</div>}
                </React.Fragment>
              ))}
          </div>
          <div className="equal-to">=</div>
          <div className="result-sum">{numSumResults.year}</div>
          {numSumResults.year !== numSumResults.yearNum && (
            <div className="result-sum single-num">{numSumResults.yearNum}</div>
          )}
        </div>
      );
    } else if (index === 1) {
      let monthSuffix = 'th';
      let lastNumberOfMonth =
        numSumResults.month > 10
          ? numSumResults.month - 10
          : numSumResults.month;
      if (lastNumberOfMonth === 1) monthSuffix = 'st';
      else if (lastNumberOfMonth === 2) monthSuffix = 'nd';
      else if (lastNumberOfMonth === 3) monthSuffix = 'rd';
      return (
        <div className="animate-numerology-item month-item">
          <div className="letters">
            {numSumResults.month}
            <div className="th">{monthSuffix}</div>
            Month
          </div>
          <div className="equal-to">=</div>
          <div className="result-sum">
            {Helper.getSingleNumberFromMonth(numSumResults.month)}
          </div>
        </div>
      );
    } else if (index === 2) {
      const strDay = `${data.day}`;
      const isMasterDay = ['11', '22'].includes(strDay);
      return (
        <div className="animate-numerology-item day-item">
          <div className="letters">
            {Array(isMasterDay ? 1 : strDay.length)
              .fill(1)
              .map((_v, i) => (
                <React.Fragment key={i}>
                  <div className="num">{isMasterDay ? strDay : strDay[i]}</div>
                  {i !== strDay.length - 1 && !isMasterDay && (
                    <div className="plus-between-nums">+</div>
                  )}
                </React.Fragment>
              ))}
          </div>
          <div className="equal-to">=</div>
          <div className="result-sum">{numSumResults.day}</div>
        </div>
      );
    }

    return <></>;
  };

  if (isNumerologyAspects) {
    return (
      <div
        className={`numerology-aspects ${isContainerDisplay ? 'display' : ''}`}
      >
        <p className="h2">
          {`${data.name}'s Numerology Reading - ${Helper.formatDOB(
            data.year,
            data.month,
            data.day
          )}`}
        </p>
        {!isAnimationStarted && (
          <div className="image-container numbers-image">
            <img src={NumbersImage} alt="numbers" />
          </div>
        )}
        {NumerologyAspectsImages.map((imgSrc, i) => (
          <div
            key={`numerology-aspect-${i + 1}`}
            className={`image-container ${isAnimationStarted ? 'display' : ''}`}
          >
            <img src={imgSrc} alt={`numerology-aspect-${i + 1}`} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div
      className={`life-path-number-calculation box__wrapper ${
        isCompleted ? 'hidden' : ''
      }`}
    >
      <div className="box__inner">
        <div className="animation-block">
          <div className="upper">
            <div className="birth">
              {BirthItems.map((item, i) => (
                <div
                  key={item}
                  className={`birth-item ${
                    i === curBirthItemIndex ? 'active' : ''
                  }`}
                >
                  {data[item]}
                </div>
              ))}
            </div>
            <div className="numerology">
              {Array(3)
                .fill(0)
                .map((_v, i) => (
                  <div key={i} className="numerology-item">
                    {animateNumerologyItem(i)}
                    {i !== 2 && <div className="plus-between-items">+</div>}
                  </div>
                ))}
              <div className="total-top-line"></div>
              <div className="animate-numerology-item total-item">
                <div className="letters">Total</div>
                <div className="equal-to">=</div>
                <div className="result-sum">{numSumResults.total}</div>
              </div>
            </div>
          </div>
          <div className="lower">
            <div className="then">Then,</div>
            <div
              className={`numerology ${
                numSumResults.result.length > 1 ? 'animate' : ''
              }`}
            >
              {numSumResults.result.map((item, i) => (
                <div key={i} className="animate-numerology-item">
                  {item.total > 9 && !item.isMasterNumber ? (
                    <>
                      <div className="num">{`${item.total}`[0]}</div>
                      <div className="plus">+</div>
                      <div className="num">{`${item.total}`[1]}</div>
                      <div className="equal-to">=</div>
                      <div className="result">{item.singleNumber}</div>
                    </>
                  ) : (
                    <div className="result">{item.singleNumber}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className={`result-block ${
            numSumResults.result.length > 1 ? 'delay' : ''
          }`}
        >
          <div className="life-path-number-box">
            <div className="life-path-number-box__inner">
              <p className="name-text">{data.name}</p>
              <p className="result-number">{numSumResults.lifeNumber}</p>
              <p>is your life path number</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifePathNumberCalculation;
