import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  row: {
    flexDirection: 'row',
  },
  content: {
    position: 'absolute',
    top: 40,
    left: 48,
    right: 48,
    bottom: 40,
  },
  heading2: {
    color: '#081950',
    fontFamily: 'Montserrat',
    fontSize: 30,
    fontWeight: 900,
    lineHeight: 1.2,
  },
  heading3: {
    color: '#081950',
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'uppercase',
  },
  heading4: {
    color: '#081950',
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    marginBottom: 15,
  },
  bodyText: {
    color: '#081950',
    fontFamily: 'Poppins',
    fontSize: 9,
    marginBottom: 15,
  },
  numberText: {
    color: '#ec35c4',
    fontFamily: 'Montserrat',
    fontSize: 30,
    fontWeight: 700,
    lineHeight: 1,
    paddingLeft: 4,
  },
  colorBlack: {
    color: 'black',
  },
  colorWhite: {
    color: 'white',
  },
  colorPink: {
    color: '#eb28c0',
  },
  fontWeightMedium: {
    fontWeight: 600,
  },
  fontWeightBold: {
    fontWeight: 700,
  },
  fontWeightExtraBold: {
    fontWeight: 800,
  },
  fontWeightBlack: {
    fontWeight: 900,
  },
  mb20: {
    marginBottom: 20,
  },
  // Cover
  coverTitleSection: {
    position: 'relative',
    marginTop: 165,
  },
  coverTitle: {
    fontFamily: 'Montserrat',
    fontSize: 50,
    lineHeight: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  // Page Footer
  pageFooter: {
    position: 'absolute',
    left: 30,
    bottom: 10,
    right: 30,
    flexDirection: 'row',
    paddingTop: 5,
    borderTop: '2px solid #081950',
  },
  footerLogo: {
    width: 140,
    height: 25,
  },
  pageNumberText: {
    color: '#081950',
    fontFamily: 'Poppins',
    fontSize: 10,
    paddingTop: 4,
    marginLeft: 'auto',
  },
});

export default styles;
