import './BackgroundAnimation.scss';

const BackgroundAnimation = () => {
  return (
    <div className="background-animation">
      <div className="numbers-mask"></div>
      <div className="numbers-mask"></div>
      <div className="numbers-mask"></div>

      <div className="number-animation">8</div>
      <div className="number-animation">7</div>
      <div className="number-animation">3</div>
      <div className="number-animation">4</div>
      <div className="number-animation">9</div>
      <div className="number-animation">2</div>
      <div className="number-animation">1</div>

      <div className="number-animation">10</div>
      <div className="number-animation">3</div>
      <div className="number-animation">4</div>
      <div className="number-animation">2</div>
      <div className="number-animation">9</div>
      <div className="number-animation">7</div>
      <div className="number-animation">5</div>
    </div>
  );
};

export default BackgroundAnimation;
