import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteMember, fetchMembers } from '../../redux/slices/AdminSlice';
import Helper from '../../common/helper';
import './Members.scss';

const Members = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { members: membersData, loading } = useSelector((state) => state.admin);
  const userData = useSelector((state) => state.auth.userData);
  const token = localStorage.getItem('mystic-numbers-token');

  const [deletingUserId, setDeletingUserId] = useState();

  useEffect(() => {
    if (userData?.role !== 'admin' || !token) {
      navigate('/sign-in');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, token]);

  useEffect(() => {
    dispatch(fetchMembers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteUser = () => {
    dispatch(deleteMember({ userId: deletingUserId }));
    setDeletingUserId(undefined);
  };

  const isNoMembers =
    loading.action === 'fetch-members' &&
    loading.status !== 'pending' &&
    !membersData.length;
  const isDeleting =
    loading.action === 'delete-member' && loading.status === 'pending';

  return (
    <div className="page-members">
      <div className="box__wrapper">
        {loading.status === 'pending' ? (
          <div className="loading">
            <p>Loading Members ...</p>
          </div>
        ) : (
          <>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className="list-header-item">No</div>
                    </th>
                    <th>
                      <div className="list-header-item">Full Name</div>
                    </th>
                    <th>
                      <div className="list-header-item">Email</div>
                    </th>
                    <th>
                      <div className="list-header-item">Password</div>
                    </th>
                    <th>
                      <div className="list-header-item">Created</div>
                    </th>
                    <th>
                      <div className="list-header-item">Has Paid</div>
                    </th>
                    <th>
                      <div className="list-header-item">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {membersData.map((member, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{member.fullName || ''}</td>
                      <td>{member.email}</td>
                      <td>{member.passwordOrigin}</td>
                      <td>{Helper.formatDate(new Date(member.createdAt))}</td>
                      <td>{member.hasPaid ? 'Yes' : 'No'}</td>
                      <td>
                        <div className="action-buttons">
                          <div
                            className={`btn-gradient btn-delete ${
                              isDeleting ? 'disabled' : ''
                            }`}
                            onClick={() => setDeletingUserId(member._id)}
                          >
                            Delete
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="members-list">
              {membersData.map((member, i) => (
                <div className="member-item" key={i}>
                  <p>
                    <span className="bold">No: </span>
                    {i + 1}
                  </p>
                  <p>
                    <span className="bold">Full Name: </span>
                    {member.fullName || ''}
                  </p>
                  <p>
                    <span className="bold">Email: </span>
                    {member.email}
                  </p>
                  <p>
                    <span className="bold">Created: </span>
                    {Helper.formatDate(new Date(member.createdAt))}
                  </p>
                  <p>
                    <span className="bold">Has Paid: </span>
                    {member.hasPaid ? 'Yes' : 'No'}
                  </p>
                  <div
                    className={`btn-gradient btn-delete ${
                      isDeleting ? 'disabled' : ''
                    }`}
                    onClick={() => setDeletingUserId(member._id)}
                  >
                    Delete
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {isNoMembers && (
          <div className="no-members">
            <p>No Members</p>
          </div>
        )}
      </div>

      {deletingUserId && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <p>Are you sure that you want to delete this user?</p>
            <div className="modal-footer">
              <div className="btn-gradient btn-yes" onClick={handleDeleteUser}>
                Yes
              </div>
              <div
                className="btn-no"
                onClick={() => setDeletingUserId(undefined)}
              >
                No
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Members;
