/* eslint-disable import/no-anonymous-default-export */

const getSingleNumberFromMonth = (month) => {
  return month > 9 ? month - 9 : month;
};

const getSingleSumNumber = (strTotal) => {
  const isMasterNumber = ['11', '22', '33'].includes(strTotal);
  const singleNumber =
    strTotal.length > 1 && !isMasterNumber
      ? parseInt(strTotal[0]) + parseInt(strTotal[1])
      : parseInt(strTotal);

  return {
    isMasterNumber,
    singleNumber,
    total: parseInt(strTotal),
  };
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const playNewAudio = async (audioUrl, delay = 0, autoPlay = true) => {
  const audio = new Audio(audioUrl);
  // await audio.load();
  if (autoPlay) {
    if (delay > 0) {
      setTimeout(async () => {
        await audio.play();
      }, delay * 1000);
    } else {
      await audio.play();
    }
  }
  return audio;
};

const formatDOB = (year, month, day) => {
  let daySuffix = `th`;
  if (day === 1) {
    daySuffix = 'st';
  } else if (day === 2) {
    daySuffix = 'nd';
  } else if (day === 3) {
    daySuffix = 'rd';
  }
  return `${day}${daySuffix} ${month} ${year}`;
};

const formatDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const calculateNumerologyNumbers = (data) => {
  const results = {
    ...data,
    loveNumber: 0,
    nameNumber: 0,
    personalityNumber: 0,
    planetNumber: 0,
    soulUrgeNumber: 0,
  };
  const consonant2Numbers = {
    b: 2,
    c: 3,
    d: 4,
    f: 6,
    g: 7,
    h: 8,
    j: 1,
    k: 2,
    l: 3,
    m: 4,
    n: 5,
    p: 7,
    q: 8,
    r: 9,
    s: 1,
    t: 2,
    v: 4,
    x: 6,
    z: 8,
  };
  const vowel2Numbers = {
    a: 1,
    e: 5,
    i: 9,
    o: 6,
    u: 3,
    w: 5,
    y: 7,
  };

  let singleNumberResult;
  let sumConsonantNumbers = 0;
  let sumLoveNumbers = 0;
  let sumNameNumbers = 0;
  let sumPlanetNumbers = 0;
  let sumVowelNumbers = 0;

  // Soul Urge Number
  for (let i = 0; i < data.fullName.length; i++) {
    sumVowelNumbers += vowel2Numbers[data.fullName[i].toLowerCase()] || 0;
  }
  singleNumberResult = getSingleSumNumber(`${sumVowelNumbers}`);
  if (
    !singleNumberResult.isMasterNumber &&
    singleNumberResult.singleNumber > 9
  ) {
    singleNumberResult = getSingleSumNumber(
      `${singleNumberResult.singleNumber}`
    );
  }
  results.soulUrgeNumber = singleNumberResult.singleNumber;

  // Personality Number
  for (let i = 0; i < data.fullName.length; i++) {
    sumConsonantNumbers +=
      consonant2Numbers[data.fullName[i].toLowerCase()] || 0;
  }
  singleNumberResult = getSingleSumNumber(`${sumConsonantNumbers}`);
  if (
    !singleNumberResult.isMasterNumber &&
    singleNumberResult.singleNumber > 9
  ) {
    singleNumberResult = getSingleSumNumber(
      `${singleNumberResult.singleNumber}`
    );
  }
  results.personalityNumber = singleNumberResult.singleNumber;

  // Love Number
  const strDay = `${data.day}`;
  const strMonth = `${data.monthNumber}`;
  const strYear = `${data.year}`;
  sumLoveNumbers =
    parseInt(strDay[0]) +
    (strDay.length > 1 ? parseInt(strDay[1]) : 0) +
    parseInt(strMonth[0]) +
    (strMonth.length > 1 ? parseInt(strMonth[1]) : 0) +
    parseInt(strYear[0]) +
    parseInt(strYear[1]) +
    parseInt(strYear[2]) +
    parseInt(strYear[3]);
  singleNumberResult =
    parseInt(`${sumLoveNumbers}`[0]) + parseInt(`${sumLoveNumbers}`[1]);
  if (singleNumberResult > 9) {
    singleNumberResult =
      parseInt(`${singleNumberResult}`[0]) +
      parseInt(`${singleNumberResult}`[1]);
  }
  results.loveNumber = singleNumberResult;

  // Name Number
  for (let i = 0; i < data.name.length; i++) {
    sumNameNumbers +=
      consonant2Numbers[data.name[i].toLowerCase()] ||
      vowel2Numbers[data.name[i].toLowerCase()];
  }
  singleNumberResult = getSingleSumNumber(`${sumNameNumbers}`);
  if (
    !singleNumberResult.isMasterNumber &&
    singleNumberResult.singleNumber > 9
  ) {
    singleNumberResult = getSingleSumNumber(
      `${singleNumberResult.singleNumber}`
    );
  }
  results.nameNumber = singleNumberResult.singleNumber;

  // Planet Number
  sumPlanetNumbers =
    data.destinyNumber +
    parseInt(strDay[0]) +
    (strDay.length > 1 ? parseInt(strDay[1]) : 0);
  if (sumPlanetNumbers > 9) {
    sumPlanetNumbers =
      parseInt(`${sumPlanetNumbers}`[0]) + parseInt(`${sumPlanetNumbers}`[1]);
  }
  results.planetNumber = sumPlanetNumbers;

  return results;
};

export default {
  calculateNumerologyNumbers,
  formatDate,
  formatDOB,
  getSingleNumberFromMonth,
  getSingleSumNumber,
  playNewAudio,
  validateEmail,
};
