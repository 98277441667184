import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './slices/AdminSlice';
import authReducer from './slices/AuthSlice';
import readingReducer from './slices/ReadingSlice';

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    auth: authReducer,
    reading: readingReducer,
  },
});
