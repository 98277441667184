import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userAPI from '../../api/api';

export const authSessionLogin = createAsyncThunk(
  'auth/sessionLogin',
  async (_data, { rejectWithValue }) => {
    const token = localStorage.getItem('mystic-numbers-token') || '';
    if (!token) {
      return rejectWithValue('No token');
    }

    try {
      const response = await userAPI.post('/auth/session-login', { token });
      if (response.data.error) {
        return rejectWithValue(response.data.error);
      }
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const authSignIn = createAsyncThunk(
  'auth/signIn',
  async ({ email, pwd }, { rejectWithValue }) => {
    try {
      const response = await userAPI.post('/auth/login', {
        email,
        password: pwd,
      });
      if (response.data.error) {
        return rejectWithValue(response.data.error);
      }
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const authRegister = createAsyncThunk(
  'auth/register',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await userAPI.post('/users/', { email });
      if (response.data.error) {
        return rejectWithValue(response.data.error);
      }
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  userData: null,
  loading: {
    action: '',
    status: '',
    message: '',
  },
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearLoading: (state) => {
      state.loading = {
        action: '',
        status: '',
        message: '',
      };
    },
    signOut: (state) => {
      localStorage.removeItem('mystic-numbers-token');
      state.userData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authRegister.pending, (state, action) => {
      state.loading = {
        action: 'register',
        status: 'pending',
        message: '',
      };
    });
    builder.addCase(authRegister.fulfilled, (state, action) => {
      state.loading = {
        action: 'register',
        status: 'fulfilled',
        message: '',
      };
      if (action.payload?.token) {
        localStorage.setItem('mystic-numbers-token', action.payload.token);
        state.userData = action.payload.user;
      }
    });
    builder.addCase(authRegister.rejected, (state, action) => {
      state.loading = {
        action: 'register',
        status: 'rejected',
        message: action.payload.message,
      };
    });
    builder.addCase(authSessionLogin.pending, (state, action) => {
      state.loading = {
        action: 'session-login',
        status: 'pending',
        message: '',
      };
    });
    builder.addCase(authSessionLogin.fulfilled, (state, action) => {
      state.loading = {
        action: '',
        status: '',
        message: '',
      };
      if (action.payload.error) {
        localStorage.removeItem('mystic-numbers-token');
        state.userData = null;
      } else {
        localStorage.setItem('mystic-numbers-token', action.payload.token);
        state.userData = action.payload.user;
      }
    });
    builder.addCase(authSessionLogin.rejected, (state, action) => {
      state.loading = {
        action: '',
        status: '',
        message: '',
      };
      localStorage.removeItem('mystic-numbers-token');
      state.userData = null;
    });
    builder.addCase(authSignIn.pending, (state, action) => {
      state.loading = {
        action: 'signin',
        status: 'pending',
        message: '',
      };
      state.userData = action.payload;
    });
    builder.addCase(authSignIn.fulfilled, (state, action) => {
      state.loading = {
        action: 'signin',
        status: 'fulfilled',
        message: '',
      };
      localStorage.setItem('mystic-numbers-token', action.payload.token);
      state.userData = action.payload.user;
    });
    builder.addCase(authSignIn.rejected, (state, action) => {
      state.loading = {
        action: 'signin',
        status: 'rejected',
        message: action.payload.message,
      };
    });
  },
});

// Action creators are generated for each case reducer function
export const { clearLoading, signOut } = AuthSlice.actions;

export default AuthSlice.reducer;
