export const LifePathNumberScript = {
  1: [
    "Your life path number is 1, and this reveals some truly incredible aspects of your personality. Your positive energy is a contagious force that spreads like wildfire. It makes you a magnet for happiness and love. When you allow your creativity to shine, it draws you to explore beyond the boundaries of conventional thinking and create the world you desire from what already exists. This trait has been putting you one step ahead of your peers, and if you keep it up, you'll find opportunities to guide others and pioneer new ventures. ",
    'As you take the lead at work and school, you tap into the power of your imagination and your desire to positively impact others. This drive lures people to join you on this exciting journey of innovation and growth.',
    "You're so confident. It's truly adorable how you hold onto your beliefs with the tenacity of a stubborn mule. But that's perfectly fine. I know that deep down, you're simply terrified that someone else's ideas might outshine yours. However, do remember that your unyielding stubbornness doesn't solve any of your problems.",
    "Now, let's talk about your impeccable leadership skills. Your slightly overbearing nature is just the cherry on top. You often find yourself struggling to maintain that delicate balance between being a strong leader and a complete control freak. ",
    "As a fearless leader, you refuse to be held back by conventional wisdom or the fear of failure. Your audacity not only drives your own success but also ignites the passion and determination of those around you. But hey, just a heads-up: your self-confidence may morph into pride. It's easy for you to forget that everyone around you has got something to offer, and they're just as important as you are. ",
    'I can tell that you love throwing caution to the wind and embracing the adrenaline rush that comes with taking risks. Every daring decision fuels your fire, propelling you forward in the face of uncertainty. Your unquenchable thirst for the unknown pushes you to break barriers and explore new territories.',
    "You walk through life with a sense of purpose, unshackled by the expectations of others. In the face of adversity, you stand tall, unwavering and resolute. You love being independent. Your heart beats with the rhythm of freedom. When you're free, you find solace, and you're filled with the courage to dream, to love, to truly live.",
  ],
  2: [
    "It's no coincidence that your life path number is 2. This number signifies a deeper purpose and a reason for your existence. You're naturally good at creating peace and harmony with others. You go the extra mile to fix conflicts between people, even if it means giving up some of your peace. This ability to set aside your pride and compromise to maintain harmony is awesome. ",
    "Creating strong bonds makes you happy. When you're in a new workplace, you're a warm, shining sun, and your friendly smile draws your colleagues in and makes them feel welcome. It doesn't take much time to find that one person becomes your wingman and partner in crime. ",
    'Empathy flows in you like a river. For many, reading the energy in a room may be a challenge. But for you, it is as natural as breathing. You are a mirror of the human soul, reflecting the joy and laughter of those who are happy and sharing in the tears of those who grieve. ',
    'Yet, your empathetic nature casts a shadow, for it often prevents you from expressing your own truth, your own emotions. This concealment can become a barrier to your self-fulfilment. You have to overcome that hurdle before you can fully embrace the richness of your journey. ',
    "Because you don't judge others, they consider you a sanctuary where they can unburden their deepest fears, worries, and triumphs. As a reserved individual, you do not impose your views on the struggles of others unless your wisdom is sought. This discernment lends profound value to your insights. ",
    "Stay true to this path, and you shall find yourself at the helm of your destiny. You'll be rewarded with a steadfast league of supporters who treasure the understanding and compassion you generously offer.",
    "Friendship holds a sacred place in your heart. For those you cherish, there is no limit to the depths of your devotion. I see you're not afraid of the vulnerability of baring your soul to those who have earned your trust. ",
    "You believe that everyone is as good and kind as you're. So, instead of banishing people who betray your trust from your life, you give them a second chance. This very quality may leave you susceptible to toxic relationships.",
    'Embrace your goodness and continue to offer it to others, but remember to stand firm in your convictions. Refuse to let anyone trample upon your dignity. You will live a life filled with love and shared with those who truly value your extraordinary spirit.',
  ],
  3: [
    "You're a 3, and that's no ordinary number. It's the most extraordinary of them all. You've got this burning desire inside you, an unquenchable fire to create, invent, and change the story wherever you go. ",
    "There's an insatiable curiosity that buzzes through your veins. You're always digging deeper into the heart of social events and the mysteries of nature. And then you take that knowledge and use it to solve the unsolvable. ",
    "People can't get enough of your lightning-fast thinking. When they're in a bind, they know they can count on you to light the way. Keep on questioning, keep on exploring. The world needs more minds like yours.",
    "A social butterfly, that's what you are. Your free spirit draws people to you like moths to a flame. At times, the bustling nature of your social life may infringe upon your creative potential.",
    'Your professional aspirations gravitate towards the artistic and inventive realms. Whether as a writer, an artist, a dancer, or even an engineer, your heart desires to find a vocation that allows you to express your creative spirit. ',
    "You don't chase after dollars and shiny things. No, your sights are set on something deeper. You crave pleasure, contentment, the kind of riches that can't be counted or held in your hand. You possess this uncanny ability to see the bigger picture. ",
    "And you're not one to keep your wisdom to yourself. You offer solutions to anyone in need to see them smile. But you can't please them all. When criticized, you let those criticisms drag you down. Here's my advice: let constructive feedback fuel your growth.",
    "You've got a knack for telling it like it is. Sure, not everyone's a fan of that side of you, but the ones who don't like it? They're few, and they're not exactly lining up to tell you so. Your straight-shooting ways make you stand out. People see that in you and trust you to guide them through the storm. ",
    'Until now, I have witnessed only the most admirable qualities within you. However, I perceive an underlying instability. Your life is bogged down with countless competing interests. In your quest to please everyone, you attempt to engage in all these activities simultaneously. This often leads to either overwhelming failure or complete exhaustion, thereby defeating your original purpose.',
    'You take life one step at a time without planning for unforeseen circumstances. When the unexpected inevitably occurs, you find yourself ill-prepared. Yet, it is in these moments that your swift thinking saves the day.',
  ],
  4: [
    "Your life path number is 4, but honestly, I'm not shocked at all. From the moment we met, I could sense your grounded and practical nature. You're all about what should be done rather than what could be done. Making rash, impulsive decisions isn't your thing. Instead, you thoughtfully consider your options and always have a backup plan if your initial strategy doesn't work out. Your aura radiates reliability and dependability, as your love for stability keeps you from diving recklessly into the unknown.",
    "Some of your friends think you're too cautious. They encourage you to be less rigid and savor life's experiences. But that's because they envy your well-ordered existence. Your actions, guided by intention, fortify the foundation for your destiny.",
    'Thanks to your little cocoon of stability, your pursuit of various forms of security comes effortlessly. However, when unexpected changes arise due to forces beyond your control, your adaptability may wane, potentially leading to setbacks. ',
    'In slow-paced environments such as schools or libraries, your talents as an employee would flourish. As a supervisor, your cautious demeanor might inadvertently hinder the groundbreaking visions of your subordinates. With enough persuasion, you permit these ideas to mature and materialize.',
    'As a steadfast believer in the sanctity of established routines, you adhere to the structure with unwavering devotion. Nothing annoys you more than an unexpected alteration in your plans. This reverence for tradition instils a sense of discipline, compelling you to execute tasks with precision and timeliness. Consequently, you toil relentlessly, often sacrificing personal well-being and leisure to satisfy clients, partners, or superiors.',
    'This same devotion entangles you in believing that your self-worth is linked to your professional accomplishments. When your chosen vocation fails to ignite your passion, you become dissatisfied and sad. Paradoxically, the fear of change that fuels your adherence to routine prevents you from seeking new beginnings.',
    "Listen, life's fleeting moments don't wait for anyone, and squandering them on meeting the expectations of others is a fool's errand. This week, shrug off the weight of convention. Give in to that lingering desire, that one thing that's been gnawing at you for far too long.",
    "And if you find yourself straying from the beaten path, indulging in an unbudgeted splurge or a moment of unbridled spontaneity, don't let guilt consume you. Remember, life is meant to be lived. And sometimes, stepping out of line is what makes the journey worthwhile.",
  ],
  5: [
    "Number 5 is the force guiding your life path. It's the reason behind your past choices that now haunt your present. You've always wanted to be free and independent. But the suffocating grip of being accountable to someone else traps your soul in a dark abyss of misery. You'd rather write your own rules and live by them.",
    "As life starts to feel repetitive, you can't help but crave a change of tune. You long to break free from the same old dance and find a new beat. And if there's one person who's a natural at staying open to new melodies, it's you!",
    "This open-mindedness makes you a master of adaptation. You're a perfect fit for careers that require thinking on your feet and taking center stage. Whether it's exploring new destinations as a travel blogger, or guiding others through exciting journeys, any occupation that lets you discover new things and places appeals to your adventurous spirit. ",
    "As you peer through the windowpane of your thoughts, you'll find it cluttered with countless questions about the world and the events that transpire within it. The urge to solve these mysteries often leads you to contemplate abandoning your responsibilities, all in the name of pursuing adventure. This intoxicating allure tempts you to abandon your comfort zone and venture into the wild.",
    "When you speak, people don't just listen. They're persuaded. Your words can sway hearts and minds—for better or for worse. It's a skill that can make even your most bitter enemies your allies.",
    "You're a strategist. Each step you take is calculated, always with your goal in sight. And as you take steps to achieve those goals, others can't help but fall under the shadow of your influence.",
    "I know many people have the life path number 5, but trust me, nobody can use it as effectively as you can. However, you've got obstacles stopping you from truly shining with this number.",
    "When you've been doing the same thing for too long, you get bored. Then you let your restlessness push you into wasting time on pointless activities just for a quick thrill. You lose sight of what really matters.",
    "Now listen, your flexibility is special, but it can also be your downfall if it turns into impulsiveness. So, the next time you feel the urge to satisfy your desires without thinking it through, stop and pull yourself together. Remember, you've got the potential to be great, but only if you stay focused.",
  ],
  6: [
    "Your life path number is 6. This number tells me that you're a carer. Possessing a tender heart, you're effortlessly moved by the problems of others. Such a quality allows you to fit into domestic settings. You enjoy tending to the young ones and ensuring older adults and all others in your sphere are fed and warm. You always resolve family disputes in the best interest of those involved. ",
    "In the workplace, your colleagues hold you in high regard as one who embodies a human touch. Your empathetic nature shines through when engaging with clients and fellow executives, demonstrating a genuine understanding and respect for their perspectives. You'd make a remarkable customer care representative, nurse, teacher, or family lawyer. As an entrepreneur, your exceptional rapport with people will cultivate a loyal customer base.",
    'When others stumble, they confide in you. Like a watchful parent, you regard everyone around you as if they were your children. Naturally, after hearing their sob stories, you may be inclined to judge them a little. But with gentle guidance, you steer them back onto the right path.',
    "I surmise that your gift stems from emotional maturity. It enables you to extend a helping hand and prevent others from tumbling into life's ditches. However, at times, your self-righteous nature may lead you to overstep boundaries. When your unsolicited counsel is met with rejection, you may recoil, sometimes hesitating to offer assistance in the future. But don't let this discourage you. ",
    "Offering help when not asked for can tear your kind heart apart, exposing you to the icy side of your emotions. You might lose that delicate balance you fight so hard to maintain. Your relationships with others could turn bitter, leading to a misuse of your caregiving abilities. Instead of feeling hurt when your advice is rejected, see it as a signal that you haven't fully grasped the person's situation. Use the chance to learn more about them.",
    "As you grow closer to people, you'll experience inner turmoil over which friends and family members deserve your loyalty the most. But remember, loyalty is a group effort for you. You're there for everyone.",
    "You make a valuable team player, but you don't know how to draw the line between personal and professional relationships. But by bit, this chips away at your productivity. To become emotionally stronger and wiser, and to secure your heart, learn how to draw that line. Find the balance, and watch yourself flourish.",
  ],
  7: [
    "When we first met, I had this feeling that your life path number would be 7. The letters in your name just confirmed my hunch! It's no surprise you're always trying to learn more about yourself. That makes you strong, but it can also be your weakness.",
    "Your eyes don't just see the world like everyone else. They delve into the hidden depths, uncovering secrets that elude others. With this knowledge, you're always a step ahead, preparing for the unexpected. You scrutinize every situation and plot the best path forward.",
    "But you also revel in the thrill of a perfectly executed plan, believing you can control the outcome if you prepare enough. Sadly, life doesn't always play by the rules. So when your masterful plans don't materialize, it becomes clear that you can't think spontaneously. In those moments, the terror of uncertainty paralyzes you. ",
    "However, your analytical mindset grants you the power to see both sides of every coin. Yet, you magnify the bad side and criticize it relentlessly until it becomes a monstrous, twisted version of its former self. This is why you're not the best person to go to for support. When others share their new ideas with you, they expose them to your excessively critical nature.",
    'As a result, these plans are suffocated under your unyielding scrutiny. This attitude may lead to isolation and despair as you snuff out the creative sparks of those around you.',
    "As a perfectionist, you elevate yourself above the flawed nature of humans. I know how punctual you are, always arriving on time for appointments, and how deeply you loathe those who leave you waiting or stand you up. You make sure they're aware of your displeasure.",
    "Your perfection turns you irritable and sour, making you quite the challenge to interact with. Only a few people can tolerate your air of superiority. And this is why you're left with a small circle of friends. And with each passing day, you push yourself further into your protective shell.",
    "Spending time alone allows you to gain knowledge by reading books and exploring various resources. You become more in tune with your inner self, allowing you to trust your instincts because you know yourself so well. Maintain this attitude, but don't get too caught up in trying to be perfect all the time. Nobody is perfect, and that's what makes life so interesting and full of surprises.",
  ],
  8: [
    "Your life path number is 8. There's nothing you want more than to capture, retain, and exercise power. You'll do whatever it takes to be at the top.",
    "I perceive an exceptional ambition in your spirit. It's not enough for you to have a good house, a fine car, and a good job. No day passes without you feeling an intense craving for something more. You seek something bigger and better than what's regularly obtainable from life.",
    "You don't feel successful until you've broken a record or exceeded the expectations of your friends and enemies. You believe real success comes from your achievements. As a result, you make giant strides early in life. You rise fast through the ranks, surpassing your peers so early that they later submit to you.",
    'But your heart is so vulnerable to greed, which you may or may not realize is slowly consuming you. At first, you think you just want a little more than what you have. But then, you cannot resist the urge for the whole cake. I know you well enough to see why this happens. Your ambition convinces you that you deserve all the good things in life. Your love for power makes you obsessed with controlling everything around you. And gradually, the greed for material possessions eats away at your core. ',
    "All the time, you nurse a big ego. But this ego is just a facade for the fear that crawls along the corridors of your heart. You're scared of failure. Instead of confronting it, you run away from it. You use your pride as a shield. But pride can never be a shield. It's a sword that first destroys its wielder. ",
    'Your childhood has influenced your emotional state. People used to write you off or set unrealistic expectations for you. You were afraid of proving them right or letting them down. That fear still haunts you today. ',
    "However, I want you to know you can use fear to your advantage. When you're scared, admit it and do whatever you'd do if you weren't scared. As you let go of fear, you'll have more room to love yourself and others. That's when you'll get the control you want. ",
    "When you're in control of your life, you can focus on doing what makes you happy. For you, this may mean starting your own business or leading your team to victory. And with that, you'll find peace.",
  ],
  9: [
    'Your journey through life is guided by the number 9, a symbol of embracing new beginnings. After bidding farewell to past chapters, you step into the unknown, ready to explore the wonders that lie ahead. This fearless attitude stems from the maturity of your life path number within the numerological chart.',
    "Having experienced many highs and lows, you've developed a resilience that keeps you undaunted by the uncertainties of life. While your courage might seem intimidating, it's balanced by your warmth and generosity. ",
    `You're not one to dwell on the "what ifs" or "could have beens." You don't waste time on exes or hold on to bitter grudges. With a clear mind, you channel your creative energies into exciting new ventures. Be it painting, writing, devising innovative work strategies, or renovating your home; you're eager to make the most of every opportunity. In your world, there's no room for dwelling on the past. You're all about moving forward and creating something beautiful.`,
    "You may not know it yet, but you have the seed of forward-thinking planted deep within you. Unlike others, you don't cry and complain when something unfortunate happens. You glean whatever lessons can be learned from the incident and salvage the situation. You've made your fair share of mistakes and emerged wiser for it. Wisdom, hard-earned from life's trials, has become your most valuable weapon.",
    "There's a selflessness that drives you to act when others look the other way. The suffering of others cuts you deep, and you can't bear to do nothing about it. Instead, you take action. You volunteer at the community shelter, gather your used clothes, offering them to those who have so little. Your gestures seem small, but they make a world of difference. And your words are a lifeline for those who've nearly given up. ",
    'But sometimes, you can be too selfless. Putting others first can eventually take a toll on you, and not everyone you help will appreciate your kindness. When this happens, you start feeling depressed, and that depression could lead to something more serious, like melancholy. This takes away the satisfaction you get from helping others.',
    "Here's what I suggest: put yourself first sometimes. I know it might be hard, especially considering your life path number, but try taking it one step at a time. Go ahead and treat yourself to a nice meal or do something special for yourself occasionally. Ask for help and accept support from others when you need it.",
  ],
  11: [
    'Your life path number is 11. The two digits it contains make it a master number and master numbers are the hardest to find in numerology.',
    "You're twice more sensitive than everyone else. Your pulse is connected to the auras of the people in whichever setting you're in. You're the kind of person that can correctly guess someone's mood by just looking at their face. You feel the happiness, moodiness, anger, and bewilderment of others in your spirit. Even when encountering a stranger, you make them feel as if they've known you for a long time, getting them to open up to you in unique ways. ",
    "Being immensely sensitive spurs you to want to give everyone what you think they need. So, you test your hands with creative exercises. You're fluid, and your works are never boring, letting the audience feel the emotions you want them to experience. ",
    "Some people are jealous of how much power lies you carry, but they can't cut ties with you because you can easily sway the majority of the crowd to decide in your favor. You'll be tempted to misuse this power to lead others in the path of ruin, but your empathy will save you from going astray. Consider pursuing a career in psychological fields, such as therapy or spirituality. ",
    "When you're not caught up in empathizing, you're busy settling any conflicts that may have come up between people. It's hard for folks to stay mad at each other around you since you're constantly looking for reasons to keep the peace, mainly for your own sake. After all, if you're going to tap into everyone's emotions, it's better for you if they're feeling positive vibes.",
    "Your extreme sensitivity comes at the high price of indecisiveness. Regular folks typically have just one emotional train running through their hearts, making it easy for them to choose their destination. But you? You're pulled in different directions by a whirlwind of emotions for various reasons. ",
    'Even when you finally make a decision, a sudden surge of feelings can hit you at the last minute, causing you to change your mind. This is why you hesitate when quick action is needed, often ending up doing nothing. This approach will hold you back in the real world. To overcome it, rely on your head rather than your heart when making decisions. Pick the most logical option among the possible choices and go for it.',
  ],
  22: [
    "You've got life path number 22, the most complex and delicate of all life numbers. It's also one of the three master numbers, which give their carriers double the potential vested on other life path numbers. But having great power means having even greater responsibility. So, if you manage to keep the good and bad sides of your life path number in check, you'll fulfill your destiny on a grand scale. ",
    "You have the incredible ability to build and create something from nothing. When others see a dead end, you spot a chance to turn things around and create something solid. Your vision is so strong and full of hope that it makes people question if you're even human. Anyone can come up with a great idea, but it takes someone with insane determination to make it happen. Good thing your life path number gives you more than enough tenacity to get the job done.",
    "Your workaholic attitude gets you into leadership positions pretty easily. If you're not elected, you charm your way to the top. But, you tend to push yourself and your team too hard, neglecting everyone's mental health and telling them to focus on the big picture. You forget that not everyone has your special vision. Some people even see you as a bit of a taskmaster. Sure, you get results, but it often comes at the cost of short-term comfort.",
    "You're not the type to enjoy needy relationships. You really appreciate it when your partner is independent and doesn't need you around all the time. You've always been that ambitious person who's hesitant to try new things or get lost in daydreams. Time is super important to you, and you always use it wisely. That's why you're so disciplined!",
    "You have a hard time letting go of control. You don't your team's abilities and make it clear to them, which prevents them from suggesting better ways to achieve the organization's goals. As a result, while you continue to advance in your career, your colleagues stay in the same position for years.",
    "You tend to butt heads with your bosses more than anyone else because you're convinced your ideas are the best. If you're working in a company right now, try to tone it down when presenting your thoughts to the higher-ups, so they'll pay attention. And if you're running your own business, adding a bit of empathy to your leadership style wouldn't hurt.",
  ],
  33: [
    `Your life path number is 33. 33 is also the last of the three master numbers, and has the most spiritual significance. As a spiritual person, you know that there is more to the world than we can see and that this "more" can be harnessed for good. You connect with your emotions on a deeper level than those around you. `,
    "Sometimes, you may struggle with feeling like an outsider, like no one understands what it's like to live in your head. But your spiritual strength keeps you grounded and connected with others despite feeling different from them. The more time you spend with people who understand this part of yourself, the better off you'll be. ",
    "You're amazing at being there for people and offering emotional support and practical solutions most people need. But you stress yourself out trying to find solutions for others. You might think that doing this makes them appreciate what you do for them more. But it reduces the value of what you're offering them by more than half because they see you as someone easy to manipulate and take advantage of.",
    "You are humble and deserve to be recognized for it. Being humble helps you to acknowledge your faults and not repeat them deliberately. Don't be shy about your mission to the world. If someone makes a mistake, offer them a helping hand. That said, I need you to remember that there are some vital lessons that people have to learn on their own. And to learn those lessons, they must be allowed to make certain mistakes. ",
    "It's time to take a hard look at your life path. You've been letting the weeds grow, and they're crowding out your patience, protection, and love. Yes, it's wonderful to be as generous as you're and even philanthropic, but the first rule of being on your life path is owning your emotions. Others call it the art of drawing balance. You can call it whatever you want, but ensure you identify when and when not to butt your head into other people's matters. It would be best if you also reined in on your emotions so they don't get the better of you and hinder your progress. ",
    "Stop putting others before yourself. Focus on you. Underneath those intense emotions, there's untapped creativity waiting to be unleashed. Separate what you feel like doing from what you need to do. Let your creativity flow, and you'll excel in any field you choose!",
  ],
};
