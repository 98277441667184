export const DestinyNumbersScript = {
  start: [
    {
      text: 'Hello {name} and welcome back, ',
      time: 1,
    },
    {
      text: 'I now have all the information I need to continue your reading ',
      time: 3,
    },
    {
      text: 'and calculate your destiny number.',
      time: 6,
    },
    {
      text: 'Unlike your life path number, which focuses on your birth date, ',
      time: 8,
    },
    {
      text: 'your destiny number (often called expression number) will always be calculated ',
      time: 12,
    },
    {
      text: 'based on your full name that graced your birth certificate.',
      time: 17,
    },
    {
      text: 'It’s a slightly complicated process that converts the letters in your name ',
      time: 21,
    },
    {
      text: 'into digits using the Pythagorean alphabet so I will calculate yours here for you.',
      time: 25,
    },
    {
      text: 'Now I must warn you, ',
      time: 30,
    },
    {
      text: 'your destiny number can often reveal certain information that may shock you',
      time: 32,
    },
    {
      text: 'but it’s imperative that you listen with an open mind',
      time: 36,
    },
    {
      text: 'so that you can benefit the most from your reading.',
      time: 38,
    },
    {
      text: '',
      time: 42,
    },
  ],
  1: [
    {
      text: 'The power of destiny number 1 is within you',
      time: 1,
    },
    {
      text: `which means you're stubborn and driven.`,
      time: 3,
    },
    {
      text: `You'll always end up leading any group you're in, whether you try to or not.`,
      time: 5,
    },
    {
      text: 'Some people want attention but stay in the shadows.',
      time: 9,
    },
    {
      text: `But you shine bright without even trying, and people can't help but be drawn to you.`,
      time: 12,
    },
    {
      text: `I see that you're brimming with inventive thoughts.`,
      time: 16,
    },
    {
      text: `And unlike others, you don't hide them away due to the fear of mockery.`,
      time: 19,
    },
    {
      text: `It's not that you're entirely fearless, but rather, `,
      time: 24,
    },
    {
      text: `you're more concerned about the consequences of keeping your insights to yourself.`,
      time: 28,
    },
    {
      text: 'With a firm belief in your potential to make a difference,',
      time: 32,
    },
    {
      text: 'you confidently share your ideas, knowing they can turn things around.',
      time: 35,
    },
    {
      text: 'Your self-confidence can sometimes manifest as arrogance,',
      time: 40,
    },
    {
      text: 'causing you to dismiss the ideas of others, believing yours to be superior.',
      time: 43,
    },
    {
      text: 'You worry that if you entertain differing opinions, they might fail and impede your progress. ',
      time: 47,
    },
    {
      text: 'Consequently, you resist opposing views.',
      time: 53,
    },
    {
      text: 'However, this behavior contradicts the very essence of effective leadership.',
      time: 56,
    },
    {
      text: 'I understand you have a passion for leading, but if you truly want others to follow you,',
      time: 61,
    },
    {
      text: `it's crucial to appreciate their contributions and give credit where it's due. `,
      time: 66,
    },
    {
      text: 'As an overachiever, no goal is too high for you to accomplish.',
      time: 70,
    },
    {
      text: `You're always setting new records, ensuring your name remains at the top of any list.`,
      time: 74,
    },
    {
      text: 'In school, you consistently rank among the best,',
      time: 78,
    },
    {
      text: 'maintaining flawless scores as part of your impressive reputation.',
      time: 81,
    },
    {
      text: 'People wonder how you manage to string along your accomplishments.',
      time: 84,
    },
    {
      text: 'However, you laugh at them because they have no idea how much work you put into yourself. ',
      time: 88,
    },
    {
      text: `Although you don't shy away from leadership,`,
      time: 93,
    },
    {
      text: 'you only lead because you want to be in the limelight.',
      time: 95,
    },
    {
      text: 'You want the fame, the money, and the respect that comes with leading.',
      time: 98,
    },
    {
      text: `So deep down, you're one big mush of individualism.`,
      time: 102,
    },
    {
      text: `Focused, you don't see anyone but yourself, and it's hard to fall prey to peer pressure. `,
      time: 105,
    },
    {
      text: `If you want to reach your potential and deal with your destiny number's downsides,`,
      time: 110,
    },
    {
      text: 'stop being so isolated.',
      time: 114,
    },
    {
      text: 'You like being independent and not caring what others think,',
      time: 117,
    },
    {
      text: `but that attitude won't get you far.`,
      time: 119,
    },
    {
      text: 'Connect with others on a deeper level.',
      time: 121,
    },
    {
      text: 'Be more emotionally sensitive. ',
      time: 123,
    },
    {
      text: `When you do that, your relationships won't suffer from your determination to succeed, `,
      time: 126,
    },
    {
      text: `and you'll find it easier to pay attention to your loved ones.`,
      time: 130,
    },
    {
      text: `In return, they'll cherish and love you even more for your efforts.`,
      time: 133,
    },
    {
      text: '',
      time: 138,
    },
  ],
  2: [
    {
      text: 'Your Destiny number is 2.',
      time: 1,
    },
    {
      text: 'I could feel a gentle aura surrounding you even before calculating your Destiny number.',
      time: 3,
    },
    {
      text: 'This should give you an idea of how palpable your sensitivity is.',
      time: 7,
    },
    {
      text: 'As an emotional antenna, you effortlessly pick up',
      time: 11,
    },
    {
      text: 'on the signals others send and transmit your own.',
      time: 14,
    },
    {
      text: 'With abundant peace within your soul, you naturally bring tranquillity wherever you go.',
      time: 17,
    },
    {
      text: `It's high time you stopped underestimating your worth.`,
      time: 22,
    },
    {
      text: `You're the one who keeps the group united and offers a safe space during tough times.`,
      time: 26,
    },
    {
      text: 'Instead of imposing your opinions on others,',
      time: 31,
    },
    {
      text: 'you inspire them to use their own thoughts and ideas to move the group forward.',
      time: 33,
    },
    {
      text: 'Guided by your kind nature, you lend a helping hand to others,',
      time: 37,
    },
    {
      text: `sharing what you can, even if it's not much.`,
      time: 41,
    },
    {
      text: 'Despite your achievements, you continue to be humble and kind, ',
      time: 44,
    },
    {
      text: 'reflecting your belief that people are more than means to accomplish goals.',
      time: 48,
    },
    {
      text: 'Your sincere respect for others,',
      time: 52,
    },
    {
      text: 'coupled with your constant efforts to improve their well-being,',
      time: 54,
    },
    {
      text: 'highlights your grounded and authentic approach to life.',
      time: 57,
    },
    {
      text: 'Relationships are very dear to you.',
      time: 61,
    },
    {
      text: `You'd trade all the riches in the world to save the people you love.`,
      time: 63,
    },
    {
      text: 'Seeing others suffer is unbearable,',
      time: 66,
    },
    {
      text: 'and you often take the blame for their mistakes or cover for less diligent co-workers.',
      time: 68,
    },
    {
      text: 'However, putting others first can be a double-edged sword for your destiny.',
      time: 74,
    },
    {
      text: 'If you let your altruistic side take over completely, people may take advantage of you, ',
      time: 79,
    },
    {
      text: 'using you for their own gain and leaving you feeling drained and unappreciated.',
      time: 83,
    },
    {
      text: 'On the flip side, your caring nature might lead you',
      time: 87,
    },
    {
      text: 'to sacrifice your own dreams to help your loved ones succeed.',
      time: 90,
    },
    {
      text: 'While they flourish in their careers, you could find yourself stuck,',
      time: 94,
    },
    {
      text: 'not progressing in your own life.',
      time: 97,
    },
    {
      text: 'As your youth fades, you might regret not putting yourself first.',
      time: 99,
    },
    {
      text: `But you don't have to be stuck in either of those extremes.`,
      time: 103,
    },
    {
      text: 'Treading the middle ground may be very hard for you',
      time: 106,
    },
    {
      text: 'because only very few people with your destiny number can do that. ',
      time: 109,
    },
    {
      text: 'However, you can partner with others.',
      time: 113,
    },
    {
      text: 'As a partner rather than a subordinate, ',
      time: 116,
    },
    {
      text: `you'll recognize how much value you offer and appreciate yourself for it.`,
      time: 118,
    },
    {
      text: 'Your shell will become tougher.',
      time: 122,
    },
    {
      text: 'Instead of seeking external validation and becoming upset',
      time: 124,
    },
    {
      text: `when you're not appreciated for your hard work, `,
      time: 127,
    },
    {
      text: `you'll control your wave of emotions so they don't overpower you.`,
      time: 130,
    },
    {
      text: `Then you'll have all the happiness, peace, and wealth you desire.`,
      time: 133,
    },
    {
      text: '',
      time: 138,
    },
  ],
  3: [
    {
      text: 'Your Destiny number is 3.',
      time: 1,
    },
    {
      text: 'Three is the queen bee of all destiny numbers.',
      time: 3,
    },
    {
      text: 'Your personality is bubbly, and you truly enjoy being in large crowds.',
      time: 5,
    },
    {
      text: 'When like-minded individuals surround you, it feels as if the world is yours for the taking,',
      time: 10,
    },
    {
      text: 'and nothing can stop you from achieving your goals.',
      time: 15,
    },
    {
      text: 'You are like a bird that refuses to be caged, needing to spread its wings and fly.',
      time: 18,
    },
    {
      text: `You can't go a day without embarking on something new.`,
      time: 22,
    },
    {
      text: 'Since you love partying and dancing, meeting new people comes naturally to you.',
      time: 25,
    },
    {
      text: 'Your easy-going and carefree nature makes it effortless',
      time: 30,
    },
    {
      text: 'to win others over and have them on your good side.',
      time: 33,
    },
    {
      text: 'In a relationship, your openness inspires your significant other to become a better partner.',
      time: 37,
    },
    {
      text: 'Although you possess fierce loyalty, ',
      time: 42,
    },
    {
      text: 'you sometimes find it difficult to differentiate between your wants and needs, ',
      time: 45,
    },
    {
      text: 'often prioritizing your wants over your actual needs.',
      time: 49,
    },
    {
      text: 'This can make your partner unhappy and may even result in frequent arguments.',
      time: 53,
    },
    {
      text: 'However, your strong communication skills save the relationship ',
      time: 57,
    },
    {
      text: `each time it's on the edge of falling apart.`,
      time: 60,
    },
    {
      text: 'When someone offends you, ',
      time: 62,
    },
    {
      text: 'you face the issue head-on rather than keeping it bottled up inside.',
      time: 64,
    },
    {
      text: 'This proactive attitude also comes into play ',
      time: 67,
    },
    {
      text: 'when dealing with seemingly overwhelming challenges.',
      time: 70,
    },
    {
      text: 'Instead of waiting for the problem to escalate, you express your struggles, ',
      time: 73,
    },
    {
      text: `and since you've been so kind to everyone, they are eager to come to your aid.`,
      time: 77,
    },
    {
      text: `Consequently, you've grown accustomed to having others do things for you without much effort.`,
      time: 82,
    },
    {
      text: 'So, when no one is available to shoulder your burdens, ',
      time: 87,
    },
    {
      text: 'you become overwhelmed and discouraged.',
      time: 90,
    },
    {
      text: 'For you, life is more of a sprint than a marathon.',
      time: 92,
    },
    {
      text: 'Your hobbies and interests reflect this mindset.',
      time: 95,
    },
    {
      text: 'Instead of focusing on planning for the future or making long-term investments, ',
      time: 98,
    },
    {
      text: 'you prefer to spend your time and money on short-term pleasures.',
      time: 102,
    },
    {
      text: `I can see that you'll likely become a successful professional in a creative field.`,
      time: 106,
    },
    {
      text: 'However, you may often feel like you can do everything all at once, ',
      time: 110,
    },
    {
      text: `but life doesn't always work that way.`,
      time: 113,
    },
    {
      text: 'Your struggle to prioritize your needs can leave you with many unfinished projects ',
      time: 115,
    },
    {
      text: 'and may make you seem careless.',
      time: 120,
    },
    {
      text: 'The tendency to leave projects incomplete will eventually make you dissatisfied with yourself.',
      time: 122,
    },
    {
      text: 'To avoid the trauma of feeling unfulfilled, ',
      time: 127,
    },
    {
      text: 'focus on satisfying your needs before indulging in your wants.',
      time: 130,
    },
    {
      text: 'When you prioritize and manage your time effectively, ',
      time: 133,
    },
    {
      text: `you'll complete your tasks and achieve the success you're destined for.`,
      time: 136,
    },
    {
      text: '',
      time: 141,
    },
  ],
  4: [
    {
      text: 'Your destiny number is 4, ',
      time: 1,
    },
    {
      text: 'which explains why you tend to see the world in black and white terms.',
      time: 3,
    },
    {
      text: 'You possess an almost innocent mindset, ',
      time: 6,
    },
    {
      text: 'one that is not as familiar with the complexities and pains others might experience.',
      time: 8,
    },
    {
      text: 'As a result, when someone hurts you, you often look inward, trying to understand ',
      time: 13,
    },
    {
      text: 'what you could have done to deserve their actions and constantly placing blame upon yourself.',
      time: 17,
    },
    {
      text: 'The emoji that best represents you is probably a ruler or compass, ',
      time: 22,
    },
    {
      text: 'as it shows just how straightforward and calculated you can be.',
      time: 26,
    },
    {
      text: `When you make a decision, you don't waver or deviate from the path.`,
      time: 30,
    },
    {
      text: `You'd rather stick with the done-to-death methods of doing things.`,
      time: 34,
    },
    {
      text: `Your social life is non-existent, but it's not because you're not fun.`,
      time: 38,
    },
    {
      text: `People think you're too uptight to relax and enjoy yourself.`,
      time: 42,
    },
    {
      text: `As a boss, you're quick to call out your team if they try different ways to get results, `,
      time: 45,
    },
    {
      text: 'caring more about how they do things than the actual outcome.',
      time: 49,
    },
    {
      text: 'You tend to choose low-risk, by-the-book careers like law, civil service, ',
      time: 53,
    },
    {
      text: 'or teaching, where you must follow established doctrines and principles.',
      time: 57,
    },
    {
      text: 'Your down-to-earth nature makes you a great manager.',
      time: 62,
    },
    {
      text: 'When everyone else is panicking, you stay chill and know how to put resources to the best use.',
      time: 65,
    },
    {
      text: `You're one of the few people who don't let money control them.`,
      time: 70,
    },
    {
      text: 'For you, everything needs to be planned and budgeted, ',
      time: 73,
    },
    {
      text: 'from vacations and outings to groceries and even your morning coffee.',
      time: 76,
    },
    {
      text: 'This thrifty attitude helps you save more than your friends, ',
      time: 80,
    },
    {
      text: `but it also holds you back because you're too scared to invest.`,
      time: 84,
    },
    {
      text: `So, your wealth doesn't grow, and in the end, `,
      time: 88,
    },
    {
      text: `you're not much better off than those who spend on fancy cars and pricey clothes, `,
      time: 91,
    },
    {
      text: 'the ones you look down on.',
      time: 95,
    },
    {
      text: 'Sometimes, you can be a real party pooper.',
      time: 97,
    },
    {
      text: `It's cool to have boundaries, but don't forget to loosen up occasionally.`,
      time: 100,
    },
    {
      text: 'Look for the gray area between black and white.',
      time: 104,
    },
    {
      text: 'Live by the rules, but remember they have exceptions, ',
      time: 107,
    },
    {
      text: `and it's okay to bend them a bit if it helps you out.`,
      time: 110,
    },
    {
      text: `You don't need to be formal and proper all the time.`,
      time: 113,
    },
    {
      text: 'Even though you put on this super-upright act and scold people for making normal mistakes, ',
      time: 116,
    },
    {
      text: `deep down, there's a wild side hiding beneath that serious mask.`,
      time: 121,
    },
    {
      text: 'Let it out and have some fun.',
      time: 125,
    },
    {
      text: `You're already great, but then, you'll be so much better.`,
      time: 127,
    },
    {
      text: '',
      time: 131,
    },
  ],
  5: [
    {
      text: `You've got the number 5, which is super creative in numerology.`,
      time: 1,
    },
    {
      text: `You're meant to grow and create new stuff, not stay stuck in one place.`,
      time: 4,
    },
    {
      text: `You can't stand leaving things as they are or sticking to old traditions.`,
      time: 9,
    },
    {
      text: 'Every night, you go to bed dreaming about rewriting history and changing the world.',
      time: 13,
    },
    {
      text: `Some folks might think you're odd, and others might even chat behind your back, `,
      time: 17,
    },
    {
      text: 'labelling you as quirky or too daring.',
      time: 22,
    },
    {
      text: `I bet there's been someone bold enough to tell you to tone down your unconventional ways.`,
      time: 25,
    },
    {
      text: `It's natural for people to feel uneasy with the unfamiliar.`,
      time: 30,
    },
    {
      text: 'However, having someone like you around to push them out of their comfort zones ',
      time: 33,
    },
    {
      text: 'and explore better, more efficient ways of doing things is a game-changer.',
      time: 37,
    },
    {
      text: 'The only thing constant in your destiny is change.',
      time: 42,
    },
    {
      text: 'Many people come and go.',
      time: 45,
    },
    {
      text: 'Some come to help you, while others tempt you to abandon your life path.',
      time: 47,
    },
    {
      text: 'You fall in love and then fall out of it quickly, leaving many broken hearts behind.',
      time: 51,
    },
    {
      text: `You've got lots of enemies and even more bitter exes. But it's not your fault.`,
      time: 56,
    },
    {
      text: `You can't stand being stuck in a relationship that doesn't make you happy.`,
      time: 61,
    },
    {
      text: `Unfortunately, happiness isn't as easy to figure out for you as it is for others.`,
      time: 64,
    },
    {
      text: `It's like this mysterious thing you're always chasing.`,
      time: 69,
    },
    {
      text: `And even though you keep ditching what you've got in search of that happiness, `,
      time: 72,
    },
    {
      text: 'you somehow always miss the mark.',
      time: 75,
    },
    {
      text: `You don't hold your creations close to your heart because you feel they're not worth much.`,
      time: 78,
    },
    {
      text: 'So, on the outside, you appear to be someone who never runs out of ideas ',
      time: 82,
    },
    {
      text: `and doesn't need emotional support.`,
      time: 87,
    },
    {
      text: 'When no one takes the time to remind you of how much you matter, ',
      time: 89,
    },
    {
      text: 'you still find the strength to keep going.',
      time: 92,
    },
    {
      text: `That's how incredibly self-motivated you are.`,
      time: 95,
    },
    {
      text: 'But sometimes, even the strongest among us need a shoulder to lean on, ',
      time: 98,
    },
    {
      text: `a kind word, or a gentle reminder that they're not alone.`,
      time: 102,
    },
    {
      text: 'The one thing holding you back is how often you try to escape reality.',
      time: 106,
    },
    {
      text: 'When you dive into creative activities, ',
      time: 110,
    },
    {
      text: `it's because they help you forget your problems for a while.`,
      time: 113,
    },
    {
      text: 'But when you come back to the real world, ',
      time: 116,
    },
    {
      text: 'you find that those troubles are still waiting for you.',
      time: 119,
    },
    {
      text: `So, it's time to stop running away.`,
      time: 122,
    },
    {
      text: 'Grab a hold of your creativity and use it to tackle real-life situations.',
      time: 124,
    },
    {
      text: `If you do that, you'll see that your reality can become as beautiful as your wildest dreams.`,
      time: 129,
    },
    {
      text: 'So, take a deep breath, face your challenges head-on, ',
      time: 135,
    },
    {
      text: 'and your destiny will turn out amazing.',
      time: 139,
    },
    {
      text: '',
      time: 143,
    },
  ],
  6: [
    {
      text: 'Your Destiny number is 6, ',
      time: 1,
    },
    {
      text: 'which reveals that your most exceptional gift is love.',
      time: 3,
    },
    {
      text: `However, your love isn't the kind that remains hidden.`,
      time: 6,
    },
    {
      text: 'Instead, you radiate it outwards, enabling it to manifest and prevent calamities.',
      time: 9,
    },
    {
      text: 'At times, love can also become your most significant challenge, ',
      time: 15,
    },
    {
      text: 'especially when you love too deeply.',
      time: 19,
    },
    {
      text: 'You blend easily into large gatherings, hanging out in the middle, tending to those in need.',
      time: 22,
    },
    {
      text: `Your life goals are to stand up for those who can't defend themselves, `,
      time: 28,
    },
    {
      text: 'support the less fortunate, and uphold the law.',
      time: 32,
    },
    {
      text: `Unlike most people who only care about themselves and don't worry about others' problems, `,
      time: 35,
    },
    {
      text: `you see everyone's issues as your own.`,
      time: 39,
    },
    {
      text: 'Your parenting instincts come alive around kids or folks who seem lost.',
      time: 42,
    },
    {
      text: `Even though you're not really into becoming a parent, you act in a way that kids love, `,
      time: 47,
    },
    {
      text: `often getting them gifts and toys, and you don't mind helping them with their homework.`,
      time: 52,
    },
    {
      text: 'Your love is patient, kind, and generous.',
      time: 57,
    },
    {
      text: `You're always ready to help out.`,
      time: 60,
    },
    {
      text: 'Throughout life, you keep on giving.',
      time: 62,
    },
    {
      text: `Soon, you'll see the rewards of your kindness.`,
      time: 65,
    },
    {
      text: 'When you use your wealth to make a difference, it comes back to you in many ways.',
      time: 68,
    },
    {
      text: 'Anyone would be lucky to have you as their partner.',
      time: 72,
    },
    {
      text: `Your affection is genuine, pure, and you're super forgiving.`,
      time: 75,
    },
    {
      text: 'Your heart is filled with love, leaving no space for holding grudges.',
      time: 79,
    },
    {
      text: 'Even when others would be super upset, ',
      time: 83,
    },
    {
      text: 'you welcome back those who let you down or left you hanging.',
      time: 85,
    },
    {
      text: `You're so caring that some people might see you as a doormat they can toss aside after using.`,
      time: 89,
    },
    {
      text: 'Fortunately, you had some experiences during childhood ',
      time: 94,
    },
    {
      text: 'that taught you to exercise your generosity with discretion.',
      time: 97,
    },
    {
      text: 'As you grow older, you master how to discern when someone really needs your help ',
      time: 100,
    },
    {
      text: 'or is just playing you for a fool.',
      time: 104,
    },
    {
      text: 'Think about taking a job where you can show your love for others.',
      time: 107,
    },
    {
      text: 'You could work at a non-profit, volunteer at a food bank, teach, ',
      time: 110,
    },
    {
      text: 'become a consultant, or even join law enforcement.',
      time: 113,
    },
    {
      text: 'In any of these roles, your passion for people and your intense focus will help you.',
      time: 116,
    },
    {
      text: `You might not end up super rich, but that's not how you measure success.`,
      time: 121,
    },
    {
      text: `You think the true measure of success is how much one's life impacts others.`,
      time: 125,
    },
    {
      text: `Don't be shy about sharing your love. Everyone needs it.`,
      time: 130,
    },
    {
      text: `So go out there and make a difference in people's lives.`,
      time: 133,
    },
    {
      text: '',
      time: 138,
    },
  ],
  7: [
    {
      text: 'Your Destiny number is 7.',
      time: 1,
    },
    {
      text: 'Out of the twelve destiny numbers, 7 occupies the middle position.',
      time: 3,
    },
    {
      text: `So, you're stuck in between, not really sure where you fit in, `,
      time: 6,
    },
    {
      text: 'always searching for where you truly belong.',
      time: 9,
    },
    {
      text: 'Knowledge means everything to you.',
      time: 11,
    },
    {
      text: `When someone is looking for you, they know they'll probably find you at the library, `,
      time: 13,
    },
    {
      text: 'lost in a book, figuring out answers to some of the craziest questions.',
      time: 17,
    },
    {
      text: 'Your brain is always on fire, adding up facts and making sense of things.',
      time: 21,
    },
    {
      text: `Even when you're asleep, your mind's still buzzing.`,
      time: 25,
    },
    {
      text: `But you can't crack every nut. Some problems are just too tough.`,
      time: 28,
    },
    {
      text: 'When you encounter these puzzling issues, ',
      time: 32,
    },
    {
      text: 'you step back from the crowd and find your own space.',
      time: 35,
    },
    {
      text: `That's when you bring out all your tools and start connecting the dots, drawing the map.`,
      time: 38,
    },
    {
      text: 'You never give up on any mystery, even if it seems impossible to solve.',
      time: 42,
    },
    {
      text: `You're a total introvert with not much social energy.`,
      time: 47,
    },
    {
      text: 'Just hanging out with a few folks can tire you out, ',
      time: 50,
    },
    {
      text: `so it's annoying when someone invades your personal space.`,
      time: 52,
    },
    {
      text: 'Reading a lot helps you understand how people think.',
      time: 55,
    },
    {
      text: `So, you're not afraid to keep those you don't want around at bay, knowing they can't hurt you.`,
      time: 58,
    },
    {
      text: 'This boldness makes you seem cold to some and cool to others.',
      time: 63,
    },
    {
      text: `Even the ones who can't get past your defenses still admire your intelligence from a distance.`,
      time: 66,
    },
    {
      text: 'When you meet someone new, ',
      time: 71,
    },
    {
      text: 'you quickly judge them based on how logical they seem during your first chat.',
      time: 73,
    },
    {
      text: `This helps you decide if they're worth keeping in your life or not.`,
      time: 77,
    },
    {
      text: `Anyone who doesn't enjoy probing current phenomena as much as `,
      time: 80,
    },
    {
      text: 'you do will weigh you down and suppress your ambition.',
      time: 83,
    },
    {
      text: 'Your intelligence announces you wherever you go, ',
      time: 86,
    },
    {
      text: 'so you never run out of opportunities for career and academic advancement.',
      time: 89,
    },
    {
      text: `Your destiny leads you on an easy path where you don't have to struggle to get anything, `,
      time: 93,
    },
    {
      text: `not because you're lazy, `,
      time: 97,
    },
    {
      text: 'but because you spent your early days learning while others just let life pass by.',
      time: 99,
    },
    {
      text: `But sometimes, knowing so much might make you feel like everyone else isn't worth your time.`,
      time: 103,
    },
    {
      text: 'When that happens, remember that people wrote the books you study.',
      time: 109,
    },
    {
      text: `If you push everyone away with your high-minded attitude, you'll end up alone.`,
      time: 113,
    },
    {
      text: `Being alone isn't a problem, but having a few buddies around will make your destiny more fun.`,
      time: 117,
    },
    {
      text: 'Join clubs, attend events, or start conversations with people you meet.',
      time: 122,
    },
    {
      text: `You'll soon find that making friends can be pretty easy and rewarding.`,
      time: 126,
    },
    {
      text: '',
      time: 131,
    },
  ],
  8: [
    {
      text: 'Your destiny number is 8.',
      time: 1,
    },
    {
      text: `You're destined to be very powerful and materialistic.`,
      time: 3,
    },
    {
      text: 'While others wait for adulthood to catch up with them ',
      time: 6,
    },
    {
      text: 'before taking practical steps forward with their lives, ',
      time: 8,
    },
    {
      text: 'you map out your plans at an early age.',
      time: 10,
    },
    {
      text: 'With the available resources, ',
      time: 13,
    },
    {
      text: 'you implement those plans and build your empire brick by brick.',
      time: 15,
    },
    {
      text: 'No one is a better businessperson than you are.',
      time: 18,
    },
    {
      text: 'The speed at which you take the initiative and ',
      time: 21,
    },
    {
      text: 'turn every situation into an opportunity to make money is astonishing.',
      time: 23,
    },
    {
      text: 'Fears and worries of failure often overcome the desire to be financially strong.',
      time: 27,
    },
    {
      text: 'But you toss those dark emotions aside and gaze at the light at the end of the tunnel.',
      time: 31,
    },
    {
      text: 'Discipline is your most obvious strength.',
      time: 36,
    },
    {
      text: `When you need to get work done, you don't waste any extra seconds lingering elsewhere.`,
      time: 39,
    },
    {
      text: 'Surprisingly, time is your most precious asset.',
      time: 44,
    },
    {
      text: `That's why you are extremely good at managing it.`,
      time: 47,
    },
    {
      text: 'Your head is like a library.',
      time: 50,
    },
    {
      text: 'In your head, thoughts about each aspect of your life are arranged in order of importance.',
      time: 52,
    },
    {
      text: 'The most important aspects come first and determine your courses of action.',
      time: 58,
    },
    {
      text: 'Very often, your action is to work, save money, and invest in your future.',
      time: 62,
    },
    {
      text: 'Someone with a mindset like yours is made for positions of authority.',
      time: 67,
    },
    {
      text: 'When you occupy any leadership or managerial office, you swing into action immediately.',
      time: 71,
    },
    {
      text: `You'll use your power to right the wrongs and `,
      time: 77,
    },
    {
      text: 'do what will lead to the happiness of the greatest number of people.',
      time: 79,
    },
    {
      text: `You'll fare better with a partner who understands the gravity of your mission on Earth.`,
      time: 83,
    },
    {
      text: `It's only when they understand that they'll be able to fully support you `,
      time: 88,
    },
    {
      text: 'without being jealous or hurt when you place official duties over them.',
      time: 91,
    },
    {
      text: `Life won't go easy on you. Your biggest enemy will be yourself.`,
      time: 95,
    },
    {
      text: 'It may be too difficult for you to participate in activities ',
      time: 100,
    },
    {
      text: 'just for the sake of learning or having fun.',
      time: 104,
    },
    {
      text: 'Your mind is set on winning.',
      time: 107,
    },
    {
      text: 'As a result, you can be ruthless towards your opponents ',
      time: 109,
    },
    {
      text: `when you feel they're getting in your way.`,
      time: 111,
    },
    {
      text: 'You may do anything to take them down and ',
      time: 113,
    },
    {
      text: 'even cause permanent damage to their reputation or families.',
      time: 115,
    },
    {
      text: `But if you do that, you won't derive the satisfaction`,
      time: 118,
    },
    {
      text: 'you thought you would derive from your achievements.',
      time: 122,
    },
    {
      text: `Don't waste time trying to bring others down.`,
      time: 125,
    },
    {
      text: 'Just work on making yourself better.',
      time: 127,
    },
    {
      text: `There's plenty of space for everyone up there.`,
      time: 130,
    },
    {
      text: '',
      time: 134,
    },
  ],
  9: [
    {
      text: 'Your destiny number is 9, ',
      time: 1,
    },
    {
      text: 'and it shows that you want to see justice in the world.',
      time: 3,
    },
    {
      text: 'All parts of your personality focus on making sure everyone gets treated fairly.',
      time: 5,
    },
    {
      text: `You're different from others who try to do good things `,
      time: 9,
    },
    {
      text: `because you're not driven by love or vulnerability.`,
      time: 12,
    },
    {
      text: `Instead, you're driven by your experiences and a sense of urgency.`,
      time: 14,
    },
    {
      text: 'People sometimes wonder how old you are since you seem emotionally mature.',
      time: 18,
    },
    {
      text: `This is because you've experienced many good and bad things early on, `,
      time: 23,
    },
    {
      text: `and you already know what's important to you.`,
      time: 26,
    },
    {
      text: `You've had some tough times, `,
      time: 28,
    },
    {
      text: `but you don't want others to go through the same thing if you can help it.`,
      time: 30,
    },
    {
      text: `On the outside, you want people to believe that you're perpetually calm.`,
      time: 33,
    },
    {
      text: 'So, you prefer to dress in cooler colors that emphasize your role as an agent of goodness.',
      time: 37,
    },
    {
      text: 'However, your calm facade sometimes gets in the way of truly expressing your feelings.',
      time: 42,
    },
    {
      text: `You're afraid that you've been so exalted to the position of a god that `,
      time: 47,
    },
    {
      text: `if you display human emotions, you'll be ridiculed or judged.`,
      time: 51,
    },
    {
      text: `Your cold, aloof demeanor prevents you from openly pursuing the one you love.`,
      time: 54,
    },
    {
      text: `It puts off potential partners who think you're incapable of being romantic.`,
      time: 58,
    },
    {
      text: `It also makes people think it's okay to stress you beyond humanly acceptable levels.`,
      time: 63,
    },
    {
      text: 'While you may not collapse beneath the weight of that burden, ',
      time: 68,
    },
    {
      text: `you may harbor resentment towards the people you're trying to help.`,
      time: 71,
    },
    {
      text: 'That annoyance might build up until it explodes when you least expect it.',
      time: 75,
    },
    {
      text: 'The problem with destiny number 9 is that you sometimes ',
      time: 79,
    },
    {
      text: 'let out your frustration at the worst times, and it surprises everyone.',
      time: 82,
    },
    {
      text: 'In your zeal to help everyone and everything, you can be a little too focused on ',
      time: 87,
    },
    {
      text: `what you're doing rather than on how it's being received.`,
      time: 91,
    },
    {
      text: `That's why you may occasionally find yourself acting `,
      time: 94,
    },
    {
      text: 'like a one-person show and then wondering ',
      time: 97,
    },
    {
      text: 'why everyone is so mad at you for not listening to them.',
      time: 99,
    },
    {
      text: 'Plenty of people could use the advice you have to give, ',
      time: 102,
    },
    {
      text: 'but they need to be able to trust that your advice will be useful.',
      time: 105,
    },
    {
      text: 'For them to trust you, they need to know that your interests are aligned with theirs.',
      time: 108,
    },
    {
      text: `If you're feeling pressured by someone who wants you to do something `,
      time: 113,
    },
    {
      text: 'that directly conflicts with your interests, speak up.',
      time: 116,
    },
    {
      text: `That's when you'll add as much value as you're destined to add to humanity.`,
      time: 120,
    },
    {
      text: '',
      time: 126,
    },
  ],
  11: [
    {
      text: 'Your destiny number is 11.',
      time: 1,
    },
    {
      text: 'This number makes you as unstable as water.',
      time: 3,
    },
    {
      text: 'Like water, you easily soak up the energy in the room ',
      time: 5,
    },
    {
      text: 'and are easily affected by the bad energies of others.',
      time: 9,
    },
    {
      text: `You're in tune with your surroundings, `,
      time: 12,
    },
    {
      text: 'and your emotions often change based on the people around you.',
      time: 14,
    },
    {
      text: `You're happy when everyone's happy.`,
      time: 17,
    },
    {
      text: 'But when there are mixed emotions, you get confused and frustrated.',
      time: 19,
    },
    {
      text: `Expressing frustration isn't easy, `,
      time: 22,
    },
    {
      text: `so you escape into a fantasy world where you don't have to feel it anymore.`,
      time: 24,
    },
    {
      text: `You're your safe zone and your dangerous alley.`,
      time: 28,
    },
    {
      text: 'The contrast between the different sides of you ',
      time: 31,
    },
    {
      text: 'that manifest in different situations scares you.',
      time: 34,
    },
    {
      text: `Other times, you feel as if you don't really know yourself `,
      time: 37,
    },
    {
      text: `and so can't even identify what you like and dislike.`,
      time: 40,
    },
    {
      text: 'This makes it easy for others to influence you.',
      time: 44,
    },
    {
      text: `You often follow whoever's leading, whether they're going in the right direction.`,
      time: 47,
    },
    {
      text: 'Figuring out right from wrong might be too strange for you.',
      time: 51,
    },
    {
      text: `You're like a sponge, soaking up everything people say without questioning it.`,
      time: 55,
    },
    {
      text: 'Rather than weigh different options to identify which you prefer, ',
      time: 59,
    },
    {
      text: 'you leave all the options on an equal scale.',
      time: 62,
    },
    {
      text: `This makes you overwhelmed because you don't know which option to choose.`,
      time: 65,
    },
    {
      text: `When you're in such a dilemma, you rely on others to decide for you `,
      time: 69,
    },
    {
      text: 'and blame them if their decisions land you in trouble.',
      time: 72,
    },
    {
      text: 'Your style and opinions change with your mood, which happens quite often.',
      time: 75,
    },
    {
      text: 'You tend to base your views on what people say rather than your beliefs.',
      time: 79,
    },
    {
      text: 'Connecting with you takes time and effort.',
      time: 83,
    },
    {
      text: 'Once someone has broken your psychological walls, you quickly rebuild them without trying.',
      time: 86,
    },
    {
      text: `And since you exude multiple energies, it's hard for people to pinpoint `,
      time: 92,
    },
    {
      text: 'which one they should tackle when trying to get to know you.',
      time: 95,
    },
    {
      text: 'The result is that you stay friendless.',
      time: 98,
    },
    {
      text: 'You might blame your lack of friends on being socially awkward ',
      time: 101,
    },
    {
      text: 'or shy and think that your past experiences shaped who you are.',
      time: 104,
    },
    {
      text: `It's normal for people with the destiny number 11 to feel this way.`,
      time: 108,
    },
    {
      text: `You wish for friends, but you're lost in your dream world.`,
      time: 112,
    },
    {
      text: `It's important to understand that having friends may not be part of your destiny.`,
      time: 116,
    },
    {
      text: 'Your strength lies in your independence and ability to entertain yourself.',
      time: 120,
    },
    {
      text: 'If you find peace within yourself, your future will begin to fall into place.',
      time: 124,
    },
    {
      text: 'Define the emotions you want to feel and the goals you want to get.',
      time: 128,
    },
    {
      text: 'Then chase them.',
      time: 131,
    },
    {
      text: '',
      time: 134,
    },
  ],
  22: [
    {
      text: 'Your destiny number is 22.',
      time: 1,
    },
    {
      text: `You don't just talk or dream about things.`,
      time: 3,
    },
    {
      text: 'You make them happen.',
      time: 5,
    },
    {
      text: 'When you lead a team, you guys usually get the job done.',
      time: 7,
    },
    {
      text: 'Your ideas might not be the best, but they always become a reality.',
      time: 10,
    },
    {
      text: 'People can count on you to finish what you start.',
      time: 14,
    },
    {
      text: `You'll make a great leader, a trustworthy boss, and an efficient employee.`,
      time: 17,
    },
    {
      text: '2 is a very sensitive number, ',
      time: 22,
    },
    {
      text: 'and having double 2s in your destiny number projects the sensitivity of 2 outwards.',
      time: 24,
    },
    {
      text: 'Instead of you feeling what others are feeling, others feel what you feel.',
      time: 29,
    },
    {
      text: `You're usually on an all-time high, desperate to meet deadlines and crush objectives.`,
      time: 33,
    },
    {
      text: 'When you disclose your mission to people, ',
      time: 38,
    },
    {
      text: 'the passion with which you sell your idea to them inspires them to help you out.',
      time: 40,
    },
    {
      text: `In leadership roles, you're a total rockstar because you never leave things half-done.`,
      time: 45,
    },
    {
      text: `When there's a problem in your jurisdiction, you don't freak out or bail.`,
      time: 50,
    },
    {
      text: `Even if everyone else leaves, you'll use your smarts `,
      time: 54,
    },
    {
      text: `and might even work yourself to the bone to find a practical solution.`,
      time: 57,
    },
    {
      text: 'Your objective approach to everything helps you apologize for wrongs done ',
      time: 60,
    },
    {
      text: 'and make amends with others.',
      time: 64,
    },
    {
      text: `Naturally, people can't walk all over you `,
      time: 66,
    },
    {
      text: 'because the sheer force of your destiny scares them away from messing with you.',
      time: 68,
    },
    {
      text: 'Going by your strongly founded realism, ',
      time: 72,
    },
    {
      text: `there shouldn't be anything height you can't reach in life.`,
      time: 74,
    },
    {
      text: `Unfortunately, you're limited by the quantity and quality of resources available.`,
      time: 78,
    },
    {
      text: `You're also limited by your extreme practicality, `,
      time: 82,
    },
    {
      text: 'which prevents you from accepting that ',
      time: 85,
    },
    {
      text: 'there could be other possibilities besides what you aim for.',
      time: 87,
    },
    {
      text: 'You have tried to do a particular thing several times and failed.',
      time: 90,
    },
    {
      text: 'But you still kept trying.',
      time: 94,
    },
    {
      text: 'I want you to know that you may be patient, but time is not.',
      time: 96,
    },
    {
      text: 'Time is running out.',
      time: 99,
    },
    {
      text: 'So, if you continue pressing forward at just one goal and ignoring others, ',
      time: 101,
    },
    {
      text: `you'll end up under-fulfilled and pessimistic.`,
      time: 105,
    },
    {
      text: `It would help if you dropped the veil, you've cast over your eyes.`,
      time: 109,
    },
    {
      text: 'Your options are as many as you make them.',
      time: 113,
    },
    {
      text: 'The fact that you cannot finish one project does not mean ',
      time: 115,
    },
    {
      text: 'that all others you attempt will stay unfinished.',
      time: 118,
    },
    {
      text: `Refuse to let doubt set in when you're unable to hit the mark.`,
      time: 120,
    },
    {
      text: 'Shield yourself from being paralyzed by fear.',
      time: 124,
    },
    {
      text: 'Hope for the best, and surround yourself with people or things that help you stay optimistic.',
      time: 127,
    },
    {
      text: '',
      time: 134,
    },
  ],
  33: [
    {
      text: 'Your destiny number is 33, the number of goodness.',
      time: 1,
    },
    {
      text: 'Life is nothing short of a struggle, ',
      time: 4,
    },
    {
      text: `but you're destined to make living easier for everyone.`,
      time: 6,
    },
    {
      text: 'You were sent to Earth to prevent it from being destroyed by bad people.',
      time: 9,
    },
    {
      text: 'Your number is the cardinal sign of everything good.',
      time: 13,
    },
    {
      text: `You're here to replace sicknesses sweeping with health.`,
      time: 16,
    },
    {
      text: `It's your destiny not to just be rich but to make many rich.`,
      time: 19,
    },
    {
      text: `Because you're equipped with the strongest business acumen among all destiny numbers, `,
      time: 23,
    },
    {
      text: `you'll enjoy creating job opportunities for others `,
      time: 27,
    },
    {
      text: 'and thus turn their lives around for the better.',
      time: 30,
    },
    {
      text: 'When you speak, your words edify the listeners.',
      time: 33,
    },
    {
      text: `You're the agent of transformation in your community.`,
      time: 37,
    },
    {
      text: `I know you're going through a tough phase right now.`,
      time: 40,
    },
    {
      text: 'Remember that the darkest part of the night is just before dawn.',
      time: 42,
    },
    {
      text: `From birth, you've been arraigned on the good side in the battle between good and evil.`,
      time: 46,
    },
    {
      text: 'Various elements of this world are striving to stain your good conscience.',
      time: 52,
    },
    {
      text: 'Someone may have broken your trust and made you believe that the world is not worth redeeming.',
      time: 58,
    },
    {
      text: `You're trying to swim to the surface, `,
      time: 63,
    },
    {
      text: `but there's a thing that wraps itself tighter and tighter around your neck.`,
      time: 65,
    },
    {
      text: `That thing is so tight that you feel as if you can't breathe.`,
      time: 69,
    },
    {
      text: `That thing may not necessarily be evil, but it's stopping you from moving forward.`,
      time: 72,
    },
    {
      text: 'The moment you identify what that thing is, you will use your good powers to conquer it.',
      time: 77,
    },
    {
      text: 'Your biggest weakness is yourself.',
      time: 82,
    },
    {
      text: 'You battle with internal fear.',
      time: 84,
    },
    {
      text: 'Fear prevents you from manifesting your inherent goodness.',
      time: 86,
    },
    {
      text: `You worry that you're too pure, so everyone will dislike you `,
      time: 90,
    },
    {
      text: 'if you allow your goodness to influence your actions.',
      time: 93,
    },
    {
      text: 'Then you shirk from expressing your goodness in its fullness.',
      time: 96,
    },
    {
      text: 'In the end, you thwart your destiny with your own hands.',
      time: 99,
    },
    {
      text: 'Seeing the number 33 popping up on your numerology chart is a proverbial wake-up call.',
      time: 103,
    },
    {
      text: `It's the universe nudging you to finally squash that fear that's been lurking around.`,
      time: 108,
    },
    {
      text: `When you've conquered your fear, you'll care less about what people say about you.`,
      time: 113,
    },
    {
      text: `You'll also become more grounded in the real world and form solid relationships with others.`,
      time: 118,
    },
    {
      text: 'The beauty of this connection isn’t just in the solidity it brings to your relationships.',
      time: 123,
    },
    {
      text: 'It also lets you peek into the world from the perspectives of others.',
      time: 128,
    },
    {
      text: `You'll begin to fathom the reasons behind the actions of those around you.`,
      time: 132,
    },
    {
      text: 'This understanding will make your interactions richer ',
      time: 136,
    },
    {
      text: 'and help you achieve your ultimate purpose.',
      time: 139,
    },
    {
      text: '',
      time: 143,
    },
  ],
};
