import { useEffect, useState } from 'react';
import { StartIntroScript } from '../../../../../common/start-intro-script';

export const useStartIntroAudioData = ({ personalData, startPlay }) => {
  const [countSeconds, setCountSeconds] = useState(0);
  const [currentCaption, setCurrentCaption] = useState('');
  const [intervalId, setIntervalId] = useState(undefined);

  useEffect(() => {
    if (startPlay) {
      startTimer();
    }
  }, [startPlay]);

  useEffect(() => {
    if (countSeconds > 0) {
      const nextBlock = StartIntroScript.find(
        (block) => block.time === countSeconds
      );
      if (nextBlock) {
        setCurrentCaption(
          nextBlock.text
            .replace('{name}', personalData.name)
            .replace(
              '{birthday}',
              `${personalData.monthNumber > 9 ? '' : '0'}${
                personalData.monthNumber
              }/${personalData.day > 9 ? '' : '0'}${personalData.day}/${
                personalData.year
              }`
            )
        );
        if (!nextBlock.text) {
          stopTimer();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countSeconds]);

  const startTimer = () => {
    const id = setInterval(() => setCountSeconds((old) => old + 1), 1000);
    setIntervalId(id);
  };

  const stopTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  };

  return {
    caption: currentCaption,
    isFinished: countSeconds > 0 && !intervalId,
    isStarted: countSeconds > 0,
  };
};
