import { useEffect, useState } from 'react';
import './QuizName.scss';

const QuizName = ({ onStart }) => {
  const [firstNameText, setFirstNameText] = useState('');
  const [isFormEntered, setIsFormEntered] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsFormEntered(true);
    }, 100);
  }, []);

  return (
    <div className={`quiz-name ${isFormEntered ? 'entered' : ''}`}>
      <div className="box__wrapper">
        <div className="box__inner">
          <div className="box__content">
            <p className="h2">Enter your First Name</p>
            <div className="form-content">
              <input
                placeholder="Name"
                value={firstNameText}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value < 1) {
                    setFirstNameText(value);
                  } else if (value.length < 16) {
                    value = value.replace(' ', '');
                    setFirstNameText(
                      `${value[0].toUpperCase()}${value.slice(1)}`
                    );
                  }
                }}
                autoFocus
              />
              <div
                className={`btn-gradient btn-start ${
                  !firstNameText ? 'disabled' : ''
                }`}
                onClick={() => {
                  setIsFormEntered(false);
                  setTimeout(() => {
                    onStart(firstNameText);
                  }, 1000);
                }}
              >
                Start your reading
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizName;
