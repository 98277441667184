import axios from 'axios';

const ELEVENLABS_API_BASE_URL = 'https://api.elevenlabs.io/v1';
const ELEVENLABS_API_KEY = 'b2977d92b80b09ac69f52289e84902c9'; // production key

export const covertTextToSpeech = async (inputText) => {
  const VOICE_ID = 'TxGEqnHWrfWFTfGW9XjX'; // premade/Josh

  const options = {
    method: 'POST',
    url: `${ELEVENLABS_API_BASE_URL}/text-to-speech/${VOICE_ID}`,
    headers: {
      accept: 'audio/mpeg',
      'content-type': 'application/json',
      'xi-api-key': `${ELEVENLABS_API_KEY}`,
    },
    data: {
      text: inputText,
    },
    responseType: 'arraybuffer',
  };

  try {
    const response = await axios.request(options);
    const mp3 = new Blob([response.data], { type: 'audio/mpeg' });
    const url = URL.createObjectURL(mp3);
    return url;
  } catch (error) {
    console.log('API ERROR [covertTextToSpeech]: ', error);
  }

  return null;
};

export const addSubscriberToAWeber = (data) => {
  const formData = new FormData();
  formData.append('meta_web_form_id', '212189434');
  formData.append('meta_split_id', '');
  formData.append('listname', 'awlist6631175');
  formData.append('meta_adtracking', 'MysticNumbers_Reading_Form');
  formData.append('meta_message', 1);
  formData.append('meta_required', 'name,email');
  formData.append('meta_tooltip', '');
  formData.append('name', data.name);
  formData.append('email', data.email);

  fetch('https://www.aweber.com/scripts/addlead.pl', {
    method: 'POST',
    mode: 'no-cors',
    referrerPolicy: 'no-referrer',
    body: formData,
  });
};

// User APIs
const instance = axios.create({
  baseURL: 'https://mystic-numbers-api-a620c5960e02.herokuapp.com',
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('mystic-numbers-token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default instance;
