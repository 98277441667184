import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userAPI from '../../api/api';

export const fetchMembers = createAsyncThunk(
  'admin/fetch-members',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await userAPI.get('/users/');
      if (response.data.error) {
        return rejectWithValue(response.data.error);
      }
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteMember = createAsyncThunk(
  'admin/delete-member',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await userAPI.post('/users/delete', { userId });
      if (response.data.error) {
        return rejectWithValue(response.data.error);
      }
      return {
        ...response.data,
        userId,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  members: [],
  loading: {
    action: '',
    status: '',
    message: '',
  },
};

export const AdminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearLoading: (state) => {
      state.loading = {
        action: '',
        status: '',
        message: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMembers.pending, (state, action) => {
      state.loading = {
        action: 'fetch-members',
        status: 'pending',
        message: '',
      };
    });
    builder.addCase(fetchMembers.fulfilled, (state, action) => {
      state.loading = {
        action: 'fetch-members',
        status: 'fulfilled',
        message: '',
      };
      state.members = action.payload;
    });
    builder.addCase(fetchMembers.rejected, (state, action) => {
      state.loading = {
        action: 'fetch-members',
        status: 'rejected',
        message: action.payload.message,
      };
    });
    builder.addCase(deleteMember.pending, (state, action) => {
      state.loading = {
        action: 'delete-member',
        status: 'pending',
        message: '',
      };
    });
    builder.addCase(deleteMember.fulfilled, (state, action) => {
      state.loading = {
        action: 'delete-member',
        status: 'fulfilled',
        message: '',
      };
      if (action.payload.success) {
        state.members = state.members.filter(
          (member) => member._id !== action.payload.userId
        );
      }
    });
    builder.addCase(deleteMember.rejected, (state, action) => {
      state.loading = {
        action: 'delete-member',
        status: 'rejected',
        message: action.payload.message,
      };
    });
  },
});

// Action creators are generated for each case reducer function
export const { clearLoading } = AdminSlice.actions;

export default AdminSlice.reducer;
