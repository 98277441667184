import ExpressionBackgroundImg from '../../../assets/images/expression-background.png';
import './BackgroundImages.scss';

const BackgroundImages = () => {
  return (
    <div className="background-images">
      <img src={ExpressionBackgroundImg} alt="expression-background" />
    </div>
  );
};

export default BackgroundImages;
