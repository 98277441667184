import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { covertTextToSpeech } from '../../../../../api/api';
import { S3UploadedAudios } from '../../../../../common/constants';
import VoiceCaptionBox from '../audio-caption-box/AudioCaptionBox';
import { useEndOutroAudioData } from './useEndOutroAudioData';

const EndOutroAudio = ({ name, readingStep }) => {
  const [nameAudio, setNameAudio] = useState(null);
  const [scriptAudio, setScriptAudio] = useState(null);
  const [canPlayNameAudio, setCanPlayNameAudio] = useState(false);
  const [canPlayScriptAudio, setCanPlayScriptAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (name) {
      setTimeout(() => loadAudios(), 20000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const loadAudios = async () => {
    const nameSpeech = await covertTextToSpeech(
      `${name}, this might surprise you,`
    );
    const nameAudioObj = new Howl({
      src: [nameSpeech],
      html5: true,
      format: 'mp3',
      onload: () => setCanPlayNameAudio(true),
      onplay: () => setIsPlaying(true),
    });
    setNameAudio(nameAudioObj);

    const outroAudioObj = new Howl({
      src: [S3UploadedAudios.END_OUTRO],
      html5: true,
      format: 'mp3',
      onload: () => setCanPlayScriptAudio(true),
    });
    setScriptAudio(outroAudioObj);
  };

  useEffect(() => {
    if (
      readingStep === 'outro-order' &&
      nameAudio &&
      scriptAudio &&
      canPlayNameAudio &&
      canPlayScriptAudio &&
      !isPlaying
    ) {
      setTimeout(() => nameAudio.play(), 1000);
      setTimeout(() => scriptAudio.play(), 3000);
    }
  }, [
    canPlayNameAudio,
    canPlayScriptAudio,
    isPlaying,
    nameAudio,
    scriptAudio,
    readingStep,
  ]);

  const { caption, isFinished, isStarted } = useEndOutroAudioData({
    name,
    startPlay:
      readingStep === 'outro-order' && canPlayNameAudio && canPlayScriptAudio,
  });

  useEffect(() => {
    if (isFinished) {
      nameAudio.unload();
      scriptAudio.unload();
    }
  }, [isFinished, nameAudio, scriptAudio]);

  return (
    <VoiceCaptionBox
      caption={caption}
      isFinished={isFinished}
      isStarted={isStarted}
      hiddenControls
    />
  );
};

export default EndOutroAudio;
