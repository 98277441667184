import { useEffect, useState } from 'react';
import './DecadeStep.scss';

const DecadeStep = ({ className, onSelect }) => {
  const [delayOffset, setDelayOffset] = useState(0.2);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setDelayOffset(0.1);
    }
  }, []);

  const getAnimationStyle = (index) => {
    return {
      animation: `scale-up-tl 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) ${
        delayOffset * (index + 1)
      }s both`,
    };
  };

  return (
    <div className={`step-content ${className}`}>
      <p className="h2 text-center">Which Decade were you born?</p>

      <div className="decade-grid">
        {Array(12)
          .fill(1910)
          .map((decade, i) => (
            <div
              key={decade + i * 10}
              className="number-box"
              style={getAnimationStyle(i)}
              onClick={() => onSelect(decade + i * 10)}
            >
              <div className="number-box__inner">{decade + i * 10}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DecadeStep;
