import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userAPI from '../../api/api';

export const addSubscriberToSendlane = createAsyncThunk(
  'reading/addSubscriberToSendlane',
  async (data, { rejectWithValue }) => {
    try {
      const response = await userAPI.post('/users/subscribe', data);
      if (response.data.error) {
        return rejectWithValue(response.data.error);
      }
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLastReadingNumbers = createAsyncThunk(
  'reading/updateLastReadingNumbers',
  async (data, { rejectWithValue }) => {
    try {
      const response = await userAPI.post('/users/update-reading-numbers', {
        readingNumbers: data,
      });
      if (response.data.error) {
        return rejectWithValue(response.data.error);
      }
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  step: '',
  readingNumbers: null,
};

export const ReadingSlice = createSlice({
  name: 'reading',
  initialState,
  reducers: {
    updateReadingNumbers: (state, action) => {
      state.readingNumbers = action.payload;
    },
    updateReadingStep: (state, action) => {
      state.step = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addSubscriberToSendlane.fulfilled, (state, action) => {
      if (action.payload.error) {
        console.log(
          'API ERROR [updateLastReadingNumbers]: ',
          action.payload.error
        );
      } else {
        console.log(action.payload);
      }
    });
    builder.addCase(updateLastReadingNumbers.fulfilled, (state, action) => {
      if (action.payload.error) {
        console.log(
          'API ERROR [updateLastReadingNumbers]: ',
          action.payload.error
        );
      } else {
        state.readingNumbers = action.payload.lastReadingNumbers;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { updateReadingNumbers, updateReadingStep } = ReadingSlice.actions;

export default ReadingSlice.reducer;
