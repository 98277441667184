import { useEffect, useState } from 'react';

export const useInterval = ({ callback, ms }) => {
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (!intervalId) {
      const id = setInterval(callback, ms);
      setIntervalId(id);
    }
  }, [callback, intervalId, ms]);

  const finishInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  return {
    finishInterval,
  };
};
