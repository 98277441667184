import React, { useEffect, useState } from 'react';
import { EnglishAlphabets } from '../../../common/constants';
import Helper from '../../../common/helper';
import './DestinyNumberCalculation.scss';

const DestinyNumberCalculation = ({
  fullName,
  onComplete,
  onDestinyNumberCalculated,
}) => {
  const [countSeconds, setCountSeconds] = useState(-1);
  const [noAnimation, setNoAnimation] = useState(false);
  const [intervalId, setIntervalId] = useState(undefined);
  const [interval2Id, setInterval2Id] = useState(undefined);
  const [lettersBuffer, setLettersBuffer] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [isGraphicEntered, setIsGraphicEntered] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [reportResult, setReportResult] = useState({
    result: [],
    total: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      setIsGraphicEntered(true);
    }, 7500);

    setTimeout(() => {
      const id = setInterval(() => setCountSeconds((old) => old + 1), 1000);
      const id2 = setInterval(() => setNoAnimation((old) => !old), 500);
      setIntervalId(id);
      setInterval2Id(id2);
    }, 7000);
  }, []);

  useEffect(() => {
    if (countSeconds > 0 && fullName.length >= countSeconds) {
      setLettersBuffer(fullName.toLowerCase().slice(0, countSeconds));
    } else if (fullName.length < countSeconds && intervalId) {
      clearInterval(intervalId);
      clearInterval(interval2Id);
      setIntervalId(undefined);
      setInterval2Id(undefined);
      setCountSeconds((old) => old + 1);
    }
  }, [countSeconds, intervalId, interval2Id, fullName]);

  useEffect(() => {
    if (lettersBuffer.length > selectedNumbers.length) {
      const currentLetter = lettersBuffer[lettersBuffer.length - 1];
      const letterNumber =
        (EnglishAlphabets.findIndex((letter) => letter === currentLetter) + 1) %
        9;
      setSelectedNumbers([...selectedNumbers, letterNumber || 9]);
    }
  }, [lettersBuffer, selectedNumbers]);

  useEffect(() => {
    if (selectedNumbers.length === fullName.length) {
      const result = [];
      let report = Helper.getSingleSumNumber(
        `${selectedNumbers.reduce((a, b) => a + b, 0)}`
      );
      result.push(report);
      if (
        !['11', '22', '33'].includes(`${report.singleNumber}`) &&
        report.singleNumber > 9
      ) {
        report = Helper.getSingleSumNumber(`${report.singleNumber}`);
        result.push(report);
      }

      onDestinyNumberCalculated(report.singleNumber);

      setReportResult({
        result,
        total: result[0].total,
        destinyNumber: report.singleNumber,
      });

      let completedTime = (result.length * 3 + 4) * 1000;
      if (selectedNumbers.length + result.length * 3 + 4 < 42) {
        completedTime =
          (42 - selectedNumbers.length - result.length * 3 - 4) * 1000;
      }

      setTimeout(() => {
        setIsCompleted(true);
      }, completedTime);

      setTimeout(() => {
        onComplete(report.singleNumber);
      }, completedTime + 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNumbers, fullName.length]);

  return (
    <div
      className={`destiny-number-report ${isGraphicEntered ? 'entered' : ''} ${
        isCompleted ? 'hidden' : ''
      }`}
    >
      <div className="box__wrapper">
        <div className="box__inner alphabets-chart">
          {Array(9)
            .fill(1)
            .map((_, i) => (
              <div
                key={`number-${i + 1}`}
                className={`square-piece number-piece ${
                  selectedNumbers[selectedNumbers.length - 1] === i + 1 &&
                  selectedNumbers.length === countSeconds &&
                  !noAnimation
                    ? 'active'
                    : ''
                }`}
              >
                <div className="piece-inner">{i + 1}</div>
              </div>
            ))}
          {EnglishAlphabets.map((letter) => (
            <div
              key={`letter-${letter}`}
              className={`square-piece letter-piece ${
                lettersBuffer.includes(letter) ? 'selected' : ''
              } ${
                lettersBuffer[lettersBuffer.length - 1] === letter
                  ? 'active'
                  : ''
              }`}
            >
              <div className="piece-inner">{letter}</div>
            </div>
          ))}
        </div>

        <div className="calculation-block">
          <div className="numbers-sum">
            {Array(fullName.length)
              .fill(1)
              .map((_, i) => (
                <React.Fragment key={i}>
                  <div
                    className={`letter-label ${
                      selectedNumbers.length > i ? 'display' : ''
                    }`}
                  >
                    {selectedNumbers[i] || ''}
                  </div>
                  {i + 1 === fullName.length ? (
                    <>
                      <div
                        className={`symbol-label ${
                          selectedNumbers.length === fullName.length
                            ? 'display'
                            : ''
                        }`}
                      >
                        =
                      </div>
                      <div
                        className={`letter-label delay2 ${
                          selectedNumbers.length === fullName.length
                            ? 'display'
                            : ''
                        }`}
                      >
                        {reportResult.total}
                      </div>
                    </>
                  ) : (
                    <div
                      className={`symbol-label ${
                        selectedNumbers.length > i ? 'display' : ''
                      }`}
                    >
                      +
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>
          {reportResult.result.length > 0 && (
            <div className="single-digit">
              {reportResult.result.length < 2 &&
              reportResult.result[0].isMasterNumber ? (
                <div className="result-block">
                  <div
                    className={`symbol-label delay1 ${
                      countSeconds > fullName.length + 1 ? 'display' : ''
                    }`}
                    style={{ transitionDelay: '0.5s' }}
                  >
                    Your Destiny Number Is
                  </div>
                  <div
                    className={`letter-label delay1 ${
                      countSeconds > fullName.length + 1 ? 'display' : ''
                    }`}
                    style={{ transitionDelay: '1.25s' }}
                  >
                    {reportResult.destinyNumber}
                  </div>
                </div>
              ) : (
                <>
                  {reportResult.result.map((item, i) => (
                    <React.Fragment key={i}>
                      <div
                        className={`letter-label ${
                          countSeconds > fullName.length + 1 ? 'display' : ''
                        }`}
                        style={{ transitionDelay: `${0.5 * 1 + i * 3}s` }}
                      >
                        {`${item.total}`[0]}
                      </div>
                      <div
                        className={`symbol-label ${
                          countSeconds > fullName.length + 1 ? 'display' : ''
                        }`}
                        style={{ transitionDelay: `${0.5 * 2 + i * 3}s` }}
                      >
                        +
                      </div>
                      <div
                        className={`letter-label ${
                          countSeconds > fullName.length + 1 ? 'display' : ''
                        }`}
                        style={{ transitionDelay: `${0.5 * 3 + i * 3}s` }}
                      >
                        {`${item.total}`[1]}
                      </div>
                      <div
                        className={`symbol-label ${
                          countSeconds > fullName.length + 1 ? 'display' : ''
                        }`}
                        style={{ transitionDelay: `${0.5 * 4 + i * 3}s` }}
                      >
                        =
                      </div>
                      {i + 1 === reportResult.result.length ? (
                        <div className="result-block">
                          <div
                            className={`symbol-label ${
                              countSeconds > fullName.length + 1
                                ? 'display'
                                : ''
                            }`}
                            style={{ transitionDelay: `${0.5 * 6 + i * 3}s` }}
                          >
                            Your Destiny Number Is
                          </div>
                          <div
                            className={`letter-label ${
                              countSeconds > fullName.length + 1
                                ? 'display'
                                : ''
                            }`}
                            style={{ transitionDelay: `${0.5 * 7.5 + i * 3}s` }}
                          >
                            {item.singleNumber}
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`letter-label ${
                            countSeconds > fullName.length + 1 ? 'display' : ''
                          }`}
                          style={{ transitionDelay: `${0.5 * 5 + i * 3}s` }}
                        >
                          {item.singleNumber}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DestinyNumberCalculation;
