import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import NumerologyDocument from '../../pdf/numerology-document/NumerologyDocument';
import './PDFPreview.scss';

const PDFPreview = () => (
  <div className="page-pdf-preview">
    <PDFViewer>
      <NumerologyDocument
        data={{
          name: 'Alex',
          lifePathNumber: 5,
          birthDayNumber: 6,
          soulUrgeNumber: 7,
          personalityNumber: 8,
          loveNumber: 9,
          destinyNumber: 11,
          nameNumber: 2,
          planetNumber: 3,
        }}
      />
    </PDFViewer>
  </div>
);

export default PDFPreview;
