import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
import CheckMarkSVG from '../../../assets/images/affiliates/check-mark.svg';
import ArrowDownImage from '../../../assets/images/upsell/arrow-down.png';
import BlueprintImage from '../../../assets/images/upsell/blueprint.png';
import CardsImage from '../../../assets/images/upsell/cards.png';
import ProfileImage from '../../../assets/images/upsell/profile.png';
import SecureCardsImage from '../../../assets/images/upsell/secure-cards.png';
import './Upsell.scss';

const Upsell = () => {
  const [searchParams] = useSearchParams();
  const cbf = searchParams.get('cbf') || '';
  const username = searchParams.get('name') || 'Alex';

  const { defaultOrderLink, upgardeMyOrderLink } = useMemo(() => {
    return {
      defaultOrderLink: `https://mysticnumb.pay.clickbank.net/?cbitems=2&cbur=d${
        cbf ? '&cbf=' + cbf : ''
      }`,
      upgardeMyOrderLink: `https://mysticnumb.pay.clickbank.net/?cbitems=2&cbur=a${
        cbf ? '&cbf=' + cbf : ''
      }`,
    };
  }, [cbf]);

  const ultimateManifestationGuides = [
    {
      name: 'Proven Strategies:',
      text: 'Discover proven techniques and strategies to attract wealth and abundance into your life.',
    },
    {
      name: 'Mindset Shifts:',
      text: 'Learn how to cultivate a prosperous mindset and overcome limiting beliefs about money.',
    },
    {
      name: 'Practical Exercises:',
      text: 'Engage in exercises designed to help you visualize and achieve your financial goals.',
    },
    {
      name: 'Financial Empowerment:',
      text: 'Gain control over your financial future and embrace new possibilities for wealth creation.',
    },
    {
      name: 'Personal Growth:',
      text: 'Develop a deeper understanding of your desires, align your intentions, and grow personally and financially.',
    },
    {
      name: 'Positive Transformation:',
      text: 'Experience a transformation in your mindset and outlook on wealth and abundance.',
    },
    {
      name: 'Life of Fulfillment:',
      text: 'Attract opportunities and create a life filled with joy, abundance, and fulfillment.',
    },
    {
      name: 'Financial Empowerment:',
      text: 'Gain control over your financial future and embrace new possibilities for wealth creation.',
    },
  ];

  const testimonialsData = [
    {
      name: 'Alex',
      text: '“This guide changed my life! I’ve cleared my debt and started investing wisely. I feel in control of my finances for the first time in a long time! Thanks Mystic Numbers for pointing me in the right direction.”',
    },
    {
      name: 'Emma',
      text: '“Easy to understand and implement. Manifestation works!!”',
    },
  ];

  return (
    <div className="page-upsell">
      <Helmet>
        <title>Do not Close This Page | Mystic Numbers</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="hero">
        <div className="hero__upper">
          <div className="logos-wrapper">
            <div className="logo">
              <Logo />
            </div>
            <img src={SecureCardsImage} alt="secure and cards" />
          </div>
          <div className="steps-wrapper">
            <div className="step-one">Step 1: Order Received</div>
            <div className="step-two">
              <span>Step 2:</span>
              <br />
              Customize Order
            </div>
          </div>
        </div>
        <div className="hero__lower">
          <p className="heading-h1">Wait! Do not Close This Page!</p>
          <p>Do not hit the back button. it may cause errors in your order</p>
        </div>
      </div>

      <div className="container">
        <div className="header">
          <h1 className="heading-h1">Congratulations, {username}!</h1>
          <h2 className="heading-h2">
            While we are preparing your full personalised numerology report,
            quickly take advantage of my incredible
            <br />
            <span className="color-pink">one-time-only VIP special offer</span>
            ...
          </h2>
        </div>
        <div className="blueprint-with-text">
          <div className="image-wrapper">
            <img src={BlueprintImage} alt="blueprint" />
          </div>
          <div className="text-body">
            Hi {username}, John here and congratulations on joining the Mystic
            Numbers family.
            <br />
            <br />
            Your full personalised report is being prepared but before I take
            you there I want to welcome you with an{' '}
            <span className="color-white fw-bold">
              exclusive opportunity
            </span>{' '}
            that transformed my life and{' '}
            <span className="color-white fw-bold">can transform yours too</span>
            .
            <br />
            <br />
            See, when I first learned about numerology I also discovered a
            little-known wealth secret that most people don’t know about..
            <br />
            <br />A secret so powerful that when combined with my numerology
            reports my clients referred to this combination as the ‘
            <span className="color-white fw-bold">Holy Grail</span>’ because
            their lives made a startlingly fast turnaround.
            <br />
            <br />
            {username}, during your brief reading we touched on the subject of
            money and how you would like to improve on this aspect of your life.
            <br />
            <br />
            Whether you’re looking to{' '}
            <span className="color-white fw-bold">
              build generational wealth, upgrade your lifestyle, live out your
              jet-setting fantasies, or simply never have to stress about money
              again
            </span>{' '}
            there is one thing that has worked for me and my clients time and
            time again….
          </div>
        </div>
        <div className="power-of-manifest">
          <h1 className="heading-h1">The Power Of Manifestation!!!</h1>
          <div className="image-and-text">
            <img src={ProfileImage} alt="profile" />
            <div className="text-body">
              Manifestation is an immensely powerful tool that can help get you
              there.
              <br />
              <br />
              It can transform your thoughts and desires into real,
              life-changing results,{' '}
              <span className="color-white fw-bold">
                opening the doors to limitless abundance and success.
              </span>
              <br />
              <br />
              It might sound crazy but I can assure you{' '}
              <span className="color-white fw-bold">it’s true!</span>
            </div>
          </div>
          <div className="text-body">
            The universe is governed by a set of universal laws; these laws
            cannot be changed, cannot be broken and apply to every individual,
            regardless of age or nationality.
            <br />
            <br />
            These laws are the riverbanks which guide the flow of their lives on
            its journey to its ultimate end.
            <br />
            <br />
            The law of attraction is one such law. The law of attraction is the
            belief that anyone can determine{' '}
            <span className="color-white fw-bold">
              their destiny through the power of their minds.
            </span>
          </div>
        </div>
        <div className="law-of-attraction">
          <h2 className="heading-h2">
            The Law of Attraction attracts to you everything you need, according
            to the nature of your thoughts
          </h2>
          <p className="heading-h2">
            Using manifestation and the law of attraction, you can bring your
            financial goals to life!
          </p>
          <div className="quote-box">
            <div className="left-col">
              <div className="text-body">
                But, don’t go it alone….
                <br />
                <br />
                The more you know about these laws the easier it is to navigate
                the energy you need in the right direction.
              </div>
              <div className="text-body">
                No wonder that even stars like{' '}
                <i>Will Smith, Jim Carrey, Oprah Winfrey</i>, and{' '}
                <i>Lady Gaga</i> swear by manifestation as the secret to their
                success.
              </div>
            </div>
            <div className="right-col">
              <div className="text-body">
                <br />
                <br />
                Jim Carrey makes it sound really simple. He says:
                <br />
                <br />
                <i>
                  “As far as I can tell, it’s just about letting the universe
                  know what you want and then working towards it while letting
                  go of how it comes to pass.”
                </i>
              </div>
            </div>
          </div>
          <div className="bg-image-box">
            <div className="text-body">
              Yet, there are reasons why some people are better at manifesting
              than others.
              <br />
              <br />
              There are a couple of fundamentals you need to understand in order
              to apply manifestation effectively.
              <br />
              <br />
              <strong>
                With a little guidance and the right techniques, your best life
                is waiting for you:
              </strong>
            </div>
          </div>
        </div>
        <div className="my-ultimate">
          <h2 className="heading-h2">
            Get My Ultimate Manifestation Blueprint
          </h2>
          <div className="letter-box">
            <div className="box-content">
              <div className="discount">
                <div className="was-price">
                  <p>
                    <s>
                      Was &nbsp;<span className="color-pink">$299</span>
                    </s>
                  </p>
                  <p>Today only</p>
                </div>
                <div className="today-price">$97</div>
              </div>
              <p className="one-time-fee">One TiME Fee - NO Subscription</p>
              <img className="cards-image" src={CardsImage} alt="cards" />
              <a href={upgardeMyOrderLink}>
                <div className="upgrade-btn">
                  Yes {username}, Upgrade My Order
                </div>
              </a>
            </div>
            <div className="image-wrapper">
              <img src={BlueprintImage} alt="blueprint" />
            </div>
            <div className="no-thanks">
              <a href={defaultOrderLink}>
                No thanks John I want to pass on this amazing offer and go it
                alone
              </a>
            </div>
          </div>
        </div>
        <div className="stop-scrolling-box">
          <div className="text-body">
            Stop scrolling through reels of places you want to go and start
            living it.
            <br />
            <br />
            Start living the life you want without money getting in the way!
            <br />
            <br />
            In no time, manifestation will have you smiling all the way to the
            bank.
          </div>
        </div>
        <div className="ultimate-manifestation-guide">
          <h2 className="heading-h2 medium-italic">
            This ultimate manifestation guide is a step-by-step handbook
            designed to empower you to manifest wealth, attract abundance, and
            create a life of fulfilment and prosperity.
          </h2>
          <div className="cards-list guides">
            {ultimateManifestationGuides.map((guide, i) => (
              <div className="card" key={i}>
                <img src={CheckMarkSVG} alt="check-mark" />
                <p className="card-name">{guide.name}</p>
                <p className="card-text">{guide.text}</p>
              </div>
            ))}
          </div>
          <div className="cards-list testimonial">
            {testimonialsData.map((item, i) => (
              <div className="card" key={i}>
                <p className="card-name">{item.name}</p>
                <p className="card-text">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="my-ultimate">
          <h2 className="heading-h2 medium-italic">
            Don’t just dream of financial freedom — live it! Act now and embrace
            the power to shape your financial destiny.
            <br />
            Don’t wait for this introductory price to disappear Click the link
            below now
          </h2>
          <div className="arrow-down">
            <img src={ArrowDownImage} alt="arrow down" />
          </div>
          <div className="letter-box">
            <div className="box-content">
              <div className="discount">
                <div className="was-price">
                  <p>
                    <s>
                      Was &nbsp;<span className="color-pink">$299</span>
                    </s>
                  </p>
                  <p>Today only</p>
                </div>
                <div className="today-price">$97</div>
              </div>
              <p className="one-time-fee">One TiME Fee - NO Subscription</p>
              <img className="cards-image" src={CardsImage} alt="cards" />
              <a href={upgardeMyOrderLink}>
                <div className="upgrade-btn">
                  Yes {username}, Upgrade My Order
                </div>
              </a>
            </div>
            <div className="image-wrapper">
              <img src={BlueprintImage} alt="blueprint" />
            </div>
            <div className="no-thanks">
              <a href={defaultOrderLink}>
                No thanks John I want to pass on this amazing offer and go it
                alone
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upsell;
