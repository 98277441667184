import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AllMonthNames,
  QuizBirthdayStepNames,
} from '../../../common/constants';
import DayStep from './components/day-step/DayStep';
import DecadeStep from './components/decade-step/DecadeStep';
import MonthStep from './components/month-step/MonthStep';
import YearStep from './components/year-step/YearStep';
import './QuizBirthday.scss';

const QuizBirthday = ({ onComplete }) => {
  const [quizData, setQuizData] = useState({
    step: 'decade', // 'decade', 'year', 'month', 'day'
    data: {
      decade: 0,
      year: 0,
      month: '',
      day: 0,
    },
  });
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (isCompleted) {
      setTimeout(() => {
        onComplete({
          ...quizData.data,
          monthNumber:
            AllMonthNames.findIndex((name) => name === quizData.data.month) + 1,
        });
      }, 500);
    }
  }, [isCompleted, onComplete, quizData]);

  const gotoNextStep = useCallback(
    (step, value) => {
      const currentStepIndex = QuizBirthdayStepNames.findIndex(
        (name) => name === step
      );

      if (step === 'day') {
        setIsCompleted(true);
      }

      if (currentStepIndex >= 0) {
        setQuizData({
          ...quizData,
          step: QuizBirthdayStepNames[currentStepIndex + 1],
          data: {
            ...quizData.data,
            [step]: value,
          },
        });
      }
    },
    [quizData]
  );

  const QuizBoxContainer = useMemo(() => {
    const QuizStepComponents = {
      decade: DecadeStep,
      year: YearStep,
      month: MonthStep,
      day: DayStep,
    };

    return QuizBirthdayStepNames.map((step) => {
      const StepComponent = QuizStepComponents[step];
      return (
        <StepComponent
          key={step}
          className={`${quizData.step === step ? 'visible' : 'invisible'}`}
          data={quizData.data}
          onSelect={(value) => gotoNextStep(step, value)}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizData]);

  return (
    <div className={`quiz-birthday ${isCompleted ? 'hidden' : ''}`}>
      <div className="quiz-birthday__header">
        <p className="h1">
          Find Out What The Hidden Secrets Of Numerology Reveal About You
        </p>
      </div>

      <div className="quiz-birthday__content box__wrapper">
        <div className="box__inner">{QuizBoxContainer}</div>
      </div>
    </div>
  );
};

export default QuizBirthday;
