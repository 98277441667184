import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { covertTextToSpeech } from '../../../../../api/api';
import VoiceCaptionBox from '../audio-caption-box/AudioCaptionBox';
import { useLifePathNumberAudioData } from './useLifePathNumberAudioData';

const LifePathNumberAudio = ({
  lifePathNumber,
  name,
  readingStep,
  onFinish,
}) => {
  const [nameAudio, setNameAudio] = useState(null);
  const [scriptAudio, setScriptAudio] = useState(null);
  const [audioPaused, setAudioPaused] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [canPlayNameAudio, setCanPlayNameAudio] = useState(false);
  const [canPlayScriptAudio, setCanPlayScriptAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const {
    caption,
    isFinished,
    isStarted,
    scriptAudioSrc,
    startTimer,
    stopTimer,
  } = useLifePathNumberAudioData({
    lifePathNumber,
    name,
  });

  useEffect(() => {
    if (name && scriptAudioSrc) {
      setTimeout(() => loadAudios(), 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, scriptAudioSrc]);

  const loadAudios = async () => {
    const scriptAudioObj = new Howl({
      src: [scriptAudioSrc],
      html5: true,
      format: 'mp3',
      onload: () => setCanPlayScriptAudio(true),
    });
    setScriptAudio(scriptAudioObj);

    const nameSpeech = await covertTextToSpeech(name);
    const nameAudioObj = new Howl({
      src: [nameSpeech],
      html5: true,
      format: 'mp3',
      onload: () => setCanPlayNameAudio(true),
      onplay: () => setIsPlaying(true),
    });
    setNameAudio(nameAudioObj);
  };

  useEffect(() => {
    if (
      readingStep === 'life-path-number' &&
      nameAudio &&
      scriptAudio &&
      canPlayNameAudio &&
      canPlayScriptAudio &&
      !isPlaying
    ) {
      startPlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scriptAudio,
    readingStep,
    nameAudio,
    canPlayNameAudio,
    canPlayScriptAudio,
    isPlaying,
  ]);

  useEffect(() => {
    if (isFinished) {
      stopPlayer();
      nameAudio.unload();
      scriptAudio.unload();
      onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished, nameAudio, scriptAudio]);

  const startPlayer = async () => {
    startTimer();

    if (audioPaused) {
      scriptAudio.play();
      setAudioPaused(false);
    } else {
      nameAudio.play();

      setTimeout(() => scriptAudio.play(), 1000);
    }
  };

  const stopPlayer = () => {
    if (scriptAudio) {
      scriptAudio.pause();
      setAudioPaused(true);
    }
    stopTimer();
  };

  const handleControlVolumn = (isMuted) => {
    scriptAudio.mute(isMuted);
    setAudioMuted(isMuted);
  };

  return (
    <VoiceCaptionBox
      caption={caption}
      isFinished={isFinished}
      isMuted={audioMuted}
      isPaused={audioPaused}
      isStarted={isStarted}
      onControlClicked={audioPaused ? startPlayer : stopPlayer}
      onVolumeClicked={() => handleControlVolumn(!audioMuted)}
    />
  );
};

export default LifePathNumberAudio;
