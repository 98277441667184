import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import LockIcon from '../../../assets/images/icon_lock.svg';
import LogoIcon from '../../../assets/images/icon_logo.svg';
import MonkeyBackIcon from '../../../assets/images/money-back-guarantee-icon.png';
import PhotoImage from '../../../assets/images/photo.png';
import PDFCoverImage from '../../../assets/images/pdf-cover.png';
import { PaymentLogos } from '../../common/constants';
import Helper from '../../common/helper';
import NumerologyDocument from '../../pdf/numerology-document/NumerologyDocument';
import './Order.scss';

const Order = ({ personalData }) => {
  const navigate = useNavigate();
  const readingNumbers = useSelector((state) => state.reading.readingNumbers);
  const userData = useSelector((state) => state.auth.userData);
  const token = localStorage.getItem('mystic-numbers-token');

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    if (!personalData && !token) {
      navigate('/sign-in');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalData, token]);

  const readingData = useMemo(() => {
    let tmpReadingData = readingNumbers;
    if (!tmpReadingData) {
      tmpReadingData = localStorage.getItem('mystic-numbers-reading');
      if (tmpReadingData) {
        tmpReadingData = JSON.parse(tmpReadingData);
      } else {
        navigate('/');
      }
    }
    return tmpReadingData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readingNumbers]);

  const clickBankURL = useMemo(() => {
    if (readingData?.fullName && (personalData?.email || userData?.email)) {
      return `https://mysticnumb.pay.clickbank.net/?cbitems=1&cbskin=43542&cbfid=55149&name=${
        readingData.fullName
      }&email=${personalData?.email || userData?.email}`;
    }
    return 'https://mysticnumb.pay.clickbank.net/?cbitems=1&cbskin=43542&cbfid=55149';
  }, [personalData, readingData, userData]);

  const calculatedNumbers = useMemo(() => {
    return readingData ? Helper.calculateNumerologyNumbers(readingData) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readingData]);

  if (userData && userData.hasPaid && calculatedNumbers) {
    return (
      <div className="page-members-area">
        <div className="box__wrapper">
          <div className="pdf-list-item">
            <div className="pdf-cover">
              <img src={PDFCoverImage} alt="pdf cover" />
              <div className="pdf-cover-inner">
                <div className="logo">
                  <img src={LogoIcon} alt="logo" />
                </div>
                <p className="title style-1">Numerology</p>
                <p className="title style-2">Blueprint For</p>
                <p className="title style-3">
                  {personalData?.name || userData.firstName || 'Name'}
                </p>
                <div className="pdf-cover-bottom">
                  An in depth numerology report of <br /> the 8 core elements in
                  your chart covering relationships, money, love, your lifes
                  purpose and much more
                </div>
              </div>
            </div>
            <div className="box__inner">
              <PDFDownloadLink
                document={
                  <NumerologyDocument
                    data={{
                      name: userData.firstName,
                      lifePathNumber: calculatedNumbers.lifePathNumber,
                      birthDayNumber: calculatedNumbers.day,
                      soulUrgeNumber: calculatedNumbers.soulUrgeNumber,
                      personalityNumber: calculatedNumbers.personalityNumber,
                      loveNumber: calculatedNumbers.loveNumber,
                      destinyNumber: calculatedNumbers.destinyNumber,
                      nameNumber: calculatedNumbers.nameNumber,
                      planetNumber: calculatedNumbers.planetNumber,
                    }}
                  />
                }
                fileName={`${userData.firstName}_full_numerology_blueprint.pdf`}
                className="btn-gradient btn-download"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    'Report loading...'
                  ) : isMobile ? (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      Report ready to download
                    </a>
                  ) : (
                    'Report ready to download'
                  )
                }
              </PDFDownloadLink>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-order">
      <div className="box__wrapper">
        <div className="box__header">
          <p className="header-desc">
            Click the button below to get your personalised numerology blueprint
            for only{' '}
            <span className="origin-price">
              <strike>$49.00</strike>
            </span>{' '}
            <span className="save-price">$15</span>
          </p>
        </div>

        <div className="box__content">
          <div className="content-section">
            <div className="mobile-visible">
              <a className="btn-gradient btn-order-now" href={clickBankURL}>
                Order Now
              </a>
            </div>
            <div className="pdf-cover">
              <img src={PDFCoverImage} alt="pdf cover" />
              <div className="pdf-cover-inner">
                <div className="logo">
                  <img src={LogoIcon} alt="logo" />
                </div>
                <p className="title style-1">Numerology</p>
                <p className="title style-2">Blueprint For</p>
                <p className="title style-3">
                  {personalData?.name || userData?.firstName || 'Name'}
                </p>
                <div className="pdf-cover-bottom">
                  An in depth numerology report of <br /> the 8 core elements in
                  your chart covering relationships, money, love, your lifes
                  purpose and much more
                </div>
              </div>
            </div>
            <div className="order-block">
              <a className="btn-gradient btn-order-now" href={clickBankURL}>
                Order Now
              </a>
              <div className="lock-desc">
                <img src={LockIcon} alt="lock" />
                <p>All orders are processed on a secure server</p>
              </div>
              <div className="payment-logos">
                {PaymentLogos.map(({ name, src }) => (
                  <img key={name} src={src} alt={name} />
                ))}
              </div>
              <p className="sub-title">
                Thousands of people have already ordered their personalised
                numerology blueprint
              </p>
              <div className="refer-box">
                <img src={PhotoImage} alt="" />
                <p>
                  I was amazed by the accuracy of my reading. The level of
                  detail my report goes into is really impressive. I must say
                  it's had a profound effect on me and helped me seriously level
                  up in every aspect of my life.
                </p>
              </div>
            </div>
          </div>

          <div className="footer-section">
            <img src={MonkeyBackIcon} alt="Monkey Back" />
            <p>
              We're certain that you'll love your numerology blueprint. But If
              for any reason you aren't fully satisfied, just send us a quick
              email within 60 days and we'll refund your purchase, no questions
              asked
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
