import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes as Switch, useLocation } from 'react-router-dom';
import BackgroundAnimation from './sections/background-animation/BackgroundAnimation';
import BackgroundImages from './sections/background-images/BackgroundImages';
import Footer from './sections/footer/Footer';
import Header from './sections/header/Header';
import AffiliatesPage from './pages/affiliates/Affiliates';
import MembersPage from './pages/members/Members';
import OrderPage from './pages/order/Order';
import PDFPreviewPage from './pages/pdf-preview/PDFPreview';
import PrivacyPolicyPage from './pages/privacy-policy/PrivacyPolicy';
import ReadingPage from './pages/reading/Reading';
import SignInPage from './pages/signin/SignIn';
import TermsConditionsPage from './pages/terms-conditions/TermsConditions';
import ThankYouPage from './pages/thank-you/ThankYou';
import Upsell from './pages/upsell/Upsell';
import { authSessionLogin } from './redux/slices/AuthSlice';
import { updateReadingNumbers } from './redux/slices/ReadingSlice';
import './App.scss';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { userData } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(authSessionLogin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData?.lastReadingNumbers) {
      dispatch(updateReadingNumbers(userData.lastReadingNumbers));
    }
  }, [dispatch, userData]);

  const { pathname } = useMemo(() => {
    return {
      pathname: location.pathname.split('/')[1],
    };
  }, [location]);

  return (
    <div className="App">
      <BackgroundImages />
      <BackgroundAnimation />

      <Header />

      <div className={`App__page ${pathname}`}>
        <Switch>
          <Route path="/" element={<ReadingPage />} />
          <Route path="/affiliates" element={<AffiliatesPage />} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/members-area" element={<OrderPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-conditions" element={<TermsConditionsPage />} />
          <Route path="/thankyou" element={<ThankYouPage />} />
          <Route path="/new-message-1" element={<Upsell />} />
          <Route path="/pdf-preview" element={<PDFPreviewPage />} />
          {/* Admin */}
          <Route path="/members" element={<MembersPage />} />
        </Switch>

        <Footer />
      </div>

      <div className="clickbank-note">
        <p>
          ClickBank is the retailer of products on this site. CLICKBANK® is a
          registered trademark of Click Sales Inc., a Delaware corporation
          located at 1444 S. Entertainment Ave., Suite 410 Boise, ID 83709, USA
          and used by permission. ClickBank's role as retailer does not
          constitute an endorsement, approval or review of these products or any
          claim, statement or opinion used in promotion of these products.
        </p>
      </div>
    </div>
  );
}

export default App;
