import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Howl } from 'howler';
import { S3UploadedAudios } from '../../common/constants';
import {
  addSubscriberToSendlane,
  updateReadingStep,
} from '../../redux/slices/ReadingSlice';
import AudioCaption from './audio-caption/AudioCaption';
import DestinyNumberCalculation from './destiny-number-calculation/DestinyNumberCalculation';
import DestinyNumberReport from './destiny-number/DestinyNumber';
import LifePathNumberCalculation from './life-path-number-calculation/LifePathNumberCalculation';
import LifePathNumberReport from './life-path-number/LifePathNumber';
import OutroOrder from './outro-order/OutroOrder';
import QuizBirthday from './quiz-birthday/QuizBirthday';
import QuizFullName from './quiz-full-name/QuizFullName';
import QuizName from './quiz-name/QuizName';
import './Reading.scss';

const Reading = () => {
  // 'quiz-birthday', 'quiz-name', life-path-number-calculation, 'life-path-number', 'quiz-full-name',
  // 'destiny-number-calculation', 'destiny-number', 'outro-order'
  const [bgMusicAudio, setBGMusicAudio] = useState(null);
  const [readingStep, setReadingStep] = useState('quiz-birthday');
  const [personalData, setPersonalData] = useState(null);
  const [finishedReport, setFinishedReport] = useState('');
  const [canPlayAudio, setCanPlayAudio] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // pre-load audios
    playBGMusicAudio(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(updateReadingStep(readingStep));

    if (readingStep === 'life-path-number-calculation' && bgMusicAudio) {
      bgMusicAudio.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bgMusicAudio, readingStep]);

  const playBGMusicAudio = (autoPlay = true) => {
    const audio = new Howl({
      src: [S3UploadedAudios.BACKGROUND],
      volume: 0.2,
      html5: true,
      onend: () => playBGMusicAudio(),
    });

    if (autoPlay) {
      audio.play();
    } else {
      setBGMusicAudio(audio);
    }
  };

  const handleAudioCanPlay = () => {
    setCanPlayAudio(true);
  };

  const handleFinishReporting = (aspect) => {
    let nextStep = {
      'life-path-number': 'quiz-full-name',
      'destiny-number': 'outro-order',
    }[aspect];

    setFinishedReport(aspect);

    setTimeout(() => {
      setFinishedReport('');
      setReadingStep(nextStep);
    }, 1000);
  };

  const renderCurrentReadingContainer = useMemo(() => {
    return {
      'quiz-birthday': (
        <QuizBirthday
          onComplete={(data) => {
            setPersonalData(data);
            setReadingStep('quiz-name');
          }}
        />
      ),
      'quiz-name': (
        <QuizName
          onStart={(name) => {
            setPersonalData({
              ...personalData,
              name,
            });
            setReadingStep('life-path-number-calculation');
          }}
        />
      ),
      'life-path-number-calculation': (
        <LifePathNumberCalculation
          data={personalData}
          canPlayAudio={canPlayAudio}
          onComplete={() => setReadingStep('life-path-number')}
          onLifePathNumberCalculated={(lifePathNumber) => {
            setPersonalData({
              ...personalData,
              lifePathNumber,
            });
          }}
        />
      ),
      'life-path-number': (
        <LifePathNumberReport
          lifePathNumber={personalData?.lifePathNumber || 0}
          isFinished={finishedReport === 'life-path-number'}
        />
      ),
      'quiz-full-name': (
        <QuizFullName
          name={personalData?.name || ''}
          onComplete={({ fullName, email }) => {
            setPersonalData({
              ...personalData,
              fullName,
              email,
            });
            setReadingStep('destiny-number-calculation');
            dispatch(
              addSubscriberToSendlane({
                email,
                first_name: personalData.name,
                last_name: fullName.replace(personalData.name, ''),
              })
            );
          }}
        />
      ),
      'destiny-number-calculation': (
        <DestinyNumberCalculation
          fullName={personalData?.fullName || ''}
          onComplete={() => setReadingStep('destiny-number')}
          onDestinyNumberCalculated={(destinyNumber) => {
            setPersonalData({
              ...personalData,
              destinyNumber,
            });
            localStorage.setItem(
              'mystic-numbers-reading',
              JSON.stringify({
                ...personalData,
                destinyNumber,
              })
            );
          }}
        />
      ),
      'destiny-number': (
        <DestinyNumberReport
          destinyNumber={personalData?.destinyNumber || 0}
          isFinished={finishedReport === 'destiny-number'}
        />
      ),
      'outro-order': <OutroOrder personalData={personalData} />,
    }[readingStep];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canPlayAudio, finishedReport, personalData, readingStep]);

  return (
    <>
      {renderCurrentReadingContainer}

      <div className="audio-caption-wrapper">
        {personalData && (
          <AudioCaption
            readingStep={readingStep}
            personalData={personalData}
            onCanPlay={handleAudioCanPlay}
            onFinish={handleFinishReporting}
          />
        )}
      </div>
    </>
  );
};

export default Reading;
