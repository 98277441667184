export const PlanetNumberScript = {
  1: [
    "Your planet number is 1. In numerology, the first planet is the sun. It's the king of other planets. No planet can come too close to the sun, or else it'll burn under the sun's heat. I'm pointing out these things so you understand the extent of your power and mission in this world. As a representative of planet number 1, you're not meant to be hidden under a bushel. Don't be satisfied with living for yourself or be content with a minimally good life. ",
    "Like the sun, you're scorchy. You have a boiling temper and a short fuse, so it doesn't take much to blow you off. While this could save you the stress of being played for a fool or insulted, it also makes you command more fear than respect. People obey you without question, not because they genuinely like your orders but because they fear your wrath. ",
    "You may have landed in trouble on some occasions when your hot temper made you hit someone or say something that you later regretted. While you can't directly quench your hot temper, you can walk away from the scene when you notice your temper beginning to flare.",
    "You're as bold as a lion and crafty like a tortoise. You don't turn your back on any challenge, no matter how mighty. At first, you may butt the problem with force, but when you notice it isn't budging, you can switch to using honey-dipped words to trap your target. ",
    "You may not be the safest friend, but you're among the best. You root for your friends no matter what. While you won't spare them from your wrath if they offend you, you'll keep them warm, like the sun. You'll keep them happy. If you're more well off than they're, you'll give them some of your resources to help them gain their footing. ",
    "However, your enemies are in for a brutal ride. You show no mercy in dealing with those who want to frustrate your peace, as you dislike compromise. You're someone who would rather meet the fiercest of storms head-on than bend to their whirl.",
    "In your relationships, you assume the lead rather naturally, guiding most interactions. There's also an irresistible charm to you that sends ripples of fascination through those around you. But there's something else about you that stands out. It's how amidst shades of grey, you're a vibrant burst of sunshine.",
  ],
  2: [
    "Your planet number is 2. The second planet in numerology is the moon, which embodies feminine, caring, and nurturing qualities. With the moon as your planetary symbol, you're gifted with a merciful heart. Your mind is watery, like the deep seas, cool and calm and void of trouble. You don't like being bothered or bothering others, and you thrive on the symphony of peace. ",
    "You love love. Your love is somewhat universal, extending to everything and everyone, like an antidote to the so much hatred that abounds everywhere. Your hand is always held out to anyone in the ditch, ready to pull them out and make them whole. Like the moon, which reflects the softened version of the light of the sun, you make an excellent complement to authoritative figures. You'll do well as the aide of political figures, an assistant manager, or the deputy leader of an organization or initiative. ",
    "Your presence is like rainwater, watering the talents of others. Sharing dreams with you is one sure way to get them realized because sometimes, you're the one person that provides the encouragement people need to go ahead with their aspirations. The world won't be a happy place without you. You don't have to look too far to recognize your talents. There's one in you that's so prominent that it can't be hidden, and that's your instinctive veering away from your personal goals to look after the interests of others. ",
    "You play a significant role in multiplication and reproduction. I don't mean that your role is to give birth to millions of babies, but that you're exceptional at causing good things to replicate in others. When you're very skilled at doing a particular thing, you don't hoard your expertise. You impart the knowledge you've gained along the way into up and coming people in your field, and they understand. You occupy the slot of a teacher. You may not crave much greatness or popularity for yourself, but you'll go the extra mile to ensure that those coming after you become the movers and shakers of their generation. ",
    "You have a deep mind, as deep as the ocean waves. The same way you can harbor a lot of compassion is the same way you can harbor a lot of malice. But the thing about you keeping grudges is that you can't muster enough wickedness to take revenge on the person who offended you. And that's what makes you, you.",
  ],
  3: [
    "Your planet number is 3. The third planet in numerology is Jupiter, which is the planet of optimism. It doesn't matter what you're going through or have gone through. You fix your gaze on the light at the end of the tunnel, even when there's no obvious reason to hope for better days.",
    "Being around you brings joy to people, because you're so effortlessly cheerful. You find happiness in things that often go unnoticed, like sunrise and sunset, good food, and loyal friends. You're the agent of laughter. There's hardly any sad moment in your company. You find it easy to compliment people because you genuinely see the best in them.",
    "But your public behavior is usually a mask for your private feelings. While you're vibrant and energetic in open spaces, you morph into almost the exact opposite behind closed doors. You don't want anyone to see you cry so that your tears don't break the illusion of you being constantly happy. You feel as if your happiness is a duty that you're bound to perform, so you try to resist any dark emotions from taking over your mind. As a result, no one can detect when you're experiencing any difficulties, and you may not share your problems with anyone until they've escalated beyond your control.",
    "You don't hold grudges against people, no matter how much their actions grieved your soul. You feel that life is too short to be spent keeping malice, so you just let go. Although your ability to forgive easily endears more people to you, it also makes those people take advantage of your forgiving spirit. Once you sense a conflict brewing between you and someone, you quickly distance yourself from them. This could also mean that you'd rather run away from your challenges than tackle them head-on.",
    "I can picture you in a room filled with all your friends, huddled together, planning the next big adventure. You crave for these moments, these exhilarating trips where you can just let go and be one with the world. But you don't live in this world as much as you live in your thoughts. Events unravel around you, and from your headspace, you choose the side you wish to see.",
    "When it comes to deciding, a task that's second nature to some, you find yourself at crossroads. Time can slip right through your hands and before you know it, you're undecided. More often than not, you sway with the winds of others' opinions. It's the company you keep that influences your decisions. Whatever you do, keep good company.",
  ],
  4: [
    "Your planet number is 4. The fourth planet in numerology is Uranus, the planet of innovation and rebellion. As an owner of planet number 4, you reason far and wide and often conclude that life will be better for everyone if they don't have to live by the current rules.",
    "While you admire and adhere to certain traditions in some facets of your life, like love and marriage, you oppose the status quo in most other areas. But you don't just sit down and criticize what you feel needs to be criticized. You rack your brain and put your hands to work. Then you develop new, more effective methods of achieving set objectives. The objective may be the maintenance of social order, national security, or the performance of particular tasks at work.",
    'When assuming a new position of leadership, the first thing you want to do is scrape all the policies of the past government. However, your insistence on overturning traditions in favor of fresh strategies usually prevents continuity in government and leadership. You may also fall prey to greedy fellows. They can use your stubborn spirit to overthrow the current leaders and their rules. Later, they betray you by enacting rules far worse than the ones you fought against.',
    "People don't gather around you much because they fear your revolutionary nature. So you often chase them, trying to make your reasoning appeal to them so they support your agendas. You loudly voice your aspirations, giving you the impetus to go after them with a club.",
    "You're always on the go, spreading the word about your skills and achievements, seeking your next breakthrough. You happily sacrifice temporary desires for long-term comforts. As more people get to know you, you develop a magnetic personality, not in the sense of drawing people closer to you, but in keeping them close to you. You believe everyone can be of use to you tomorrow, so you don't cut off people from your life unless it's essential.",
    "Although you do your job with all the zeal you can muster, you may not be entirely pleased with everything about it. This is why you don't get tired of speaking out against the management.",
    "There's just one issue with having a strong drive for positive change. It might lead you to feel like your entire existence revolves around the realization of the revolution you desire. As a result, when your goals aren't met, you struggle with dark emotions, such as depression and cynicism.",
  ],
  5: [
    "Your planet number is 5. You're ruled by Mercury, which is the fifth planet in numerology. For you, the most vital accomplishment is the satisfaction of physical pleasure. You prefer setting short-term goals and dipping your money into investments that yield massive returns within a shorter period.",
    "Mercury is also among the youngest planets, so you have the curiosity of a child. You want to have a bit of everything you see, whether it's food, clothes, gadgets, or even people. You're easily encouraged and easily discouraged. You do so many things quickly, as you can jump from here to there without counting the costs or worrying about the possibility of regretting your actions.",
    "Your nimbleness often lends itself to easily being swayed to participate in groups or join project teams. You dive right in, seemingly powered by a belief in your ability to conquer the tasks at hand. Remember, though, while audacity can be an asset, it's also essential to pause, reflect, and make informed decisions.",
    "You think fast and act faster. The reason why you're able to be so spontaneous is that you're more concerned about making the most of the present. When you table your ideas for developing an initiative before people, the ideas sound refreshing and invigorating. They make your listeners want to team up with you.",
    "You're the best fit for the growth department in organizations. You can keep up with new trends and tweak the organization's approach to dealing with customers to suit those new trends. You'll also do well working in any constantly evolving field, like information technology.",
    "You like having money and being with people that have money. As you do anything to get money for yourself, you aim for wealthy friends who're as lenient with the way they spend their money as you are.",
    "You're adorable when you want to be, and you easily win the hearts of everyone around you, including your enemies. But as lovely as this trait is, it can't ensure that everything will always go smoothly for you.",
    "Trust doesn't come easily to you. You often perceive others as obstacles on your path to success. This makes placing trust in anyone a big deal. When you let someone in, they can lead you by the nose to wherever they see fit. This can be a strength as much as a vulnerability. Hold onto your sense of discernment. Use it wisely, and it can lead you to wonderful places.",
  ],
  6: [
    "Your planet number is 6. In numerology, the sixth planet is Venus, which governs love, sensuality, and beauty. I do not doubt that you're lovely in all the ways common to your gender. You're also particular about how you look, so you put much effort into caring for your skin, updating your wardrobe, and stepping out in style.",
    "You have an eye for physical attractiveness, so your first criterion for choosing a partner is that they must appear stunning on your arm. You love luxury scents and may even plant flowers that emit beautiful fragrances or put scented candles within your home. Because of your sensitivity to smell, you'll also take special care to apply the most romantic perfumes and deodorants.",
    "The result of grooming your body so much is that you look ravishingly attractive. Heads turn when you walk, and people can't resist admiring your elegance. But since you know how charming you're, you may slack on your work and expect your beauty to make crooked paths straight before you.",
    "You have an easy life ahead of you. You get what others struggle for without breaking a sweat because everything seems to fall in place in your favor magically. Although this experience is everyone's dream, it also has its setbacks. You let go of your possessions as quickly as you get them. This makes you generous to those around you but also extravagant. As the people praise your generosity, your wallet suffers. You experience frequent bouts of wealth followed by periods when you're very close to being broke.",
    "You fit well into different social settings. You're also good at flattery, and you use this skill to work your way into the top class of society. However, you don't aspire to become great for selfish reasons but to assist those needing your assistance.",
    "You're harmless as a dove, but sometimes, you narrow your sights on just yourself, make yourself the center of your world, and feel as if everyone should serve you. Thanks to your appreciation of beauty, you'll make a fine graphics designer, website developer, interior decorator, event planner, or even excel in the arts.",
    "You enjoy red and blue songs, slow dances, and heartfelt poetry. You're a very sweet person who's never rude to people unless they truly deserve it. However, your careless generosity can dig holes into your pockets if you're not more discreet with your finances.",
  ],
  7: [
    `Your planet number is 7. The seventh planet in numerological order is Neptune, the planet of mental and spiritual enlightenment. Instead of enriching your pockets, you enrich your mind with all the wisdom, knowledge, and understanding you can garner. You always seek to increase your knowledge through reading documented bits of wisdom published by those that came before you. You're not content with simply learning facts. You want to know the "why" and the "how" of everything that exists, from the tiniest bacterium to the tallest tree.`,
    "You're an impressive orator. You're not afraid of pointing out the pitfalls of those in authority, and they can't silence you because the people believe what you say. Before you open your mouth to speak, your brain has processed everything you want to say, so your speeches are so eloquent that they sway the audience effortlessly.",
    "But you don't pay close attention to your health. You're usually so busy enlightening yourself that you either lose or gain much weight because you don't work out or eat healthy. You also spend much of your time behind closed doors, and this deprives you of enjoying the fresh, beautiful air of nature.",
    "You work hard, but you seldom have anything to show for it. Since you don't care for material wealth, you don't take steps to ensure that the money you accumulate increases. As you age and your ability to work hard decreases, your earning power also decreases. And you won't be bothered because you feel that the wealth of your mind is more important than a fat wallet.",
    "Certainly, nobody's perfect, and you've got your fair share of imperfections too. However, beyond those faults that make you human, there's a beautiful aspect that outshines them all. It's your kind heart. Your kindness may not be the traditional sort, the kind that hands you a coat in a storm, but it's equally valuable in its own way.",
    'Your kindness takes the form of words. Not just any words, but those that have the power to comfort the sorrowful, easing the pain of loss with their quiet, empathetic whispers.',
    "Financial responsibilities scare you off sometimes because you've always preferred to work on your growth. There's no harm in that. Wanting to develop oneself is a natural and important pursuit. But imagine, just for a moment, if you were to shoulder such responsibilities. You would transform into a sturdy crutch that your loved ones could lean heavily on in their time of need.",
  ],
  8: [
    "Your planet number is 8. The eighth numerological planet is Saturn, the planet of material wealth and maturity. Your main concerns revolve around your savings and investments rather than the latest trends in movies, shopping, dining, or amusement. You're trapped in the mindset of an adult several times your actual age.",
    "Instead of living in the moment, you focus on the future, planning for what's to come in the next 10 or 20 years. You're not particularly interested in traveling the world, as you firmly believe that if you work hard enough, the world will eventually come to you.",
    "You have an exceptional level of discipline. Distractions like friends, romantic partners, parties, or sleep can't get between you and achieving your goals. You refuse to rest until you've beaten your targets, so anything you lay your hands on must succeed. You're self-made. Even when you come from a wealthy family, you'll still work extra hard to make your own money because you like accruing all credit for your success to yourself.",
    "I see you buying a home at a relatively young age. If you take student loans to finance your tertiary education, you'll likely pay them up much earlier than your peers. You're the kind of person that likes being financially stable before running into a relationship. I feel you won't date anyone seriously until you've gotten the level of wealth that you desire.",
    "You wear an icy mask behind which you conceal your true feelings. This makes you appear perpetually unamused, so people usually steer clear of you in social settings. While you are loyal to those who lent you a helping hand in your time of need, you expect absolute loyalty from them in return. You don't take kindly to someone in your inner circle, spilling your secrets or going behind your back to do something.",
    "You have the carriage of a fulfilled sage and detest running about partying, being drunk in public, or doing anything that'll make people think you're just like everyone else. You're prim and proper and almost dislike the concept of having fun.",
    'When in the company of a more adventurous friend, your hidden fun-loving side may emerge. Your determined nature and serious personality make you a prime candidate for leadership roles. As you take on such responsibilities, your actions will inspire those around you to adhere to the rules simply by demonstrating your commitment to upholding them.',
  ],
  9: [
    'Your planet number is 9. The ninth and last planet in numerology is Mars, which embodies defense and courage. When it comes to standing up for the underprivileged and ensuring their rights are not trampled upon, you are there at the forefront. ',
    "You're a one-person army without extraordinary confidence to challenge abuses of power and maladministration. People usually perceive you as the hero who always saves the day. Their elevated opinion of you makes you think so highly of yourself that you feel one else is qualified to steer the ship except you. ",
    "Instead of planning for events in advance, you simply wing them when the time comes. You dislike having contingency plans, backup savings, or emergency funds, as you believe so much in your ability to endure the worst situations without suffering any scars. And that's why you take risks. You treat life like a big gamble of power, throwing your cards on every table until one of those cards makes a hit. But contrary to what your foes expect, you manage to scale through the storm unscathed, perhaps securing many victories along the way. ",
    'You enjoy being your boss, not because you have innovative ideas that would turn your world around, but because you want the leeway to do your own thing. You like being original. You listen to your heart, filtering your natural desires from the sea of options available. This helps you stand out anywhere you are. ',
    "Sometimes, you might even dream about snapping your fingers and turning your ideas into thriving businesses instantly. But you forget that building stable ventures takes time. If you're a little more patient and persistent, you can achieve any goal you set.",
    "You seem to find great delight in diving into the unknown, like biting into a forbidden fruit with unbridled joy. You may break free from the customs of the people around you, choose a partner who isn't your family's ideal or embark on an academic journey your parents didn't initially approve of.",
    "As I see it, you're unafraid to venture into these territories, embracing the hidden sweetness life has to offer. You recognize that by exploring the forbidden, you stumble upon the enchanting side of life that others may shy away from.",
    'In choosing your path, you exhibit authenticity. So, as you continue pursuing the unexpected, never hesitate to share your discoveries with those around you. They can draw inspiration from your adventurous heart.',
  ],
};
