import DestinyNumberAudio from './components/destiny-number-audio/DestinyNumberAudio';
import EndOutroAudio from './components/end-outro-audio/EndOutroAudio';
import IntroDestinyNumberAudio from './components/intro-destiny-number-audio/IntroDestinyNumberAudio';
import LifePathNumberAudio from './components/life-path-number-audio/LifePathNumberAudio';
import QuizFullNameAudio from './components/quiz-full-name-audio/QuizFullNameAudio';
import StartIntroAudio from './components/start-intro-audio/StartIntroAudio';

const AudioCaption = ({ readingStep, personalData, onCanPlay, onFinish }) => {
  return (
    <>
      {!personalData.fullName && (
        <StartIntroAudio
          personalData={personalData}
          readingStep={readingStep}
          onCanPlay={onCanPlay}
        />
      )}
      {personalData.lifePathNumber && !personalData.destinyNumber && (
        <LifePathNumberAudio
          lifePathNumber={personalData.lifePathNumber}
          name={personalData.name}
          readingStep={readingStep}
          onFinish={() => onFinish(readingStep)}
        />
      )}
      {personalData.lifePathNumber && !personalData.destinyNumber && (
        <QuizFullNameAudio name={personalData.name} readingStep={readingStep} />
      )}
      {personalData.lifePathNumber && (
        <IntroDestinyNumberAudio
          name={personalData.name}
          readingStep={readingStep}
        />
      )}
      {personalData.destinyNumber && (
        <>
          <DestinyNumberAudio
            destinyNumber={personalData.destinyNumber}
            readingStep={readingStep}
            onFinish={() => onFinish(readingStep)}
          />
          <EndOutroAudio name={personalData.name} readingStep={readingStep} />
        </>
      )}
    </>
  );
};

export default AudioCaption;
