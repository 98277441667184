import { useEffect, useState } from 'react';
import { LifeNumbersScript } from '../../../../../common/life-numbers-script';

export const useQuizFullNameAudioData = ({ name, startPlay }) => {
  const [countSeconds, setCountSeconds] = useState(-1);
  const [currentCaption, setCurrentCaption] = useState('');
  const [intervalId, setIntervalId] = useState(undefined);

  useEffect(() => {
    if (startPlay) {
      startTimer();
    }
  }, [startPlay]);

  useEffect(() => {
    if (countSeconds > 0) {
      const nextBlock = LifeNumbersScript.end.find(
        (block) => block.time === countSeconds
      );
      if (nextBlock) {
        setCurrentCaption(nextBlock.text.replace('{name}', name));
        if (!nextBlock.text) {
          stopTimer();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countSeconds]);

  const startTimer = () => {
    const id = setInterval(() => setCountSeconds((old) => old + 0.5), 500);
    setIntervalId(id);
  };

  const stopTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  };

  return {
    caption: currentCaption,
    isFinished: countSeconds > 0 && !intervalId,
    isStarted: countSeconds >= -0.5,
  };
};
