import React from 'react';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import MontserratFontBlack from '../../../assets/fonts/Montserrat/Montserrat-Black.otf';
import MontserratFontExtraBold from '../../../assets/fonts/Montserrat/Montserrat-ExtraBold.otf';
import MontserratFontMedium from '../../../assets/fonts/Montserrat/Montserrat-Medium.otf';
import MontserratFontRegular from '../../../assets/fonts/Montserrat/Montserrat-Regular.otf';
import PoppinsFontRegular from '../../../assets/fonts/Poppins/Poppins-Regular.otf';
import PoppinsFontBold from '../../../assets/fonts/Poppins/Poppins-Bold.otf';
import FooterLogo from '../../../assets/images/numerology-pdf/footer-logo.png';
import Page1Image from '../../../assets/images/numerology-pdf/1.png';
import Page2Image from '../../../assets/images/numerology-pdf/2.png';
import Page3Image from '../../../assets/images/numerology-pdf/3.png';
import Page4Image from '../../../assets/images/numerology-pdf/4.png';
import Page6Image from '../../../assets/images/numerology-pdf/6.png';
import Page7Image from '../../../assets/images/numerology-pdf/7.png';
import Page8Image from '../../../assets/images/numerology-pdf/8.png';
import Page9Image from '../../../assets/images/numerology-pdf/9.png';
import Page10Image from '../../../assets/images/numerology-pdf/10.png';
import Page11Image from '../../../assets/images/numerology-pdf/11.png';
import Page12Image from '../../../assets/images/numerology-pdf/12.png';
import Page13Image from '../../../assets/images/numerology-pdf/13.png';
import Page14Image from '../../../assets/images/numerology-pdf/14.png';
import Page15Image from '../../../assets/images/numerology-pdf/15.png';
import Page16Image from '../../../assets/images/numerology-pdf/16.png';
import Page17Image from '../../../assets/images/numerology-pdf/17.png';
import Page18Image from '../../../assets/images/numerology-pdf/18.png';
import Page19Image from '../../../assets/images/numerology-pdf/19.png';
import Page20Image from '../../../assets/images/numerology-pdf/20.png';
import Page21Image from '../../../assets/images/numerology-pdf/21.png';
import Page22Image from '../../../assets/images/numerology-pdf/22.png';
import Page23Image from '../../../assets/images/numerology-pdf/23.png';
import Page25Image from '../../../assets/images/numerology-pdf/25.png';
import Page26Image from '../../../assets/images/numerology-pdf/26.png';
import Page27Image from '../../../assets/images/numerology-pdf/27.png';
import Page28Image from '../../../assets/images/numerology-pdf/28.png';
import Page29Image from '../../../assets/images/numerology-pdf/29.png';
import Page30Image from '../../../assets/images/numerology-pdf/30.png';
import Page32Image from '../../../assets/images/numerology-pdf/32.png';
import Page33Image from '../../../assets/images/numerology-pdf/33.png';
import { BirthDayNumberScript } from '../scripts/BirthDayNumber';
import { DestinyNumberScript } from '../scripts/DestinyNumber';
import { LifePathNumberScript } from '../scripts/LifePathNumber';
import { LoveNumberScript } from '../scripts/LoveNumber';
import { NameNumberScript } from '../scripts/NameNumber';
import { PersonalityNumberScript } from '../scripts/PersonalityNumber';
import { PlanetNumberScript } from '../scripts/PlanetNumber';
import { SoulUrgeNumberScript } from '../scripts/SoulUrgeNumber';
import styles from './NumerologyDocumentStyles';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: MontserratFontBlack,
      fontWeight: 900,
    },
    {
      src: MontserratFontExtraBold,
      fontWeight: 800,
    },
    {
      src: MontserratFontMedium,
      fontWeight: 600,
    },
    {
      src: MontserratFontRegular,
      fontWeight: 'normal',
    },
  ],
});

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: PoppinsFontRegular,
      fontWeight: 'normal',
    },
    {
      src: PoppinsFontBold,
      fontWeight: 700,
    },
  ],
});

const PageFooter = ({ pageNumber }) => (
  <View style={styles.pageFooter}>
    <Image style={styles.footerLogo} src={FooterLogo} alt="footer logo"></Image>
    <Text style={styles.pageNumberText}>{pageNumber}</Text>
  </View>
);

const NumerologyDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page1Image} alt="page 1"></Image>
      </View>
      <View style={styles.content}>
        <View style={styles.coverTitleSection}>
          <Text
            style={[
              styles.colorPink,
              styles.coverTitle,
              styles.fontWeightBlack,
            ]}
          >
            {data.name}
          </Text>
        </View>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page2Image} alt="page 2"></Image>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page3Image} alt="page 3"></Image>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page4Image} alt="page 4"></Image>
      </View>
      <View style={styles.content}>
        <Text style={styles.heading2}>Welcome To Your Full </Text>
        <Text style={[styles.heading2, styles.mb20]}>Numerology Blueprint</Text>
        <Text style={styles.bodyText}>
          Hi <Text style={styles.fontWeightBold}>{data.name}</Text> and welcome
          to the world of numbers and your personalised numerology blueprint – a
          realm where the seemingly ordinary digits that surround us hold
          extraordinary insights into our lives.
        </Text>
        <Text style={styles.bodyText}>
          Have you ever wondered why certain numbers seem to appear at
          significant moments? Or pondered whether there's a deeper meaning
          behind your birthdate and name?
        </Text>
        <Text style={[styles.bodyText, styles.mb20]}>
          Prepare to embark on a transformative journey as we unveil the
          mysteries of numerology and create a personalized roadmap just for you
          – your very own "Numerology Blueprint."
        </Text>
        <Text style={styles.heading4}>
          Decoding Destiny: The Essence of Numerology
        </Text>
        <Text style={styles.bodyText}>
          Numerology is more than just the study of numbers; it's a powerful
          tool that allows us to decipher the hidden codes that shape our
          existence. At its core, numerology operates on the belief that numbers
          possess a unique vibrational energy that resonates with the universe
          itself.
        </Text>
        <Text style={styles.bodyText}>
          This energy influences not only the events and circumstances we
          encounter but also the qualities and attributes that define us.
        </Text>
        <Text style={styles.bodyText}>
          Imagine each number as a key that unlocks a specific aspect of your
          life.{' '}
        </Text>
        <Text style={styles.bodyText}>
          By understanding these keys, we gain access to a wealth of information
          about our personality, strengths, challenges, and the journey that
          lies ahead.{' '}
        </Text>
        <Text style={[styles.bodyText, styles.mb20]}>
          Numerology is like a personal GPS for your soul, guiding you toward a
          deeper comprehension of your purpose and potential.
        </Text>
        <Text style={styles.heading4}>
          Crafting Your Numerology Blueprint: A Personalized Guide
        </Text>
        <Text style={styles.bodyText}>
          In this transformative personalised report, we're setting out to build
          something extraordinary – your very own Numerology Blueprint.{' '}
        </Text>
        <Text style={styles.bodyText}>
          Just as architects design blueprints to create magnificent structures,
          we’ve created a customized plan that maps out the intricate design of
          your life.{' '}
        </Text>
        <Text style={styles.bodyText}>
          It's a journey of self-discovery that will enable you to navigate
          life's twists and turns with newfound clarity and purpose.
        </Text>
        <Text style={styles.bodyText}>
          Your Numerology Blueprint is a fusion of your birthdate and name – a
          unique combination that generates a set of core numbers.{' '}
        </Text>
        <Text style={styles.bodyText}>
          These numbers are like the pieces of a puzzle that come together to
          paint a portrait of who you are and where you're headed.{' '}
        </Text>
        <Text style={styles.bodyText}>
          Through each chapter, we'll delve into these numbers, uncovering their
          significance and revealing how they interact to form the masterpiece
          that is your life story.
        </Text>
      </View>
      <PageFooter pageNumber={4} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page4Image} alt="page 5"></Image>
      </View>
      <View style={styles.content}>
        <Text style={styles.heading4}>
          Empowerment through Insight: How Numerology Enhances Your Life
        </Text>
        <Text style={styles.bodyText}>
          Imagine having a guidebook that helps you understand yourself on a
          deeper level. As we explore the facets of your Numerology Blueprint,
          you'll gain insights that empower you to make informed decisions,
          leverage your strengths, and overcome challenges with resilience.{' '}
        </Text>
        <Text style={styles.bodyText}>
          Whether you're seeking clarity in your career, relationships, or
          personal growth, numerology provides a unique perspective that can
          illuminate your path.
        </Text>
        <Text style={styles.bodyText}>
          Are you ready to embark on this transformative journey of
          self-discovery and empowerment? Your personalized Numerology Blueprint
          awaits, ready to unveil the mysteries of your life and guide you
          toward a future that aligns with your true essence.{' '}
        </Text>
        <Text style={[styles.bodyText, styles.mb20]}>
          Get ready to unlock the doors of possibility as we dive into the world
          of numbers and embrace the wisdom they hold.
        </Text>
        <Text style={styles.bodyText}>Many Blessing,</Text>
        <Text style={[styles.bodyText, styles.fontWeightBold]}>
          The MysticNumbers Team
        </Text>
      </View>
      <PageFooter pageNumber={5} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page6Image} alt="page 6"></Image>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page7Image} alt="page 7"></Image>
      </View>
      <PageFooter pageNumber={7} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page8Image} alt="page 8"></Image>
      </View>
      <PageFooter pageNumber={8} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page9Image} alt="page 9"></Image>
      </View>
      <View style={styles.content}>
        <Text style={styles.heading3}>
          What does your Life Path number reveal about you?
        </Text>
        <Text style={styles.bodyText}>
          {data.name}, your life path number is{' '}
          <Text style={styles.numberText}>{data.lifePathNumber}</Text>
        </Text>
        {LifePathNumberScript[data.lifePathNumber].map((paragraph, index) => (
          <Text key={index} style={styles.bodyText}>
            {paragraph}
          </Text>
        ))}
      </View>
      <PageFooter pageNumber={9} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page10Image} alt="page 10"></Image>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page11Image} alt="page 11"></Image>
      </View>
      <PageFooter pageNumber={11} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page12Image} alt="page 12"></Image>
      </View>
      <PageFooter pageNumber={12} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page13Image} alt="page 13"></Image>
      </View>
      <View style={styles.content}>
        <Text style={styles.heading3}>
          What does your Destiny number reveal about you?
        </Text>
        <Text style={styles.bodyText}>
          {data.name}, your destiny number is{' '}
          <Text style={styles.numberText}>{data.destinyNumber}</Text>
        </Text>
        {DestinyNumberScript[data.destinyNumber].map((paragraph, index) => (
          <Text key={index} style={styles.bodyText}>
            {paragraph}
          </Text>
        ))}
      </View>
      <PageFooter pageNumber={13} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page14Image} alt="page 14"></Image>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page15Image} alt="page 15"></Image>
      </View>
      <PageFooter pageNumber={15} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page16Image} alt="page 16"></Image>
      </View>
      <PageFooter pageNumber={16} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page17Image} alt="page 17"></Image>
      </View>
      <View style={styles.content}>
        <Text style={styles.heading3}>
          What does your Soul Urge number reveal about you?
        </Text>
        <Text style={styles.bodyText}>
          {data.name}, your soul urge number is{' '}
          <Text style={styles.numberText}>{data.soulUrgeNumber}</Text>
        </Text>
        {SoulUrgeNumberScript[data.soulUrgeNumber].map((paragraph, index) => (
          <Text key={index} style={styles.bodyText}>
            {paragraph}
          </Text>
        ))}
      </View>
      <PageFooter pageNumber={17} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page18Image} alt="page 18"></Image>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page19Image} alt="page 19"></Image>
      </View>
      <PageFooter pageNumber={19} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page20Image} alt="page 20"></Image>
      </View>
      <PageFooter pageNumber={20} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page21Image} alt="page 21"></Image>
      </View>
      <View style={styles.content}>
        <Text style={styles.heading3}>
          What does your Personality number reveal about you?
        </Text>
        <Text style={styles.bodyText}>
          {data.name}, your personality number is{' '}
          <Text style={styles.numberText}>{data.personalityNumber}</Text>
        </Text>
        {PersonalityNumberScript[data.personalityNumber].map(
          (paragraph, index) => (
            <Text key={index} style={styles.bodyText}>
              {paragraph}
            </Text>
          )
        )}
      </View>
      <PageFooter pageNumber={21} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page22Image} alt="page 22"></Image>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page23Image} alt="page 23"></Image>
      </View>
      <PageFooter pageNumber={23} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page13Image} alt="page 24"></Image>
      </View>
      <View style={styles.content}>
        <Text style={styles.heading3}>What does your Love number reveal </Text>
        <Text style={styles.heading3}>about you?</Text>
        <Text style={styles.bodyText}>
          {data.name}, your love number is{' '}
          <Text style={styles.numberText}>{data.loveNumber}</Text>
        </Text>
        {LoveNumberScript[data.loveNumber].map((paragraph, index) => (
          <Text key={index} style={styles.bodyText}>
            {paragraph}
          </Text>
        ))}
      </View>
      <PageFooter pageNumber={24} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page25Image} alt="page 25"></Image>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page26Image} alt="page 26"></Image>
      </View>
      <PageFooter pageNumber={26} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page27Image} alt="page 27"></Image>
      </View>
      <View style={styles.content}>
        <Text style={styles.heading3}>
          What does your Birthday number reveal about you?
        </Text>
        <Text style={styles.bodyText}>
          {data.name}, your birth day number is{' '}
          <Text style={styles.numberText}>{data.birthDayNumber}</Text>
        </Text>
        {BirthDayNumberScript[data.birthDayNumber].map((paragraph, index) => (
          <Text key={index} style={styles.bodyText}>
            {paragraph}
          </Text>
        ))}
      </View>
      <PageFooter pageNumber={27} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page28Image} alt="page 28"></Image>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page29Image} alt="page 29"></Image>
      </View>
      <PageFooter pageNumber={29} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page30Image} alt="page 30"></Image>
      </View>
      <PageFooter pageNumber={30} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page13Image} alt="page 31"></Image>
      </View>
      <View style={styles.content}>
        <Text style={styles.heading3}>What does your Name number reveal </Text>
        <Text style={styles.heading3}>about you?</Text>
        <Text style={styles.bodyText}>
          {data.name}, your name number is{' '}
          <Text style={styles.numberText}>{data.nameNumber}</Text>
        </Text>
        {NameNumberScript[data.nameNumber].map((paragraph, index) => (
          <Text key={index} style={styles.bodyText}>
            {paragraph}
          </Text>
        ))}
      </View>
      <PageFooter pageNumber={31} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page32Image} alt="page 32"></Image>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page33Image} alt="page 33"></Image>
      </View>
      <PageFooter pageNumber={33} />
    </Page>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={Page13Image} alt="page 34"></Image>
      </View>
      <View style={styles.content}>
        <Text style={styles.heading3}>
          What does your Planet number reveal{' '}
        </Text>
        <Text style={styles.heading3}>about you?</Text>
        <Text style={styles.bodyText}>
          {data.name}, your planet number is{' '}
          <Text style={styles.numberText}>{data.planetNumber}</Text>
        </Text>
        {PlanetNumberScript[data.planetNumber].map((paragraph, index) => (
          <Text key={index} style={styles.bodyText}>
            {paragraph}
          </Text>
        ))}
      </View>
      <PageFooter pageNumber={34} />
    </Page>
  </Document>
);

export default NumerologyDocument;
