import NumerologyAspect1Image from '../../assets/images/numerology-aspects/1.png';
import NumerologyAspect2Image from '../../assets/images/numerology-aspects/2.png';
import NumerologyAspect3Image from '../../assets/images/numerology-aspects/3.png';
import NumerologyAspect4Image from '../../assets/images/numerology-aspects/4.png';
import NumerologyAspect5Image from '../../assets/images/numerology-aspects/5.png';
import NumerologyAspect6Image from '../../assets/images/numerology-aspects/6.png';
import NumerologyAspect7Image from '../../assets/images/numerology-aspects/7.png';
import NumerologyAspect8Image from '../../assets/images/numerology-aspects/8.png';
import PaymentAmexImage from '../../assets/images/payment_amex.png';
import PaymentCardImage from '../../assets/images/payment_card.png';
import PaymentSSLSecuredImage from '../../assets/images/payment_ssl-secured.png';
import PaymentVisaImage from '../../assets/images/payment_visa.png';

export const QuizBirthdayStepNames = ['decade', 'year', 'month', 'day'];

export const AllMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const BirthItems = ['year', 'month', 'day'];

export const S3UploadedAudios = {
  BACKGROUND:
    'https://mystic-numbers.s3.eu-west-2.amazonaws.com/background-sound.mp3',
  START_INTRO:
    'https://mystic-numbers.s3.eu-west-2.amazonaws.com/start-intro.mp3',
  END_LIFE_PATH:
    'https://mystic-numbers.s3.eu-west-2.amazonaws.com/end_of_life_path.mp3',
  START_DESTINY_NUMBER:
    'https://mystic-numbers.s3.eu-west-2.amazonaws.com/intro_of_destiny_number.mp3',
  END_OUTRO: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/end-outro.mp3',
};

export const AllLifeNumberAudios = {
  1: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_1.mp3',
  2: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_2.mp3',
  3: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_3.mp3',
  4: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_4.mp3',
  5: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_5.mp3',
  6: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_6.mp3',
  7: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_7.mp3',
  8: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_8.mp3',
  9: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_9.mp3',
  11: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_11.mp3',
  22: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_22.mp3',
  33: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/life_path_number_33.mp3',
};

export const AllDestinyNumberAudios = {
  1: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_1.mp3',
  2: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_2.mp3',
  3: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_3.mp3',
  4: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_4.mp3',
  5: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_5.mp3',
  6: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_6.mp3',
  7: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_7.mp3',
  8: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_8.mp3',
  9: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_9.mp3',
  11: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_11.mp3',
  22: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_22.mp3',
  33: 'https://mystic-numbers.s3.eu-west-2.amazonaws.com/destiny_number_33.mp3',
};

export const EnglishAlphabets = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const NumerologyAspectsImages = [
  NumerologyAspect1Image,
  NumerologyAspect2Image,
  NumerologyAspect3Image,
  NumerologyAspect4Image,
  NumerologyAspect5Image,
  NumerologyAspect6Image,
  NumerologyAspect7Image,
  NumerologyAspect8Image,
];

export const PaymentLogos = [
  {
    name: 'visa',
    src: PaymentVisaImage,
  },
  {
    name: 'amex',
    src: PaymentAmexImage,
  },
  {
    name: 'card',
    src: PaymentCardImage,
  },
  {
    name: 'ssl-secured',
    src: PaymentSSLSecuredImage,
  },
];

export const YouBestSelectOptions = [
  {
    value: 'option-1',
    name: 'I struggle with my weight.',
  },
  {
    value: 'option-2',
    name: 'I struggle with relationships.',
  },
  {
    value: 'option-3',
    name: 'I struggle with money.',
  },
  {
    value: 'option-none',
    name: 'None of the above',
  },
];
