import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { covertTextToSpeech } from '../../../../../api/api';
import { S3UploadedAudios } from '../../../../../common/constants';
import VoiceCaptionBox from '../audio-caption-box/AudioCaptionBox';
import { useQuizFullNameAudioData } from './useQuizFullNameAudioData';

const QuizFullNameAudio = ({ name, readingStep }) => {
  const [startNameAudio, setStartNameAudio] = useState(null);
  const [scriptAudio, setScriptAudio] = useState(null);
  const [canPlayStartNameAudio, setCanPlayStartNameAudio] = useState(false);
  const [canPlayScriptAudio, setCanPlayScriptAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setTimeout(() => loadAudios(), 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const loadAudios = async () => {
    if (name) {
      const startNameSpeech = await covertTextToSpeech(
        `${name}, there's so much more I have to share with you.`
      );
      const startNameAudioObj = new Howl({
        src: [startNameSpeech],
        html5: true,
        format: 'mp3',
        onload: () => setCanPlayStartNameAudio(true),
        onplay: () => setIsPlaying(true),
      });
      setStartNameAudio(startNameAudioObj);
    }

    const endLifePathAudioObj = new Howl({
      src: [S3UploadedAudios.END_LIFE_PATH],
      html5: true,
      format: 'mp3',
      onload: () => setCanPlayScriptAudio(true),
    });
    setScriptAudio(endLifePathAudioObj);
  };

  useEffect(() => {
    if (
      readingStep === 'quiz-full-name' &&
      startNameAudio &&
      scriptAudio &&
      canPlayStartNameAudio &&
      canPlayScriptAudio &&
      !isPlaying
    ) {
      setTimeout(() => startNameAudio.play(), 1000);
      setTimeout(() => scriptAudio.play(), 4000);
    }
  }, [
    canPlayScriptAudio,
    canPlayStartNameAudio,
    isPlaying,
    readingStep,
    scriptAudio,
    startNameAudio,
  ]);

  const { caption, isFinished, isStarted } = useQuizFullNameAudioData({
    name,
    startPlay:
      readingStep === 'quiz-full-name' &&
      canPlayStartNameAudio &&
      canPlayScriptAudio,
  });

  useEffect(() => {
    if (isFinished) {
      startNameAudio.unload();
      scriptAudio.unload();
    }
  }, [isFinished, scriptAudio, startNameAudio]);

  return (
    <VoiceCaptionBox
      caption={caption}
      isFinished={isFinished}
      isStarted={isStarted}
      hiddenControls
    />
  );
};

export default QuizFullNameAudio;
