export const EndOutroScript = [
  {
    text: '{name}, this might surprise you, ',
    time: 1,
  },
  {
    text: `but we've only just scratched the surface with your reading here today.`,
    time: 3,
  },
  {
    text: `There's so much more about your unique personality yet to be unveiled but I must warn you, `,
    time: 6,
  },
  {
    text: `some of the details are so intimate that we can't speak here.`,
    time: 11,
  },
  {
    text: 'I took the liberty of creating a FULL personalized report for you ',
    time: 15,
  },
  {
    text: 'based on the 8 most important elements of your numerology chart.',
    time: 19,
  },
  {
    text: 'This detailed and beautiful report is tailor-made specifically for you ',
    time: 22,
  },
  {
    text: 'and dives much deeper into every aspect of your life.',
    time: 27,
  },
  {
    text: 'It will help you make sense of failed relationships with family and friends, and romantically too.',
    time: 30,
  },
  {
    text: 'You will begin to understand the emotional patterns behind your breakups ',
    time: 36,
  },
  {
    text: 'and how you can fix them going forward by knowing what kind of partner is perfect for you.',
    time: 39,
  },
  {
    text: 'It will shed light on the reasoning behind your career decisions, financial setbacks, ',
    time: 44,
  },
  {
    text: 'and guide you on the right path to create the wealth and abundance you need in your life.',
    time: 49,
  },
  {
    text: 'It will reveal both your strengths and your weaknesses, providing you with the tools ',
    time: 53,
  },
  {
    text: 'to shift from an existence of mediocrity to a life you have always envisioned.',
    time: 58,
  },
  {
    text: `Your personalized numerology report won't just inspire you; it'll empower you.`,
    time: 63,
  },
  {
    text: `It'll help turn those dreams into reality.`,
    time: 68,
  },
  {
    text: `It's more than just another reading.`,
    time: 71,
  },
  {
    text: `It's a profound dive into the cogs and gears of your identity `,
    time: 73,
  },
  {
    text: 'and offers insights far deeper than anything I could outline for you here.',
    time: 76,
  },
  {
    text: 'With this beautiful 40-page report, your life will start making sense as never before.',
    time: 80,
  },
  {
    text: 'So take action right now, simply click the button on this page ',
    time: 85,
  },
  {
    text: 'where you’ll be taken to our safe and secure order page.',
    time: 88,
  },
  {
    text: 'Within seconds you’ll get access to your FULL personalised numerology report ',
    time: 91,
  },
  {
    text: 'and a deep dive into the shocking revelations that your unique numbers reveal.',
    time: 96,
  },
  {
    text: 'Imagine how it’s going to feel when everything you were confused about in life finally makes sense.',
    time: 101,
  },
  {
    text: 'Imagine knowing exactly what steps you need to take to lead you to a life full of wealth, ',
    time: 106,
  },
  {
    text: 'happiness and fulfillment.',
    time: 111,
  },
  {
    text: 'Just imagine it for a second, truly let yourself feel what that would be like.',
    time: 113,
  },
  {
    text: 'It is, of course, not something that you can put a price tag on, ',
    time: 117,
  },
  {
    text: 'because these rare insights are priceless.',
    time: 120,
  },
  {
    text: 'But, trust me, I am not in this to make money, ',
    time: 123,
  },
  {
    text: 'I truly want to help as many people as possible',
    time: 128,
  },
  {
    text: 'So to keep the price low and help as many people as possible.',
    time: 131,
  },
  {
    text: 'I’m willing to let you have it today at the lowest price possible.',
    time: 135,
  },
  {
    text: 'Usually this is available to the public for $49 but, today only ',
    time: 139,
  },
  {
    text: 'I’m going to let you have it at an exclusive price of only $15.',
    time: 143,
  },
  {
    text: 'Yup that’s right — just $15, that’s a whopping 80% off!',
    time: 147,
  },
  {
    text: 'But wait there’s more',
    time: 152,
  },
  {
    text: 'Just to make this offer a complete no brainer, here’s what I’m going to do for you.',
    time: 154,
  },
  {
    text: 'To prove how confident I am that you will LOVE this reading, ',
    time: 158,
  },
  {
    text: 'and so you have absolutely no risk.',
    time: 161,
  },
  {
    text: 'I’m prepared to back your order with a 100% satisfaction guarantee that works like this.',
    time: 163,
  },
  {
    text: 'If, within 60 days, in the unlikely event that you are unhappy with your reading, ',
    time: 168,
  },
  {
    text: 'I’ll give you every cent of your money back, that’s right, ',
    time: 173,
  },
  {
    text: 'send me an email and I’ll pay you every cent back, No questions asked!',
    time: 176,
  },
  {
    text: 'Our customers overwhelmingly positive testimonials speak volumes ',
    time: 181,
  },
  {
    text: `about the pinpoint accuracy of our numerology reports, and I’m confident you'll love it too.`,
    time: 185,
  },
  {
    text: `I know there’s a part of you that's afraid to leap and that’s totally understandable.`,
    time: 190,
  },
  {
    text: `But remember, If you pass this up, you'll return to your old life of uncertainty `,
    time: 195,
  },
  {
    text: 'and misunderstandings, fluttering about without direction.',
    time: 199,
  },
  {
    text: `It doesn't have to be this way.`,
    time: 202,
  },
  {
    text: 'One click, one simple step can open up a world of unbelievable potential.',
    time: 204,
  },
  {
    text: `Don't let your extraordinary future slip through your fingers.`,
    time: 209,
  },
  {
    text: 'Click the button on this page now, ',
    time: 213,
  },
  {
    text: `and let's embark on this incredible journey of self-discovery together.`,
    time: 215,
  },
  {
    text: 'The adventure of a lifetime begins right here, right now.',
    time: 219,
  },
  {
    text: '',
    time: 224,
  },
];
