import './Footer.scss';

const Footer = () => {
  return (
    <footer>
      <p>Copyright 2023 MysticNumbers. All rights reserved.</p>

      <div className="footer-links">
        <a href="/privacy-policy" target="_blank">
          Privacy
        </a>
        <a href="/terms-conditions" target="_blank">
          Terms & Conditions
        </a>
        <a href="mailto:support@mysticnumbers.com">Contact</a>
      </div>
    </footer>
  );
};

export default Footer;
