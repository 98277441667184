import '../privacy-policy/PrivacyPolicy.scss';

const TermsConditions = () => {
  return (
    <div className="page-privacy-terms">
      <p className="h1">Terms & Conditions</p>

      <div className="box__wrapper">
        <div className="box__inner">
          <div className="block">
            <p className="h3">Terms & Conditions</p>
            <p>
              These terms and conditions outline the rules and regulations for
              the use of this website.
            </p>
            <p>
              By accessing this website we assume you accept these terms and
              conditions in full. Do not continue to use this website if you do
              not accept all of the terms and conditions stated on this page.
            </p>
            <p>
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and any or all Agreements:
              "Client", "You" and "Your" refers to you, the person accessing
              this website and accepting the Company's terms and conditions.
              "The Company", "Ourselves", "We", "Our" and "Us", refers to our
              Company. "Party", "Parties", or "Us", refers to both the Client
              and ourselves, or either the Client or ourselves.
            </p>
            <p>
              All terms refer to the offer, acceptance and consideration of
              payment necessary to undertake the process of our assistance to
              the Client in the most appropriate manner, whether by formal
              meetings of a fixed duration, or any other means, for the express
              purpose of meeting the Client's needs in respect of provision of
              the Company's stated services/products, in accordance with and
              subject to, prevailing law.
            </p>
            <p>
              Any use of the above terminology or other words in the singular,
              plural, capitalisation and/or he/she or they, are taken as
              interchangeable and therefore as referring to same.
            </p>
          </div>
          <div className="block">
            <p className="h3">Purpose of Use</p>
            <p>
              The information on this site is for educational purposes only. The
              information provided should not replace the advice given by your
              physician or health care professional. You should consult your
              personal physician before beginning an exercise or specialized
              nutrition program.
            </p>
            <p>
              As a condition of your using this information, you represent that
              you are at least eighteen years of age and have the legal
              authority to accept the terms and conditions, are in good mental
              health, do not suffer from any mental or physical condition or
              disability which might render your participation hazardous to
              yourself or to others, or which would impair your ability to
              understand this health release.
            </p>
            <p>
              You understand and agree that the benefits you obtain may vary
              depending on many factors, including the accuracy of the
              information you provide, changes in your health and lifestyle
              habits, and compliance with the program.
            </p>
            <p>
              You understand and agree that it is your responsibility to consult
              with your physician regarding any food allergies that you may
              have, any drug interactions you may have with supplements, and to
              check the ingredients in all recipes presented.
            </p>
            <p>
              You understand and agree that if you are on any medications or
              currently being treated for any illness or condition that you may
              participate in this program only after consultation with your
              health practitioner.
            </p>
          </div>
          <div className="block">
            <p className="h3">Health Disclaimers</p>
            <p>
              Any references to physical or mental health on this website
              constitutes an educational service consisting solely of general
              health information. The materials are provided “as “ and without
              warranties of any kind either express or implied.
            </p>
            <p>
              (i) Not a Substitute for Professional Medical Advice or Treatment.
              The websites™ content is not a substitute for direct, personal,
              professional medical care and diagnosis. None of the exercises or
              methods (including products and services) mentioned on this site
              should be performed or otherwise used without clearance from your
              physician or health care provider.
            </p>
            <p>
              The information contained within is not intended to provide
              specific physical or mental health advice, or any other advice
              whatsoever, for any individual or company and should not be relied
              upon in that regard. We are not medical professionals and nothing
              on this website should be misconstrued to mean otherwise.
            </p>
            <p>
              (ii) Health Risks. There may be risks associated with
              participating in activities mentioned on this website for people
              in poor health or with pre-existing physical or mental health
              conditions. Because these risks exist, you will not participate in
              such activities if you are in poor health or have a pre-existing
              mental or physical condition. If you choose to participate in
              these risks, you do so of your own free will and accord, knowingly
              and voluntarily assuming all risks associated with such
              activities.
            </p>
            <p>
              (iii) Accuracy and Completeness. Facts and information are
              believed to be accurate at the time they were placed on this
              website. All data provided on this website is to be used for
              information purposes only. Products and services described are
              only offered in jurisdictions where they may be legally offered.
              Information provided is not all-inclusive, and is limited to
              information that is made available and such information should not
              be relied upon as all-inclusive or accurate.
            </p>
            <p>
              (iv) Injuries. You agree to hold us, owners, agents, and employees
              harmless from any and all liability for all claims for damages due
              to injuries, including attorney fees and costs, incurred by you or
              caused to third parties by you, arising out of the activities
              discussed on this website, excepting only claims for gross
              negligence or intentional tort.
            </p>
            <p>
              The products and claims made about specific products on or through
              this Site have not been evaluated by the United States Food and
              Drug Administration and are not approved to diagnose, treat, cure
              or prevent disease.
            </p>
            <p>
              This Site is not intended to provide diagnosis, treatment or
              medical advice. Products, services, information and other content
              provided on this Site, including information that may be provided
              on this Site directly or by linking to third-party websites are
              provided for informational purposes only. Please consult with a
              physician or other healthcare professional regarding any medical
              or health related diagnosis or treatment options.
            </p>
            <p>
              Information provided on this Site and linked websites, including
              information relating to medical and health conditions, treatments
              and products may be provided in summary form. To protect the
              identity of the author of this product we currently are or may in
              the future use actors and/or voice actors to present our products.
            </p>
            <p>
              Information on this Site including any product label or packaging
              should not be considered as a substitute for advice from a
              healthcare professional. This Site does not recommend
              self-management of health issues. Information on this Site is not
              comprehensive and does not cover all diseases, ailments, physical
              conditions or their treatment.
            </p>
            <p>
              Contact your healthcare professional promptly should you have any
              health related questions. Never disregard or delay medical advice
              based upon information you may have read on this Site.
            </p>
            <p>
              You should not use the information or services on this Site to
              diagnose or treat any health issues or for prescription of any
              medication or other treatment. You should always consult with your
              healthcare professional and read information provided by the
              product manufacture and any product label or packaging, prior to
              using any medication, nutritional, herbal or homeopathic product
              or before beginning any exercise or diet program or starting any
              treatment for a health issue. Individuals are different and may
              react differently to different products. You should consult your
              physician about interactions between medications you are taking
              and nutritional supplements.
            </p>
            <p>
              Comments made in any forums on this Site by employees or Site
              users are strictly their own personal views made in their own
              personal capacity and are not claims made by us or do they
              represent the position or view of the owners of this Site. Product
              ratings by any current or previous employees or Site users are
              strictly their own personal views made in their own personal
              capacity and are not intended as a substitute for appropriate
              medical care or advice from a healthcare professional.
            </p>
            <p>
              Always check the product label or packaging prior to using any
              product. If there are discrepancies, customers should follow the
              information provided on the product label or packaging.
            </p>
            <p>
              You should contact the manufacturer directly for clarification as
              to product labelling and packaging details and recommended use.
            </p>
            <p>
              Owners of this Site are not liable for any information provided on
              this Site with regard to recommendations regarding supplements for
              any health purposes.
            </p>
            <p>
              The products or claims made about specific nutrients or products
              have not been evaluated by the Food and Drug Administration.
            </p>
            <p>
              Dietary products are not intended to treat, prevent or cure
              disease. Consult with a healthcare professional before starting
              any diet, supplement or exercise program. Owners of this Site make
              no guarantee or warranty with respect to any products or services
              sold.
            </p>
          </div>
          <div className="block">
            <p className="h3">Testimonials, Case Studies, and Examples.</p>
            <p>
              Testimonials, case studies, and examples found on this website are
              exceptional results, do not reflect the typical purchaserвЂ™s
              experience, donвЂ™t apply to the average person and are not
              intended to represent or guarantee that anyone will achieve the
              same or similar results. You should always perform due diligence
              and not take such results at face value.
            </p>
            <p>
              We are not responsible for any errors or omissions in typical
              results information supplied to us by manufacturers or other
              reputable third parties.
            </p>
          </div>
          <div className="block">
            <p className="h3">Refund Policy</p>
            <p>
              Your purchase is fully protected by our 60 day returns policy.
            </p>
            <p>
              In order to obtain a refund under this policy, you must carefully
              check the terms and instructions found on our shipping and returns
              policy page here.
            </p>
          </div>
          <div className="block">
            <p className="h3">Cookies</p>
            <p>
              We employ the use of cookies. By using our website you consent to
              the use of cookies in accordance with our privacy policy.
            </p>
            <p>
              Most of the modern day interactive web sites use cookies to enable
              us to retrieve user details for each visit. Cookies are used in
              some areas of our site to enable the functionality of this area
              and ease of use for those people visiting.
            </p>
            <p>
              Some of our affiliate / advertising partners may also use cookies.
            </p>
          </div>
          <div className="block">
            <p className="h3">License</p>
            <p>
              Unless otherwise stated, we and/or our licensors own the
              intellectual property rights for all material on this website.
            </p>
            <p>All intellectual property rights are reserved.</p>
            <p>
              You may view and/or print pages from this website for your own
              personal use subject to restrictions set in these terms and
              conditions.
            </p>
          </div>
          <div className="block">
            <p className="h3">You must not:</p>
            <ul className="no-padding hidden-list-style">
              <li>
                <b>1.</b> Republish material from this website
              </li>
              <li>
                <b>2.</b> Sell, rent or sub-license material from this website
              </li>
              <li>
                <b>3.</b> Reproduce, duplicate or copy material from this
                website
              </li>
              <li>
                <b>4.</b> Redistribute content from this website (unless content
                is specifically made for redistribution)
              </li>
            </ul>
          </div>
          <div className="block">
            <p className="h3">Hyperlinking to our Content</p>
            <p>
              <b>1.</b> The following organizations may link to our Web site
              without prior written approval:
            </p>
            <ul>
              <ol>1. Government agencies;</ol>
              <ol>2. Search engines;</ol>
              <ol>3. News organizations;</ol>
              <ol>
                4. Online directory distributors when they list us in the
                directory may link to our Web site in the
              </ol>
              <ol>
                5. Systemwide Accredited Businesses except soliciting non-profit
                organizations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Web site
              </ol>
            </ul>
            <p>
              <b>2.</b> These organizations may link to our home page, to
              publications or to other Web site information so long as the link:
              (a) is not in any way misleading; (b) does not falsely imply
              sponsorship, endorsement or approval of the linking party and its
              products or services; and (c) fits within the context of the
              linking partys™ site.
            </p>
            <p>
              <b>3.</b> We may consider and approve in our sole discretion other
              link requests from the following types of organizations:
            </p>
            <ul>
              <ol>
                1. Commonly-known consumer and/or business information sources
                such as Chambers of Commerce, American Automobile Association,
                AARP and Consumers Union;
              </ol>
              <ol>2. dot.com community sites;</ol>
              <ol>
                3. Associations or other groups representing charities,
                including charity giving sites,
              </ol>
              <ol>4. online directory distributors;</ol>
              <ol>5. internet portals;</ol>
              <ol>
                6. accounting, law and consulting firms whose primary clients
                are businesses; and
              </ol>
              <ol>7. educational institutions and trade associations.</ol>
            </ul>
            <p>
              We will approve link requests from these organizations if we
              determine that: (a) the link would not reflect unfavorably on us
              or our accredited businesses (for example, trade associations or
              other organizations representing inherently suspect types of
              business, such as work-at-home opportunities, shall not be allowed
              to link); (b)the organization does not have an unsatisfactory
              record with us; (c) the benefit to us from the visibility
              associated with the hyperlink outweighs the absence; and (d) where
              the link is in the context of general resource information or is
              otherwise consistent with editorial content in a newsletter or
              similar product furthering the mission of the organization.
            </p>
            <p>
              These organizations may link to our home page, to publications or
              to other Web site information so long as the link: (a) is not in
              any way misleading; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and it products or
              services; and (c) fits within the context of the linking partys™
              site.
            </p>
            <p>
              If you are among the organizations listed in paragraph 2 above and
              are interested in linking to our website, you must notify us by
              contacting us from our vendor contact link at the bottom of this
              page. Please include your name, your organization name, contact
              information (such as a phone number and/or e-mail address) as well
              as the URL of your site, a list of any URLs from which you intend
              to link to our Web site, and a list of the URL(s) on our site to
              which you would like to link. Allow 2-3 weeks for a response.
            </p>
            <p>
              Approved organizations may hyperlink to our Web site as follows:
            </p>
            <ul className="no-padding">
              <ol>
                <b>1.</b> By use of our corporate name; or
              </ol>
              <ol>
                <b>2.</b> By use of the uniform resource locator (Web address)
                being linked to; or
              </ol>
              <ol>
                <b>3.</b> By use of any other description of our Web site or
                material being linked to that makes sense within the context and
                format of content on the linking partys™ site.
              </ol>
            </ul>
            <p>
              No use of our logo or other artwork will be allowed for linking
              absent a trademark license agreement.
            </p>
          </div>
          <div className="block">
            <p className="h3">Iframes</p>
            <p>
              Without prior approval and express written permission, you may not
              create frames around our Web pages or use other techniques that
              alter in any way the visual presentation or appearance of our Web
              site.
            </p>
          </div>
          <div className="block">
            <p className="h3">Reservation of Rights</p>
            <p>
              We reserve the right at any time and at our sole discretion to
              request that you remove all links or any particular link to our
              Web site. You agree to immediately remove all links to our Web
              site upon such request.
            </p>
            <p>
              We also reserve the right to amend these terms and conditions and
              its linking policy at any time. By continuing to link to our Web
              site, you agree to be bound to and abide by these linking terms
              and conditions.
            </p>
          </div>
          <div className="block">
            <p className="h3">Removal of links from our website</p>
            <p>
              If you find any link on our Web site or any linked web site
              objectionable for any reason, you may contact us about this. We
              will consider requests to remove links but will have no obligation
              to do so or to respond directly to you.
            </p>
            <p>
              Whilst we endeavour to ensure that the information on this website
              is correct, we do not warrant its completeness or accuracy; nor do
              we commit to ensuring that the website remains available or that
              the material on the website is kept up to date.
            </p>
          </div>
          <div className="block">
            <p className="h3">Content Liability</p>
            <p>
              We shall have no responsibility or liability for any content
              appearing on your Web site. You agree to indemnify and defend us
              against all claims arising out of or based upon your Website. No
              link(s) may appear on any page on your Web site or within any
              context containing content or materials that may be interpreted as
              libelous, obscene or criminal, or which infringes, otherwise
              violates, or advocates the infringement or other violation of, any
              third party rights.
            </p>
          </div>
          <div className="block">
            <p className="h3">Disclaimer</p>
            <p>
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to our website
              and the use of this website (including, without limitation, any
              warranties implied by law in respect of satisfactory quality,
              fitness for purpose and/or the use of reasonable care and skill).
              Nothing in this disclaimer will:
            </p>
            <ul className="no-padding">
              <ol>
                <b>1.</b> limit or exclude our or your liability for death or
                personal injury resulting from negligence;
              </ol>
              <ol>
                <b>2.</b> limit or exclude our or your liability for fraud or
                fraudulent misrepresentation;
              </ol>
              <ol>
                <b>3.</b> limit any of our or your liabilities in any way that
                is not permitted under applicable law; or
              </ol>
              <ol>
                <b>4.</b> exclude any of our or your liabilities that may not be
                excluded under applicable law.
              </ol>
            </ul>
            <p>
              The limitations and exclusions of liability set out in this
              Section and elsewhere in this disclaimer: (a) are subject to the
              preceding paragraph; and (b) govern all liabilities arising under
              the disclaimer or in relation to the subject matter of this
              disclaimer, including liabilities arising in contract, in tort
              (including negligence) and for breach of statutory duty.
            </p>
            <p>
              To the extent that the website and the information and services on
              the website are provided free of charge, we will not be liable for
              any loss or damage of any nature.
            </p>
            <p>
              You hereby agree to release and hold harmless us, our affiliates,
              and their respective directors, officers and employees, from any
              liability for any injury or adverse reaction which you may
              experience, or any other claims or damages which may arise from
              your use of the site or services or products.
            </p>
          </div>
          <div className="block">
            <p className="h3">Contact Information</p>
            <p>
              If you have any queries regarding any of our terms, please contact
              us by clicking the vendor contact link on the bottom this page.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
