import { useEffect, useState } from 'react';
import './DayStep.scss';

const DayStep = ({ className, data, onSelect }) => {
  const [delayOffset, setDelayOffset] = useState(0.1);
  const [days, setDays] = useState(1);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setDelayOffset(0.05);
    }
  }, []);

  useEffect(() => {
    if (data.month) {
      setDays(31);
    }
  }, [data]);

  const getAnimationStyle = (index) => {
    return {
      animation: `scale-up-tl 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) ${
        delayOffset * (index + 1)
      }s both`,
    };
  };

  return (
    <div className={`step-content ${className}`}>
      <p className="h2 text-center">Which Day were you born?</p>

      <div className="days-grid">
        {Array(days)
          .fill(1)
          .map((day, i) => (
            <div
              key={day + i}
              className="number-box"
              style={getAnimationStyle(i)}
              onClick={() => onSelect(day + i)}
            >
              <div className="number-box__inner">{day + i}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DayStep;
