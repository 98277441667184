import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Helper from '../../common/helper';
import { authSignIn, clearLoading } from '../../redux/slices/AuthSlice';
import './SignIn.scss';

const SignIn = () => {
  const [emailText, setEmailText] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const [validateError, setValidateError] = useState({
    email: false,
    password: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useDispatch();
  const { loading: authLoading, userData } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData)
      navigate(userData.role === 'admin' ? '/members' : '/members-area');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (
      authLoading.action === 'register' &&
      authLoading.status === 'fulfilled'
    ) {
      setIsSubmitted(true);
    }
    if (authLoading.action === 'signin' && authLoading.status === 'fulfilled') {
      dispatch(clearLoading());
      navigate(userData?.role === 'admin' ? '/members' : '/members-area');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoading, userData]);

  const buttonDisabled = useMemo(() => {
    return (
      authLoading.status === 'pending' || authLoading.action === 'register'
    );
  }, [authLoading]);

  const errorMessage = useMemo(() => {
    return (
      ['signin', 'register'].includes(authLoading.action) && authLoading.message
    );
  }, [authLoading]);

  const handleLogin = useCallback(() => {
    const validateForm = {
      email: !emailText || !Helper.validateEmail(emailText),
      password: !passwordText,
    };
    setValidateError(validateForm);

    if (!validateForm.email && !validateForm.password) {
      dispatch(
        authSignIn({
          email: emailText,
          pwd: passwordText,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailText, passwordText]);

  return (
    <div className="page-signin">
      <div className="box__wrapper">
        <div className="description-area">
          <p className="title">MysticNumbers.com</p>
          <p className="subtitle">Members Area</p>
          <p className="text-body">
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and any or all Agreements:
            "Client", "You" and "Your" refers to you, the person accessing this
            website and accepting the Company's terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves, or either the Client or ourselves.
          </p>
        </div>

        <div className="box__inner">
          <p className="title">Member Log-In</p>
          <p className="subtitle">Log in below to access your content</p>

          <div className="form-content">
            <input
              className={`${validateError.email ? 'error' : ''}`}
              placeholder="Email"
              value={emailText}
              onChange={(e) => {
                setValidateError({ ...validateError, email: false });
                setEmailText(e.target.value);
              }}
            />
            <input
              className={`${validateError.password ? 'error' : ''}`}
              type="password"
              placeholder="Password"
              value={passwordText}
              onChange={(e) => {
                setValidateError({ ...validateError, password: false });
                setPasswordText(e.target.value);
              }}
            />
            {errorMessage !== '' && <p className="error">{errorMessage}</p>}
            {isSubmitted && (
              <p className="success">Sucessfully sent an email.</p>
            )}
            <div
              className={`btn-gradient btn-login ${
                buttonDisabled ? 'disabled' : ''
              }`}
              onClick={handleLogin}
            >
              Log In
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
