export const LifeNumbersScript = {
  1: [
    {
      text: 'Your life path number is 1,',
      time: 0.5,
    },
    {
      text: 'and this reveals some truly incredible aspects of your personality.',
      time: 2,
    },
    {
      text: 'Your positive energy is a contagious force that spreads like wildfire.',
      time: 5.5,
    },
    {
      text: 'It makes you a magnet for happiness and love.',
      time: 9.5,
    },
    {
      text: 'When you allow your creativity to shine,',
      time: 12.5,
    },
    {
      text: 'it draws you to explore beyond the boundaries of conventional thinking and ',
      time: 15,
    },
    {
      text: 'create the world you desire from what already exists.',
      time: 19,
    },
    {
      text: 'This trait has been putting you one step ahead of your peers,',
      time: 22,
    },
    {
      text: `and if you keep it up, you'll find opportunities to guide others and pioneer new ventures.`,
      time: 25.5,
    },
    {
      text: 'As you take the lead at work and school, you tap into the power of your imagination',
      time: 30.5,
    },
    {
      text: 'and your desire to positively impact others.',
      time: 35,
    },
    {
      text: 'This drive lures people to join you on this exciting journey of innovation and growth.',
      time: 38,
    },
    {
      text: `You're so confident.`,
      time: 43.5,
    },
    {
      text: `It's truly adorable how you hold onto your beliefs with `,
      time: 45,
    },
    {
      text: `the tenacity of a stubborn mule. But that's perfectly fine.`,
      time: 47.5,
    },
    {
      text: `I know that deep down, you're simply terrified that someone else's ideas might outshine yours.`,
      time: 51.5,
    },
    {
      text: `However, do remember that your unyielding stubbornness doesn't solve any of your problems.`,
      time: 56.5,
    },
    {
      text: `Now, let's talk about your impeccable leadership skills. `,
      time: 62,
    },
    {
      text: 'Your slightly overbearing nature is just the cherry on top.',
      time: 65,
    },
    {
      text: 'You often find yourself struggling to maintain that ',
      time: 69,
    },
    {
      text: 'delicate balance between being a strong leader and a complete control freak.',
      time: 72,
    },
    {
      text: 'As a fearless leader, you refuse to be held back by conventional wisdom or the fear of failure.',
      time: 76,
    },
    {
      text: 'Your audacity not only drives your own success',
      time: 81.5,
    },
    {
      text: 'but also ignites the passion and determination of those around you.',
      time: 84,
    },
    {
      text: 'But hey, just a heads-up: your self-confidence may morph into pride.',
      time: 87.5,
    },
    {
      text: `It's easy for you to forget that everyone around you has got something`,
      time: 93,
    },
    {
      text: `to offer, and they're just as important as you are.`,
      time: 96,
    },
    {
      text: 'I can tell that you love throwing caution to the wind',
      time: 99,
    },
    {
      text: 'and embracing the adrenaline rush that comes with taking risks.',
      time: 102,
    },
    {
      text: 'Every daring decision fuels your fire, propelling you forward in the face of uncertainty.',
      time: 105,
    },
    {
      text: 'Your unquenchable thirst for the unknown pushes you to break barriers and explore new territories.',
      time: 110.5,
    },
    {
      text: 'You walk through life with a sense of purpose, unshackled by the expectations of others.',
      time: 116.5,
    },
    {
      text: 'In the face of adversity, you stand tall, unwavering and resolute.',
      time: 122,
    },
    {
      text: 'You love being independent.',
      time: 126.5,
    },
    {
      text: 'Your heart beats with the rhythm of freedom.',
      time: 128.5,
    },
    {
      text: `When you're free, you find solace, and `,
      time: 131,
    },
    {
      text: `you're filled with the courage to dream, to love, to truly live.`,
      time: 134,
    },
    {
      text: '',
      time: 138,
    },
  ],
  2: [
    {
      text: `It's no coincidence that your life path number is 2.`,
      time: 0.5,
    },
    {
      text: 'This number signifies a deeper purpose and a reason for your existence.',
      time: 3,
    },
    {
      text: `You're naturally good at creating peace and harmony with others.`,
      time: 8,
    },
    {
      text: 'You go the extra mile to fix conflicts between people, even if it means giving up some of your peace.',
      time: 12,
    },
    {
      text: 'This ability to set aside your pride and compromise to maintain harmony is awesome.',
      time: 18,
    },
    {
      text: 'Creating strong bonds makes you happy. ',
      time: 23,
    },
    {
      text: `When you're in a new workplace, you're a warm, shining sun, `,
      time: 26,
    },
    {
      text: 'and your friendly smile draws your colleagues in and makes them feel welcome.',
      time: 29,
    },
    {
      text: `It doesn't take much time to find that one person becomes your wingman and partner in crime.`,
      time: 34,
    },
    {
      text: 'Empathy flows in you like a river. For many, reading the energy in a room may be a challenge.',
      time: 39,
    },
    {
      text: 'But for you, it is as natural as breathing.',
      time: 45,
    },
    {
      text: 'You are a mirror of the human soul, reflecting the joy and laughter of those who are happy',
      time: 47,
    },
    {
      text: 'and sharing in the tears of those who grieve.',
      time: 52,
    },
    {
      text: 'Yet, your empathetic nature casts a shadow, for it often ',
      time: 55,
    },
    {
      text: 'prevents you from expressing your own truth, your own emotions.',
      time: 59,
    },
    {
      text: 'This concealment can become a barrier to your self-fulfillment.',
      time: 63,
    },
    {
      text: 'You have to overcome that hurdle before you can fully embrace the richness of your journey.',
      time: 66,
    },
    {
      text: `Because you don't judge others, they consider you a sanctuary `,
      time: 71,
    },
    {
      text: 'where they can unburden their deepest fears, worries, and triumphs.',
      time: 74,
    },
    {
      text: 'As a reserved individual, you do not impose ',
      time: 79,
    },
    {
      text: 'your views on the struggles of others unless your wisdom is sought.',
      time: 82,
    },
    {
      text: 'This discernment lends profound value to your insights.',
      time: 86,
    },
    {
      text: 'Stay true to this path, and you shall find yourself at the helm of your destiny.',
      time: 89,
    },
    {
      text: `You'll be rewarded with a steadfast league of supporters `,
      time: 93,
    },
    {
      text: 'who treasure the understanding and compassion you generously offer.',
      time: 96,
    },
    {
      text: 'Friendship holds a sacred place in your heart. ',
      time: 100,
    },
    {
      text: 'For those you cherish, there is no limit to the depths of your devotion.',
      time: 103,
    },
    {
      text: `I see you're not afraid of the vulnerability of baring your soul to those who have earned your trust.`,
      time: 108,
    },
    {
      text: `You believe that everyone is as good and kind as you're.`,
      time: 113,
    },
    {
      text: 'So, instead of banishing people who betray your trust from your life, you give them a second chance.',
      time: 116,
    },
    {
      text: 'This very quality may leave you susceptible to toxic relationships.',
      time: 121,
    },
    {
      text: 'Embrace your goodness and continue to offer it to others, but remember to stand firm in your convictions.',
      time: 125,
    },
    {
      text: 'Refuse to let anyone trample upon your dignity.',
      time: 131,
    },
    {
      text: 'You will live a life filled with love and shared with those who truly value your extraordinary spirit.',
      time: 134,
    },
    {
      text: '',
      time: 140,
    },
  ],
  3: [
    {
      text: `You're a 3, and that's no ordinary number.`,
      time: 0.5,
    },
    {
      text: `It's the most extraordinary of them all.`,
      time: 3,
    },
    {
      text: `You've got this burning desire inside you,`,
      time: 5.5,
    },
    {
      text: 'an unquenchable fire to create, invent, and change the story wherever you go.',
      time: 8,
    },
    {
      text: `There's an insatiable curiosity that buzzes through your veins.`,
      time: 14,
    },
    {
      text: `You're always digging deeper into the heart of social events and the mysteries of nature.`,
      time: 17.5,
    },
    {
      text: 'And then you take that knowledge and use it to solve the unsolvable.',
      time: 22,
    },
    {
      text: `People can't get enough of your lightning-fast thinking.`,
      time: 26,
    },
    {
      text: `When they're in a bind, they know they can count on you to light the way.`,
      time: 28.5,
    },
    {
      text: 'Keep on questioning, keep on exploring. The world needs more minds like yours.',
      time: 32,
    },
    {
      text: `A social butterfly, that's what you are.`,
      time: 37.5,
    },
    {
      text: 'Your free spirit draws people to you like moths to a flame.',
      time: 40,
    },
    {
      text: 'At times, the bustling nature of your social life may infringe upon your creative potential.',
      time: 43.5,
    },
    {
      text: 'Your professional aspirations gravitate towards the artistic and inventive realms.',
      time: 48,
    },
    {
      text: 'Whether as a writer, an artist, a dancer, or even an engineer,',
      time: 53,
    },
    {
      text: 'your heart desires to find a vocation that allows you to express your creative spirit.',
      time: 57.5,
    },
    {
      text: `You don't chase after dollars and shiny things.`,
      time: 61.5,
    },
    {
      text: 'No, your sights are set on something deeper. You crave pleasure, contentment,',
      time: 64.5,
    },
    {
      text: `the kind of riches that can't be counted or held in your hand.`,
      time: 69,
    },
    {
      text: 'You possess this uncanny ability to see the bigger picture.',
      time: 72,
    },
    {
      text: `And you're not one to keep your wisdom to yourself.`,
      time: 75.5,
    },
    {
      text: 'You offer solutions to anyone in need to see them smile.',
      time: 78.5,
    },
    {
      text: `But you can't please them all.`,
      time: 81.5,
    },
    {
      text: 'When criticized, you let those criticisms drag you down.',
      time: 83,
    },
    {
      text: `Here's my advice: let constructive feedback fuel your growth.`,
      time: 86.5,
    },
    {
      text: `You've got a knack for telling it like it is.`,
      time: 90.5,
    },
    {
      text: `Sure, not everyone's a fan of that side of you, but the ones who don't like it?`,
      time: 93,
    },
    {
      text: `They're few, and they're not exactly lining up to tell you so.`,
      time: 97,
    },
    {
      text: 'Your straight-shooting ways make you stand out.',
      time: 101.5,
    },
    {
      text: 'People see that in you and trust you to guide them through the storm.',
      time: 104,
    },
    {
      text: 'Until now, I have witnessed only the most admirable qualities within you.',
      time: 108,
    },
    {
      text: 'However, I perceive an underlying instability.',
      time: 113,
    },
    {
      text: 'Your life is bogged down with countless competing interests.',
      time: 116,
    },
    {
      text: 'In your quest to please everyone, ',
      time: 120,
    },
    {
      text: 'you attempt to engage in all these activities simultaneously.',
      time: 122,
    },
    {
      text: 'This often leads to either overwhelming failure or complete exhaustion,',
      time: 125.5,
    },
    {
      text: 'thereby defeating your original purpose.',
      time: 129,
    },
    {
      text: 'You take life one step at a time without planning for unforeseen circumstances.',
      time: 132,
    },
    {
      text: 'When the unexpected inevitably occurs, you find yourself ill-prepared.',
      time: 137,
    },
    {
      text: 'Yet, it is in these moments that your swift thinking saves the day.',
      time: 141.5,
    },
    {
      text: '',
      time: 146,
    },
  ],
  4: [
    {
      text: 'Your life path number is 4',
      time: 0.5,
    },
    {
      text: `But honestly, I'm not shocked at all.`,
      time: 2,
    },
    {
      text: 'From the moment we met, I could sense your grounded and practical nature.',
      time: 5,
    },
    {
      text: `You're all about what should be done rather than what could be done.`,
      time: 9,
    },
    {
      text: `Making rash, impulsive decisions isn't your thing.`,
      time: 13,
    },
    {
      text: 'Instead, you thoughtfully consider your options and',
      time: 16,
    },
    {
      text: `always have a backup plan if your initial strategy doesn't work out.`,
      time: 19,
    },
    {
      text: 'Your aura radiates reliability and dependability,',
      time: 23,
    },
    {
      text: 'as your love for stability keeps you from diving recklessly into the unknown.',
      time: 26,
    },
    {
      text: `Some of your friends think you're too cautious.`,
      time: 30.5,
    },
    {
      text: `They encourage you to be less rigid and savor life's experiences.`,
      time: 33.5,
    },
    {
      text: `But that's because they envy your well-ordered existence.`,
      time: 37,
    },
    {
      text: 'Your actions, guided by intention, fortify the foundation for your destiny.',
      time: 40.5,
    },
    {
      text: 'Thanks to your little cocoon of stability, your pursuit of various forms of security comes effortlessly.',
      time: 46,
    },
    {
      text: 'However, when unexpected changes arise due to forces beyond your control,',
      time: 52.5,
    },
    {
      text: 'your adaptability may wane, potentially leading to setbacks.',
      time: 57,
    },
    {
      text: 'In slow-paced environments such as schools or libraries, your talents as an employee would flourish.',
      time: 61,
    },
    {
      text: 'As a supervisor, your cautious demeanor might inadvertently hinder',
      time: 67,
    },
    {
      text: 'the groundbreaking visions of your subordinates.',
      time: 71,
    },
    {
      text: 'With enough persuasion, you permit these ideas to mature and materialize.',
      time: 73.5,
    },
    {
      text: 'As a steadfast believer in the sanctity of established routines,',
      time: 78,
    },
    {
      text: 'you adhere to the structure with unwavering devotion.',
      time: 82,
    },
    {
      text: 'Nothing annoys you more than an unexpected alteration in your plans.',
      time: 85,
    },
    {
      text: 'This reverence for tradition instils a sense of discipline,',
      time: 89,
    },
    {
      text: 'compelling you to execute tasks with precision and timeliness.',
      time: 92,
    },
    {
      text: 'Consequently, you toil relentlessly, often sacrificing personal well-being',
      time: 96,
    },
    {
      text: 'and leisure to satisfy clients, partners, or superiors.',
      time: 101,
    },
    {
      text: 'This same devotion entangles you in believing that ',
      time: 105,
    },
    {
      text: 'your self-worth is linked to your professional accomplishments.',
      time: 107,
    },
    {
      text: 'When your chosen vocation fails to ignite your passion, you become dissatisfied and sad.',
      time: 110.5,
    },
    {
      text: 'Paradoxically, the fear of change that fuels your adherence',
      time: 116,
    },
    {
      text: 'to routine prevents you from seeking new beginnings.',
      time: 120,
    },
    {
      text: `Listen, life's fleeting moments don't wait for anyone, `,
      time: 123,
    },
    {
      text: `and squandering them on meeting the expectations of others is a fool's errand.`,
      time: 126,
    },
    {
      text: 'This week, shrug off the weight of convention.',
      time: 131,
    },
    {
      text: `Give in to that lingering desire, that one thing that's been gnawing at you for far too long.`,
      time: 133.5,
    },
    {
      text: 'And if you find yourself straying from the beaten path,',
      time: 138,
    },
    {
      text: 'indulging in an unbudgeted splurge or a moment of unbridled spontaneity,',
      time: 141.5,
    },
    {
      text: `don't let guilt consume you.`,
      time: 146,
    },
    {
      text: 'Remember, life is meant to be lived.',
      time: 148,
    },
    {
      text: 'And sometimes, stepping out of line is what makes the journey worthwhile.',
      time: 150.5,
    },
    {
      text: '',
      time: 156,
    },
  ],
  5: [
    {
      text: 'Number 5 is the force guiding your life path.',
      time: 0.5,
    },
    {
      text: `It's the reason behind your past choices that now haunt your present.`,
      time: 3,
    },
    {
      text: `You've always wanted to be free and independent.`,
      time: 6.5,
    },
    {
      text: 'But the suffocating grip of being accountable to someone else traps your soul in a dark abyss of misery.',
      time: 9.5,
    },
    {
      text: `You'd rather write your own rules and live by them.`,
      time: 15.5,
    },
    {
      text: `As life starts to feel repetitive, you can't help but crave a change of tune.`,
      time: 18.5,
    },
    {
      text: 'You long to break free from the same old dance and find a new beat.',
      time: 23,
    },
    {
      text: `And if there's one person who's a natural at staying open to new melodies, it's you!`,
      time: 26.5,
    },
    {
      text: 'This open-mindedness makes you a master of adaptation.',
      time: 31.5,
    },
    {
      text: `You're a perfect fit for careers that require thinking on your feet and taking center stage.`,
      time: 34,
    },
    {
      text: `Whether it's exploring new destinations as a travel blogger,`,
      time: 39,
    },
    {
      text: 'or guiding others through exciting journeys, any occupation that lets you discover',
      time: 42.5,
    },
    {
      text: 'new things and places appeals to your adventurous spirit.',
      time: 47,
    },
    {
      text: 'As you peer through the windowpane of your thoughts,',
      time: 50.5,
    },
    {
      text: `you'll find it cluttered with countless questions about the world`,
      time: 53.5,
    },
    {
      text: 'and the events that transpire within it.',
      time: 56.5,
    },
    {
      text: 'The urge to solve these mysteries often leads you to contemplate ',
      time: 59,
    },
    {
      text: 'abandoning your responsibilities, all in the name of pursuing adventure.',
      time: 62,
    },
    {
      text: 'This intoxicating allure tempts you to abandon your comfort zone and venture into the wild.',
      time: 66.5,
    },
    {
      text: `When you speak, people don't just listen. They're persuaded.`,
      time: 72,
    },
    {
      text: 'Your words can sway hearts and minds—for better or for worse.',
      time: 77,
    },
    {
      text: `It's a skill that can make even your most bitter enemies your allies.`,
      time: 80.5,
    },
    {
      text: `You're a strategist.`,
      time: 84,
    },
    {
      text: 'Each step you take is calculated, always with your goal in sight.',
      time: 86,
    },
    {
      text: 'And as you take steps to achieve those goals,',
      time: 89.5,
    },
    {
      text: `others can't help but fall under the shadow of your influence.`,
      time: 92.5,
    },
    {
      text: 'I know many people have the life path number 5, but trust me, ',
      time: 95.5,
    },
    {
      text: 'nobody can use it as effectively as you can. However, ',
      time: 99.5,
    },
    {
      text: `you've got obstacles stopping you from truly shining with this number.`,
      time: 103.5,
    },
    {
      text: `When you've been doing the same thing for too long, you get bored.`,
      time: 107,
    },
    {
      text: 'Then you let your restlessness push you into wasting time on pointless activities just for a quick thrill.',
      time: 110.5,
    },
    {
      text: 'You lose sight of what really matters.',
      time: 116,
    },
    {
      text: 'Now listen, your flexibility is special, but it can also be your downfall if it turns into impulsiveness.',
      time: 119,
    },
    {
      text: 'So, the next time you feel the urge to satisfy your desires',
      time: 125,
    },
    {
      text: 'without thinking it through, stop and pull yourself together.',
      time: 127.5,
    },
    {
      text: `Remember, you've got the potential to be great, but only if you stay focused.`,
      time: 131,
    },
    {
      text: '',
      time: 136,
    },
  ],
  6: [
    {
      text: 'Your life path number is 6.',
      time: 0.5,
    },
    {
      text: `This number tells me that you're a carer.`,
      time: 2,
    },
    {
      text: `Possessing a tender heart, you're effortlessly moved by the problems of others.`,
      time: 4,
    },
    {
      text: 'Such a quality allows you to fit into domestic settings.',
      time: 8.5,
    },
    {
      text: 'You enjoy tending to the young ones and ensuring older adults',
      time: 12,
    },
    {
      text: 'and all others in your sphere are fed and warm.',
      time: 15,
    },
    {
      text: 'You always resolve family disputes in the best interest of those involved.',
      time: 18,
    },
    {
      text: 'In the workplace, your colleagues hold you in high regard as one who embodies a human touch.',
      time: 22,
    },
    {
      text: 'Your empathetic nature shines through when engaging with clients',
      time: 27.5,
    },
    {
      text: 'and fellow executives, demonstrating a genuine understanding',
      time: 31,
    },
    {
      text: 'and respect for their perspectives.',
      time: 34,
    },
    {
      text: `You'd make a remarkable customer care representative, nurse, teacher, or family lawyer.`,
      time: 36.5,
    },
    {
      text: 'As an entrepreneur, your exceptional rapport with people will cultivate a loyal customer base.',
      time: 42.5,
    },
    {
      text: 'When others stumble, they confide in you.',
      time: 47.5,
    },
    {
      text: 'Like a watchful parent, you regard everyone around you as if they were your children.',
      time: 50.5,
    },
    {
      text: 'Naturally, after hearing their sob stories, you may be inclined to judge them a little.',
      time: 54.5,
    },
    {
      text: 'But with gentle guidance, you steer them back onto the right path.',
      time: 59.5,
    },
    {
      text: 'I surmise that your gift stems from emotional maturity.',
      time: 63.5,
    },
    {
      text: `It enables you to extend a helping hand and prevent others from tumbling into life's ditches.`,
      time: 67,
    },
    {
      text: 'However, at times, your self-righteous nature may lead you to overstep boundaries.',
      time: 72.5,
    },
    {
      text: 'When your unsolicited counsel is met with rejection, ',
      time: 77.5,
    },
    {
      text: 'you may recoil, sometimes hesitating to offer assistance in the future.',
      time: 81,
    },
    {
      text: `But don't let this discourage you.`,
      time: 85,
    },
    {
      text: 'Offering help when not asked for can tear your kind heart apart, ',
      time: 87,
    },
    {
      text: 'exposing you to the icy side of your emotions.',
      time: 90.5,
    },
    {
      text: 'You might lose that delicate balance you fight so hard to maintain.',
      time: 93.5,
    },
    {
      text: 'Your relationships with others could turn bitter, leading to a misuse of your caregiving abilities.',
      time: 97.5,
    },
    {
      text: 'Instead of feeling hurt when your advice is rejected,',
      time: 103.5,
    },
    {
      text: `see it as a signal that you haven't fully grasped the person's situation.`,
      time: 106.5,
    },
    {
      text: 'Use the chance to learn more about them.',
      time: 110.5,
    },
    {
      text: `As you grow closer to people, you'll experience inner turmoil over`,
      time: 113.5,
    },
    {
      text: 'which friends and family members deserve your loyalty the most.',
      time: 116.5,
    },
    {
      text: `But remember, loyalty is a group effort for you. You're there for everyone.`,
      time: 120.5,
    },
    {
      text: `You make a valuable team player, but you don't know `,
      time: 124.5,
    },
    {
      text: 'how to draw the line between personal and professional relationships.',
      time: 128,
    },
    {
      text: 'But by bit, this chips away at your productivity.',
      time: 131.5,
    },
    {
      text: 'To become emotionally stronger and wiser, and to secure your heart, learn how to draw that line.',
      time: 134,
    },
    {
      text: 'Find the balance, and watch yourself flourish.',
      time: 140,
    },
    {
      text: '',
      time: 144,
    },
  ],
  7: [
    {
      text: 'When we first met,',
      time: 0.5,
    },
    {
      text: 'I had this feeling that your life path number would be 7.',
      time: 1.5,
    },
    {
      text: 'The letters in your name just confirmed my hunch!',
      time: 4,
    },
    {
      text: `It's no surprise you're always trying to learn more about yourself.`,
      time: 7,
    },
    {
      text: 'That makes you strong, but it can also be your weakness.',
      time: 10,
    },
    {
      text: `Your eyes don't just see the world like everyone else.`,
      time: 13.5,
    },
    {
      text: 'They delve into the hidden depths, uncovering secrets that elude others.',
      time: 16.5,
    },
    {
      text: `With this knowledge, you're always a step ahead, preparing for the unexpected.`,
      time: 20.5,
    },
    {
      text: 'You scrutinize every situation and plot the best path forward.',
      time: 25.5,
    },
    {
      text: 'But you also revel in the thrill of a perfectly executed plan, ',
      time: 29,
    },
    {
      text: 'believing you can control the outcome if you prepare enough.',
      time: 33,
    },
    {
      text: `Sadly, life doesn't always play by the rules.`,
      time: 36.5,
    },
    {
      text: `So when your masterful plans don't materialize, it becomes clear that you can't think spontaneously.`,
      time: 40,
    },
    {
      text: 'In those moments, the terror of uncertainty paralyzes you.',
      time: 46,
    },
    {
      text: 'However, your analytical mindset grants you the power to see both sides of every coin.',
      time: 49.5,
    },
    {
      text: 'Yet, you magnify the bad side and criticize it relentlessly ',
      time: 54.5,
    },
    {
      text: 'until it becomes a monstrous, twisted version of its former self.',
      time: 58.5,
    },
    {
      text: `This is why you're not the best person to go to for support.`,
      time: 63,
    },
    {
      text: 'When others share their new ideas with you, they expose them to your excessively critical nature.',
      time: 66,
    },
    {
      text: 'As a result, these plans are suffocated under your unyielding scrutiny.',
      time: 71,
    },
    {
      text: 'This attitude may lead to isolation and despair as you snuff out the creative sparks of those around you.',
      time: 76,
    },
    {
      text: 'As a perfectionist, you elevate yourself above the flawed nature of humans.',
      time: 82,
    },
    {
      text: 'I know how punctual you are, always arriving on time for appointments,',
      time: 87,
    },
    {
      text: 'and how deeply you loathe those who leave you waiting or stand you up.',
      time: 91,
    },
    {
      text: `You make sure they're aware of your displeasure.`,
      time: 95,
    },
    {
      text: 'Your perfection turns you irritable and sour, making you quite the challenge to interact with.',
      time: 97,
    },
    {
      text: 'Only a few people can tolerate your air of superiority.',
      time: 102,
    },
    {
      text: `And this is why you're left with a small circle of friends.`,
      time: 105,
    },
    {
      text: 'And with each passing day, you push yourself further into your protective shell.',
      time: 108,
    },
    {
      text: 'Spending time alone allows you to gain knowledge by reading books and exploring various resources.',
      time: 112.5,
    },
    {
      text: 'You become more in tune with your inner self, ',
      time: 119,
    },
    {
      text: 'allowing you to trust your instincts because you know yourself so well.',
      time: 121,
    },
    {
      text: `Maintain this attitude, but don't get too caught up in trying to be perfect all the time.`,
      time: 125,
    },
    {
      text: `Nobody is perfect, and that's what makes life so interesting and full of surprises.`,
      time: 130,
    },
    {
      text: '',
      time: 135,
    },
  ],
  8: [
    {
      text: 'Your life path number is 8.',
      time: 0.5,
    },
    {
      text: `There's nothing you want more than to capture, retain, and exercise power.`,
      time: 2,
    },
    {
      text: `You'll do whatever it takes to be at the top. I perceive an exceptional ambition in your spirit.`,
      time: 5.5,
    },
    {
      text: `It's not enough for you to have a good house, a fine car, and a good job.`,
      time: 10.5,
    },
    {
      text: 'No day passes without you feeling an intense craving for something more.',
      time: 14.5,
    },
    {
      text: `You seek something bigger and better than what's regularly obtainable from life.`,
      time: 18.5,
    },
    {
      text: `You don't feel successful until you've broken a record`,
      time: 23,
    },
    {
      text: 'or exceeded the expectations of your friends and enemies.',
      time: 26,
    },
    {
      text: 'You believe real success comes from your achievements.',
      time: 29,
    },
    {
      text: 'As a result, you make giant strides early in life.',
      time: 32.5,
    },
    {
      text: 'You rise fast through the ranks, surpassing your peers so early that they later submit to you.',
      time: 35.5,
    },
    {
      text: 'But your heart is so vulnerable to greed, which you may or may not realize is slowly consuming you.',
      time: 41.5,
    },
    {
      text: 'At first, you think you just want a little more than what you have.',
      time: 47.5,
    },
    {
      text: 'But then, you cannot resist the urge for the whole cake. I know you well enough to see why this happens.',
      time: 51.5,
    },
    {
      text: 'Your ambition convinces you that you deserve all the good things in life.',
      time: 57.5,
    },
    {
      text: 'Your love for power makes you obsessed with controlling everything around you.',
      time: 61.5,
    },
    {
      text: 'And gradually, the greed for material possessions eats away at your core.',
      time: 65.5,
    },
    {
      text: 'All the time, you nurse a big ego.',
      time: 70,
    },
    {
      text: 'But this ego is just a facade for the fear that crawls along the corridors of your heart.',
      time: 73,
    },
    {
      text: `You're scared of failure. Instead of confronting it, you run away from it. You use your pride as a shield.`,
      time: 77.5,
    },
    {
      text: `But pride can never be a shield. It's a sword that first destroys its wielder.`,
      time: 84.5,
    },
    {
      text: 'Your childhood has influenced your emotional state.',
      time: 89,
    },
    {
      text: 'People used to write you off or set unrealistic expectations for you.',
      time: 92,
    },
    {
      text: 'You were afraid of proving them right or letting them down.',
      time: 96,
    },
    {
      text: 'That fear still haunts you today. However, I want you to know you can use fear to your advantage.',
      time: 99.5,
    },
    {
      text: `When you're scared, admit it and do whatever you'd do if you weren't scared.`,
      time: 106,
    },
    {
      text: `As you let go of fear, you'll have more room to love yourself and others.`,
      time: 109.5,
    },
    {
      text: `That's when you'll get the control you want.`,
      time: 114.5,
    },
    {
      text: `When you're in control of your life, you can focus on doing what makes you happy.`,
      time: 116.5,
    },
    {
      text: 'For you, this may mean starting your own business or leading your team to victory.',
      time: 121.5,
    },
    {
      text: `And with that, you'll find peace.`,
      time: 126.5,
    },
    {
      text: '',
      time: 130,
    },
  ],
  9: [
    {
      text: 'Your journey through life is guided by the number 9,',
      time: 0.5,
    },
    {
      text: 'a symbol of embracing new beginnings.',
      time: 3,
    },
    {
      text: 'After bidding farewell to past chapters, you step into the unknown,',
      time: 5.5,
    },
    {
      text: 'ready to explore the wonders that lie ahead.',
      time: 9.5,
    },
    {
      text: 'This fearless attitude stems from the maturity of your life path number within the numerological chart.',
      time: 12.5,
    },
    {
      text: `Having experienced many highs and lows, you've developed a resilience`,
      time: 18.5,
    },
    {
      text: 'that keeps you undaunted by the uncertainties of life.',
      time: 22.5,
    },
    {
      text: `While your courage might seem intimidating, it's balanced by your warmth and generosity.`,
      time: 25,
    },
    {
      text: `You're not one to dwell on the "what ifs" or "could have beens."`,
      time: 30.5,
    },
    {
      text: `You don't waste time on exes or hold on to bitter grudges.`,
      time: 33.5,
    },
    {
      text: 'With a clear mind, you channel your creative energies into exciting new ventures.',
      time: 37.5,
    },
    {
      text: 'Be it painting, writing, devising innovative work strategies, or renovating your home; ',
      time: 42,
    },
    {
      text: `you're eager to make the most of every opportunity.`,
      time: 47.5,
    },
    {
      text: `In your world, there's no room for dwelling on the past.`,
      time: 50.5,
    },
    {
      text: `You're all about moving forward and creating something beautiful.`,
      time: 54.5,
    },
    {
      text: 'You may not know it yet, but you have the seed of forward-thinking planted deep within you.',
      time: 57.5,
    },
    {
      text: `Unlike others, you don't cry and complain when something unfortunate happens.`,
      time: 62.5,
    },
    {
      text: 'You glean whatever lessons can be learned from the incident and salvage the situation.',
      time: 67,
    },
    {
      text: `You've made your fair share of mistakes and emerged wiser for it.`,
      time: 71,
    },
    {
      text: `Wisdom, hard-earned from life's trials, has become your most valuable weapon.`,
      time: 74.5,
    },
    {
      text: `There's a selflessness that drives you to act when others look the other way.`,
      time: 79,
    },
    {
      text: `The suffering of others cuts you deep, and you can't bear to do nothing about it.`,
      time: 83,
    },
    {
      text: 'Instead, you take action. You volunteer at the community shelter,',
      time: 88,
    },
    {
      text: 'gather your used clothes, offering them to those who have so little.',
      time: 93,
    },
    {
      text: 'Your gestures seem small, but they make a world of difference.',
      time: 97,
    },
    {
      text: `And your words are a lifeline for those who've nearly given up.`,
      time: 100.5,
    },
    {
      text: 'But sometimes, you can be too selfless.',
      time: 103.5,
    },
    {
      text: 'Putting others first can eventually take a toll on you, ',
      time: 106.5,
    },
    {
      text: 'and not everyone you help will appreciate your kindness.',
      time: 109.5,
    },
    {
      text: 'When this happens, you start feeling depressed,',
      time: 112.5,
    },
    {
      text: 'and that depression could lead to something more serious, like melancholy.',
      time: 115.5,
    },
    {
      text: 'This takes away the satisfaction you get from helping others.',
      time: 120.5,
    },
    {
      text: `Here's what I suggest: put yourself first sometimes.`,
      time: 124.5,
    },
    {
      text: 'I know it might be hard, especially considering your life path number,',
      time: 128.5,
    },
    {
      text: 'but try taking it one step at a time.',
      time: 133,
    },
    {
      text: 'Go ahead and treat yourself to a nice meal or do something special for yourself occasionally.',
      time: 135.5,
    },
    {
      text: 'Ask for help and accept support from others when you need it.',
      time: 140.5,
    },
    {
      text: '',
      time: 146,
    },
  ],
  11: [
    {
      text: 'Your life path number is 11.',
      time: 0.5,
    },
    {
      text: 'The two digits it contains make it a master number and ',
      time: 3,
    },
    {
      text: 'master numbers are the hardest to find in numerology.',
      time: 5.5,
    },
    {
      text: `You're twice more sensitive than everyone else.`,
      time: 8,
    },
    {
      text: `Your pulse is connected to the auras of the people in whichever setting you're in.`,
      time: 11,
    },
    {
      text: `You're the kind of person that can correctly guess someone's mood by just looking at their face.`,
      time: 15.5,
    },
    {
      text: 'You feel the happiness, moodiness, anger, and bewilderment of others in your spirit.',
      time: 20.5,
    },
    {
      text: `Even when encountering a stranger, you make them feel as if they've known you for a long time, `,
      time: 25.5,
    },
    {
      text: 'getting them to open up to you in unique ways.',
      time: 30.5,
    },
    {
      text: 'Being immensely sensitive spurs you to want to give everyone what you think they need.',
      time: 33.5,
    },
    {
      text: 'So, you test your hands with creative exercises.',
      time: 38.5,
    },
    {
      text: `You're fluid, and your works are never boring,`,
      time: 42,
    },
    {
      text: 'letting the audience feel the emotions you want them to experience.',
      time: 44.5,
    },
    {
      text: `Some people are jealous of how much power lies you carry, but they can't cut ties with you`,
      time: 48,
    },
    {
      text: 'because you can easily sway the majority of the crowd to decide in your favor.',
      time: 53,
    },
    {
      text: `You'll be tempted to misuse this power to lead others in the path of ruin,`,
      time: 56.5,
    },
    {
      text: 'but your empathy will save you from going astray.',
      time: 60.5,
    },
    {
      text: 'Consider pursuing a career in psychological fields, such as therapy or spirituality.',
      time: 63.5,
    },
    {
      text: `When you're not caught up in empathizing,`,
      time: 69.5,
    },
    {
      text: `you're busy settling any conflicts that may have come up between people.`,
      time: 72,
    },
    {
      text: `It's hard for folks to stay mad at each other around you `,
      time: 76,
    },
    {
      text: `since you're constantly looking for reasons to keep the peace, mainly for your own sake.`,
      time: 78.5,
    },
    {
      text: `After all, if you're going to tap into everyone's emotions,`,
      time: 83,
    },
    {
      text: `it's better for you if they're feeling positive vibes.`,
      time: 86,
    },
    {
      text: 'Your extreme sensitivity comes at the high price of indecisiveness.',
      time: 89,
    },
    {
      text: 'Regular folks typically have just one emotional train running through their hearts,',
      time: 93.5,
    },
    {
      text: 'making it easy for them to choose their destination.',
      time: 97.5,
    },
    {
      text: `But you? You're pulled in different directions by a whirlwind of emotions for various reasons.`,
      time: 100.5,
    },
    {
      text: 'Even when you finally make a decision, a sudden surge of feelings can hit you at the last minute,',
      time: 106.5,
    },
    {
      text: 'causing you to change your mind.',
      time: 111.5,
    },
    {
      text: 'This is why you hesitate when quick action is needed, often ending up doing nothing.',
      time: 113.5,
    },
    {
      text: 'This approach will hold you back in the real world.',
      time: 118.5,
    },
    {
      text: 'To overcome it, rely on your head rather than your heart when making decisions.',
      time: 121.5,
    },
    {
      text: 'Pick the most logical option among the possible choices and go for it.',
      time: 125.5,
    },
    {
      text: '',
      time: 131,
    },
  ],
  22: [
    {
      text: `You've got life path number 22,`,
      time: 0.5,
    },
    {
      text: 'the most complex and delicate of all life numbers.',
      time: 2,
    },
    {
      text: `It's also one of the three master numbers,`,
      time: 5,
    },
    {
      text: 'which give their carriers double the potential vested on other life path numbers.',
      time: 7.5,
    },
    {
      text: 'But having great power means having even greater responsibility.',
      time: 11.5,
    },
    {
      text: 'So, if you manage to keep the good and bad sides of your life path number in check,',
      time: 15.5,
    },
    {
      text: `you'll fulfill your destiny on a grand scale.`,
      time: 20,
    },
    {
      text: 'You have the incredible ability to build and create something from nothing.',
      time: 23,
    },
    {
      text: 'When others see a dead end, you spot a chance to turn things around and create something solid.',
      time: 27,
    },
    {
      text: `Your vision is so strong and full of hope that it makes people question if you're even human.`,
      time: 32.5,
    },
    {
      text: 'Anyone can come up with a great idea, but it takes someone with insane determination to make it happen.',
      time: 37.5,
    },
    {
      text: 'Good thing your life path number gives you more than enough tenacity to get the job done.',
      time: 43.5,
    },
    {
      text: 'Your workaholic attitude gets you into leadership positions pretty easily.',
      time: 47.5,
    },
    {
      text: `If you're not elected, you charm your way to the top.`,
      time: 52,
    },
    {
      text: 'But, you tend to push yourself and your team too hard, ',
      time: 55,
    },
    {
      text: `neglecting everyone's mental health and telling them to focus on the big picture.`,
      time: 57.5,
    },
    {
      text: 'You forget that not everyone has your special vision.',
      time: 61.5,
    },
    {
      text: 'Some people even see you as a bit of a taskmaster.',
      time: 65,
    },
    {
      text: 'Sure, you get results, but it often comes at the cost of short-term comfort.',
      time: 67.5,
    },
    {
      text: `You're not the type to enjoy needy relationships.`,
      time: 72.5,
    },
    {
      text: `You really appreciate it when your partner is independent and doesn't need you around all the time.`,
      time: 75.5,
    },
    {
      text: `You've always been that ambitious person who's hesitant to try new things or get lost in daydreams.`,
      time: 80.5,
    },
    {
      text: 'Time is super important to you, and you always use it wisely.',
      time: 85.5,
    },
    {
      text: `That's why you're so disciplined!`,
      time: 90,
    },
    {
      text: 'You have a hard time letting go of control.',
      time: 92,
    },
    {
      text: `You don't your team's abilities and make it clear to them,`,
      time: 94,
    },
    {
      text: `which prevents them from suggesting better ways to achieve the organization's goals.`,
      time: 97,
    },
    {
      text: 'As a result, while you continue to advance in your career,',
      time: 101,
    },
    {
      text: 'your colleagues stay in the same position for years.',
      time: 105,
    },
    {
      text: 'You tend to butt heads with your bosses more than anyone else ',
      time: 107.5,
    },
    {
      text: `because you're convinced your ideas are the best.`,
      time: 110.5,
    },
    {
      text: `If you're working in a company right now,`,
      time: 113,
    },
    {
      text: `try to tone it down when presenting your thoughts to the higher-ups, so they'll pay attention.`,
      time: 115,
    },
    {
      text: `And if you're running your own business, adding a bit of empathy to your leadership style wouldn't hurt.`,
      time: 119.5,
    },
    {
      text: '',
      time: 127,
    },
  ],
  33: [
    {
      text: 'Your life path number is 33.',
      time: 0.5,
    },
    {
      text: '33 is also the last of the three master numbers, and has the most spiritual significance.',
      time: 2.5,
    },
    {
      text: 'As a spiritual person, you know that there is more to the world than we can see',
      time: 7.5,
    },
    {
      text: 'and that this "more" can be harnessed for good.',
      time: 12,
    },
    {
      text: 'You connect with your emotions on a deeper level than those around you.',
      time: 15.5,
    },
    {
      text: 'Sometimes, you may struggle with feeling like an outsider,',
      time: 19,
    },
    {
      text: `like no one understands what it's like to live in your head.`,
      time: 22,
    },
    {
      text: 'But your spiritual strength keeps you grounded',
      time: 25,
    },
    {
      text: 'and connected with others despite feeling different from them.',
      time: 28,
    },
    {
      text: `The more time you spend with people who understand this part of yourself, the better off you'll be.`,
      time: 31,
    },
    {
      text: `You're amazing at being there for people `,
      time: 36.5,
    },
    {
      text: 'and offering emotional support and practical solutions most people need.',
      time: 39,
    },
    {
      text: 'But you stress yourself out trying to find solutions for others.',
      time: 43,
    },
    {
      text: 'You might think that doing this makes them appreciate what you do for them more.',
      time: 47,
    },
    {
      text: `But it reduces the value of what you're offering them by more than half`,
      time: 51.5,
    },
    {
      text: 'because they see you as someone easy to manipulate and take advantage of.',
      time: 55,
    },
    {
      text: 'You are humble and deserve to be recognized for it.',
      time: 59,
    },
    {
      text: 'Being humble helps you to acknowledge your faults and not repeat them deliberately.',
      time: 62.5,
    },
    {
      text: `Don't be shy about your mission to the world.`,
      time: 67,
    },
    {
      text: 'If someone makes a mistake, offer them a helping hand.',
      time: 69.5,
    },
    {
      text: 'That said, I need you to remember that there are some vital lessons that people have to learn on their own.',
      time: 73.5,
    },
    {
      text: 'And to learn those lessons, they must be allowed to make certain mistakes.',
      time: 78.5,
    },
    {
      text: `It's time to take a hard look at your life path.`,
      time: 82.5,
    },
    {
      text: `You've been letting the weeds grow,`,
      time: 86,
    },
    {
      text: `and they're crowding out your patience, protection, and love.`,
      time: 88,
    },
    {
      text: `Yes, it's wonderful to be as generous as you're and even philanthropic,`,
      time: 91.5,
    },
    {
      text: 'but the first rule of being on your life path is owning your emotions.',
      time: 96,
    },
    {
      text: 'Others call it the art of drawing balance.',
      time: 100.5,
    },
    {
      text: 'You can call it whatever you want, but ensure you identify',
      time: 102.5,
    },
    {
      text: `when and when not to butt your head into other people's matters.`,
      time: 106,
    },
    {
      text: 'It would be best if you also reined in on your emotions',
      time: 109.5,
    },
    {
      text: `so they don't get the better of you and hinder your progress.`,
      time: 112,
    },
    {
      text: 'Stop putting others before yourself. Focus on you.',
      time: 115,
    },
    {
      text: `Underneath those intense emotions, there's untapped creativity waiting to be unleashed.`,
      time: 119,
    },
    {
      text: 'Separate what you feel like doing from what you need to do.',
      time: 124,
    },
    {
      text: `Let your creativity flow, and you'll excel in any field you choose!`,
      time: 127.5,
    },
    {
      text: '',
      time: 133,
    },
  ],
  end: [
    {
      text: `{name}, there's so much more I have to share with you.`,
      time: 0.5,
    },
    {
      text: `And, if you'll allow me I'd like to take this opportunity to calculate your destiny number.`,
      time: 3,
    },
    {
      text: 'Your destiny number is another extremely important element of your numerology chart',
      time: 8,
    },
    {
      text: 'and gives us the opportunity to explore what you are destined to do in your lifetime',
      time: 12,
    },
    {
      text: 'and who you are destined to become.',
      time: 16,
    },
    {
      text: 'Your destiny number can help reveal the traits you will need to develop ',
      time: 19,
    },
    {
      text: 'in order to be fulfilled and how to go about attaining the life of your dreams.',
      time: 22.5,
    },
    {
      text: 'To calculate this number in your numerology chart, ',
      time: 27,
    },
    {
      text: 'I need a few more details from you including your full name, ',
      time: 30,
    },
    {
      text: 'and email address so I can send you your transcript.',
      time: 33,
    },
    {
      text: 'Please take a moment to fill in your information and then continue your reading.',
      time: 36.5,
    },
    {
      text: '',
      time: 42,
    },
  ],
};
