import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import userAPI from '../../api/api';
import './ThankYou.scss';

const ThankYou = () => {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [searchParams] = useSearchParams();

  const fetchUserPassword = async (email, receipt) => {
    try {
      const response = await userAPI.get(
        `/users/get-password?email=${email}&receipt=${receipt}`
      );
      if (response.data.password) {
        setUserPassword(response.data.password);
      } else if (response.data.error) {
        console.log('error: ', response.data.error);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    const queryParamEmail = searchParams.get('cemail');
    const queryParamReceipt = searchParams.get('cbreceipt');

    setUserEmail(queryParamEmail);

    fetchUserPassword(queryParamEmail, queryParamReceipt);
  }, [searchParams]);

  return (
    <div className="page-thank-you">
      <div className="box__wrapper">
        <div className="box__inner">
          <p className="h2">Congratulations!</p>
          <p className="body-text">
            And thanks for creating your full personal Numerology blueprint.
            Please login to view your reading using the details below (Please
            save these now for future use), we have also sent your login details
            via email, please make sure to whitelist support@mysticnumbers.com
            with your email proivider.
          </p>
          <div className="login-link">
            <p className="body-text">
              Login url:{' '}
              <a
                href="https://mysticnumbers.com/sign-in"
                target="_blank"
                rel="noreferrer"
              >
                https://mysticnumbers.com/sign-in
              </a>
            </p>
            <p className="body-text">Username: {userEmail}</p>
            <p className="body-text">Password: {userPassword || '********'}</p>
          </div>
          <p className="note-text">
            Please note your purchase will show as ClickBank or CLKBANK*COM
          </p>
        </div>
        <div className="box__bottom">
          <p>
            "ClickBank is the retailer of products on this site. CLICKBANK® is a
            registered trademark of Click Sales, Inc., a Delaware corporation
            located at 1444 S. Entertainment Ave., Suite 410 Boise, ID 83709,
            USA and used by permission. ClickBank's role as retailer does not
            constitute an endorsement, approval or review of these products or
            any claim, statement or opinion used in promotion of these
            products."
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
