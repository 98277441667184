import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { covertTextToSpeech } from '../../../../../api/api';
import { S3UploadedAudios } from '../../../../../common/constants';
import VoiceCaptionBox from '../audio-caption-box/AudioCaptionBox';
import { useStartIntroAudioData } from './useStartIntroAudioData';

const StartIntroAudio = ({ personalData, readingStep, onCanPlay }) => {
  const [birthdayAudio, setBirthdayAudio] = useState(null);
  const [helloNameAudio, setHelloNameAudio] = useState(null);
  const [introAudio, setIntroAudio] = useState(null);
  const [canPlayHelloNameAudio, setCanPlayHelloNameAudio] = useState(false);
  const [canPlayIntroAudio, setCanPlayIntroAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    loadAudios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalData]);

  const loadAudios = async () => {
    if (!personalData?.name && !personalData?.lifePathNumber) {
      const birthdaySpeech = await covertTextToSpeech(
        `${personalData.month} ${personalData.day}, ${personalData.year}`
      );
      const birthdayAudioObj = new Howl({
        src: [birthdaySpeech],
        html5: true,
        format: 'mp3',
      });
      setBirthdayAudio(birthdayAudioObj);
    }

    if (personalData?.name && !personalData?.lifePathNumber) {
      const helloNameSpeech = await covertTextToSpeech(
        `Hello ${personalData.name} and welcome to Mystic Numbers`
      );
      const helloNameAudioObj = new Howl({
        src: [helloNameSpeech],
        html5: true,
        format: 'mp3',
        onload: () => setCanPlayHelloNameAudio(true),
        onplay: () => setIsPlaying(true),
      });
      setHelloNameAudio(helloNameAudioObj);
    }
  };

  useEffect(() => {
    if (birthdayAudio) {
      const introAudioObj = new Howl({
        src: [S3UploadedAudios.START_INTRO],
        html5: true,
        onload: () => setCanPlayIntroAudio(true),
        onend: () => birthdayAudio.play(),
      });
      setIntroAudio(introAudioObj);
    }
  }, [birthdayAudio]);

  useEffect(() => {
    if (
      readingStep === 'life-path-number-calculation' &&
      helloNameAudio &&
      introAudio &&
      canPlayHelloNameAudio &&
      canPlayIntroAudio &&
      !isPlaying
    ) {
      onCanPlay();

      setTimeout(() => helloNameAudio.play(), 1000);
      setTimeout(() => introAudio.play(), 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    birthdayAudio,
    canPlayHelloNameAudio,
    canPlayIntroAudio,
    helloNameAudio,
    introAudio,
    isPlaying,
    readingStep,
  ]);

  const { caption, isFinished, isStarted } = useStartIntroAudioData({
    personalData,
    startPlay:
      readingStep === 'life-path-number-calculation' &&
      canPlayHelloNameAudio &&
      canPlayIntroAudio,
  });

  useEffect(() => {
    if (isFinished) {
      helloNameAudio.unload();
      introAudio.unload();
      birthdayAudio.unload();
    }
  }, [birthdayAudio, helloNameAudio, introAudio, isFinished]);

  return (
    <VoiceCaptionBox
      caption={caption}
      isFinished={isFinished}
      isStarted={isStarted}
      hiddenControls
    />
  );
};

export default StartIntroAudio;
